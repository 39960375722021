import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Flyer from './Flyer'
import { getAuthenticityToken } from 'Shared/helpers'
import SearchBar from './SearchBar'

const Flyers = (props) => {
  const [ flyers, setFlyers ] = useState(props.flyers)
  const [ flyersAfterFilters, setFlyersAfterfilters ] = useState(props.flyers)
  const [ hideFlyers, setHideFlyers ] = useState(props.showCategory)

  const handleFlyerDelete = (deleteFlyer, isSystemTemplate, isPredefined) => {
    // eslint-disable-next-line no-alert
    if (confirm(window.I18n.t('views.golfhub.remove_flyer_confirmation_message'))){
      fetch(`/leagues/${props.leagueId}/flyers?flyer_id=${deleteFlyer.id}&is_system_template=${isSystemTemplate}&is_predefined=${isPredefined}`, {
        method: 'DELETE',
        headers: {'X-CSRF-Token': getAuthenticityToken()},
      })
      .then(() => {
        const newFlyers = flyers.filter((flyer) => flyer.id !== deleteFlyer.id)
        const newFlyersAfterFilters = flyersAfterFilters.filter((flyer) => flyer.id !== deleteFlyer.id)
        setFlyers(newFlyers)
        setFlyersAfterfilters(newFlyersAfterFilters)
      })
    }
  }

  const toggleShow = () => {
    setHideFlyers(!hideFlyers)
    setFlyersAfterfilters(flyers)
  }

  return (
    <div className="list">
      <div className='category' onMouseDown={(event) => {
        if (!event.target.closest('.search-bar')) {
          toggleShow()
        }
      }}>
        <div className="category-information">
          <div className="category_name">
            {props.isSystemTemplate ? window.I18n.t('views.golfhub.templates') : window.I18n.t('views.golfhub.flyers')}
          </div>
          <div className="search-bar" onClick={(event) => event.stopPropagation()}>
            {hideFlyers &&
              <SearchBar flyers={flyers} setFlyers={setFlyersAfterfilters} />
            }
          </div>
        </div>
        <div className="caret-display">
          <i className={`fa ${hideFlyers ? 'fa-caret-down' : 'fa-caret-up'}`}></i>
        </div>
      </div>
      {hideFlyers &&
        <div className="flyers_list">
          <div className="flyers">
            { flyersAfterFilters !== null && flyersAfterFilters.length > 0 ? 
              ( flyersAfterFilters.map((flyer) => {
                return (
                  <Flyer
                    key={flyer.id}
                    data={flyer}
                    leagueId={props.leagueId}
                    canDelete={props.canDelete}
                    handleFlyerDelete={() => handleFlyerDelete(flyer, props.isSystemTemplate, props.canDelete) }
                    isAdmin={props.isAdmin}
                    isSystemTemplate={props.isSystemTemplate}
                    flyers={flyers}
                    setFlyers={setFlyers}
                    flyersAfterFilters={flyersAfterFilters}
                    setFlyersAfterfilters={setFlyersAfterfilters} >
                  </Flyer>
                )
              })) : (
              <div className="no_results_found">
                { window.I18n.t('views.golfhub.no_results_found') }
              </div>
            )}
          </div>
        </div>
      }
    </div>
  )
}

Flyers.propTypes = {
  flyers: PropTypes.array,
  canDelete: PropTypes.bool,
  leagueId: PropTypes.string,
  customerId: PropTypes.number,
  isAdmin: PropTypes.bool,
  isSystemTemplate: PropTypes.bool,
  showCategory: PropTypes.bool,
}

export default Flyers
