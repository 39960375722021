import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'react-bootstrap'

const UploadFlyerImage = (props) => {
  
  const [ canSave, setCanSave ] = useState(false)
  const [ file, setFile ] = useState(null)
  const [ isSaving, setIsSaving ] = useState(false)
  const [ errorMessage, setErrorMessage ] = useState('')
  const [ isError, setIsError ] = useState(false)

  const openModal = () => {
    setErrorMessage('')
    setIsError(false)
    props.setOpenUploadModal()
  }

  const clearOldSave = () => {
    setCanSave(false)
    setIsSaving(false)
  }

  const onSave = () => {
    const formData = new FormData()
    formData.append('new_image', file)
    formData.append('default', props.newDefault)

    setIsSaving(true)
    fetch(`/leagues/${props.leagueId}/flyers_images`, {
      method: 'POST',
      body: formData,
    })
    .then(response => response.json())
    .then(data => {
      if (props.newDefault === true) {
        const newImageList = [ ...props.defaultImages, {'id': data['id'], 'url': data['image']['url']} ]
        props.setDefaultImages(newImageList)
      } else {
        const newImageList = [ ...props.customImages, {'id': data['id'], 'url': data['image']['url']} ]
        props.setCustomImages(newImageList)
      }

      clearOldSave()
      props.setOpenUploadModal()
    })
    .catch(error => {
      console.log(error)
      setErrorMessage(window.I18n.t('views.golfhub.flyer_upload_error.failed_upload'))
      setIsError(true)

      clearOldSave()
    })
  }
  
  const handleImageUpload = (e) => {
    const file = e.target.files[0]

    let message = ''
    if (file.size > 6000000) { // bytes
      message = 'File exceeds maximum size.'
    }
    
    if ([ 'image/jpeg', 'image/png', 'image/gif', 'image/bmp' ].indexOf(file.type) < 0) {
      message += ' File format should be jpg, jpeg, gif, png, bmp.'
    }

    if (message === '') {
      setCanSave(true)
      setFile(file)
      setErrorMessage('')
      setIsError(false)
    } else {
      setCanSave(false)
      setErrorMessage(message)
      setIsError(true)
    }
  }

  return (
    <div className="modal">
      <Modal className='modal-golfhub' show={props.openUploadModal} onHide={() => props.setOpenUploadModal()}>
        <Modal.Header className='modal-header-golfhub'>
          <Modal.Title>
          { window.I18n.t('views.golfhub.add_image') }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body-golfhub'>
          <input className='add-margin-bottom-10' type='file' accept='image/jpeg, image/png, image/gif, image/bmp' onChange={(e) => handleImageUpload(e)}/>
          {isError && 
            <div className="error-upload">
              { errorMessage }
            </div>
          }
          <div>{window.I18n.t('views.golfhub.accepted_photo_formats')}</div>
        </Modal.Body>
        <Modal.Footer className='footer modal-footer-golfhub'>
          <Button onClick={() => openModal()} className = 'cancel-button'>Cancel</Button>
          <Button onClick={() => onSave()} disabled={!canSave} className='button btsp_orange_button save-button'>
            {isSaving ? window.I18n.t('views.golfhub.saving_asset') : window.I18n.t('views.golfhub.upload_asset')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

UploadFlyerImage.propTypes = {
  leagueId: PropTypes.string,
  openUploadModal: PropTypes.bool,
  setOpenUploadModal: PropTypes.func,
  defaultImages: PropTypes.array,
  setDefaultImages: PropTypes.func,
  customImages: PropTypes.array,
  setCustomImages: PropTypes.func,
  newDefault: PropTypes.bool,
}

export default UploadFlyerImage
