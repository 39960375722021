import { connect } from 'react-redux'

import {
  changeNavigatorOptionSelectedRound,
  changeDropdownState,
  changeBookmarkedStatus,
  openPopup,
} from '../actions'

import { getRoundOptions } from '../selectors'

import NavigatorOptionComponent from '../components/navigator_option_component'

const mapStateToProps = (state, props) => ({
  roundOptions: getRoundOptions(state),
  selectedRoundId: state.navigatorOptions[props.id].selectedRoundId,
  defaultRoundId: state.misc.defaultRoundId,
  filterValue: state.misc.navigatorFilter,
  userIsAdmin: state.misc.userIsAdmin,
})

const mapDispatchToProps = dispatch => ({
  changeSelectedRound: (navigatorOptionId, roundId) => {
    dispatch(changeNavigatorOptionSelectedRound(navigatorOptionId, roundId))
  },
  onOptionClick: () => dispatch(changeDropdownState(false)),
  changeBookmarkedStatus: (id, status) => dispatch(changeBookmarkedStatus(id, status)),
  openPopup: (id, data) => dispatch(openPopup(id, data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavigatorOptionComponent)
