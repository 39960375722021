import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { formatDate } from 'Shared/helpers'

class DatesComponent extends Component {

  render() {
    
    return <Fragment>{this.props.startDate && (!this.props.endDate || this.props.startDate === this.props.endDate) && <div className="bold-color">{formatDate(this.props.startDate, '', 'ddd, MMM D, YYYY')}</div>}{this.props.startDate && this.props.endDate && this.props.startDate !== this.props.endDate && <div className="bold-color">{formatDate(this.props.startDate, '', 'MMM') !== formatDate(this.props.endDate, '', 'MMM') && <Fragment>{formatDate(this.props.startDate, '', 'ddd, MMM D') + ' - ' + formatDate(this.props.endDate, '', 'ddd, MMM D, YYYY')}</Fragment>}{formatDate(this.props.startDate, '', 'MMM') === formatDate(this.props.endDate, '', 'MMM') && <Fragment>{formatDate(this.props.startDate, '', 'ddd, MMM D') + ' - ' + formatDate(this.props.endDate, '', 'ddd, MMM D, YYYY')}</Fragment>}{Date.parse(this.props.endDate) >= new Date().setUTCHours(0, 0, 0, 0) && <div className="not-bold-color">{'Next Round: ' + formatDate(this.props.datesToSort, '', 'ddd, MMM D, YYYY')}</div>}</div>}</Fragment>
  }
}

DatesComponent.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  datesToSort: PropTypes.string,
}

export default DatesComponent
