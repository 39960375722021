import React, { useEffect, useState, useRef } from 'react'
import OttDesktopLayout from './ott_desktop_layout'
import OttMobileLayout from './ott_mobile_layout'
import OttHeader from './ott_header'
import OttTitle from './ott_title'
import OttFooter from './ott_footer'
import OttRefreshStatusBar from './ott_refresh_status_bar'
import { createPortal } from 'react-dom'
import { useSelector } from 'react-redux'


const OttLayout = () => { 
  const [ isMobile, setIsMobile ] = useState(window.innerWidth <= 800)
  const headerRef = useRef(null)
  const [ mobileHeaderHeightOffset, setMobileHeaderHeightOffset ] = useState(0) // eslint-disable-line no-unused-vars
  const isAdminMode = useSelector(state => state.isAdminMode)

  useEffect(() => {
    if (isMobile) {
      const headerWrapperDiv = document.createElement('div')
      window.parent.document.getElementById('main_content').prepend(headerWrapperDiv)
    }

    // Observe header height changes and adjust the mobile layout offset accordingly
    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        if (entry.target === headerRef.current) {
          setMobileHeaderHeightOffset(entry.contentRect.height)
        }
      }
    })
    

    if (headerRef.current) {
      resizeObserver.observe(headerRef.current)
    }

    // Observe window resize and display the desktop/mobile layout accordingly
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      // if (headerRef.current) { resizeObserver.unobserve(headerRef.current) }
      window.removeEventListener('resize', handleResize)
    }
  }, [ isMobile ])

  return (
    <body className="ott-v2">
      <div className="ott-page-root">
        {isMobile
          ?
          <>
            {/* <OttTitle/> */}
            {isAdminMode
              ?
                <section className="ott-mobile-header margin-bottom-20-px" ref={headerRef}>
                  <OttHeader/>
                </section>
              :
              createPortal(
                <section className="ott-mobile-header" ref={headerRef}>
                  <OttHeader/>
                </section>
              , window.parent.document.getElementById('main_content').firstElementChild)
            }

            <section className="ott-mobile-layout">
              <OttMobileLayout/>
            </section>
            
            {createPortal(
              <section className="ott-mobile-footer">
                <OttFooter/>
              </section>
              , window.parent.document.getElementById('main_content'))}
            <OttRefreshStatusBar/>
          </>
          :
          <>
            <OttTitle/>
            <OttHeader/>
            <OttDesktopLayout/>
            <OttFooter/>
            <OttRefreshStatusBar/>
          </>
        }
      </div>
    </body>
  )
}

export default OttLayout
