import { createSelector } from 'reselect'
import _ from 'lodash'

const getLeaguesById = (state = {}) => {
  return state.leagues
}
const getToursCount = (state = {}) => {
  const tours = state.tours
  return _.size(tours)
}

const getCheckLeagues = (state, { checkLeagues = false } = {}) => checkLeagues

const getToursEnabled = (state = {}) => {
  return state.misc.toursEnabled
}

const checkShowTours = createSelector(
  [ getLeaguesById, getToursCount, getToursEnabled, getCheckLeagues ],
  (leaguesById, toursCount, toursEnabled, checkLeagues) => (
    toursEnabled &&
    toursCount > 0 &&
    (!checkLeagues || _.some(leaguesById, league => !!league.tour))
  )
)

export default checkShowTours
