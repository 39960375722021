import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-flexbox-grid'
import { Row } from 'react-bootstrap'

class EditColumns extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: (this.props.widgets.length === 0 ? null : this.props.widgets[0].pages[0].title),
      titles: {},
      view_more: true,
      form_ui: (this.props.widgets.length === 0 ? 'new_page' : 'existing_page'),
      usesTranslations: this.props.usesTranslations,
      availableLanguages: this.props.availableLanguages,
      shouldHide: true,
      shouldHideButton: false,
    }
    this.setTitle = this.setTitle.bind(this)
    this.setTitleFromSelect = this.setTitleFromSelect.bind(this)
    this.setViewMore = this.setViewMore.bind(this)
    this.setFormUi = this.setFormUi.bind(this)
    this._widget = (this.props.widgets.length === 0 ? null : this.props.widgets[0].pages[0].widget_id)
    // this.flatten = this.flatten.bind(this)

    this._ui = (this.props.widgets.length === 0 ? null : this.props.widgets[0].pages[0].widget_id)
  }

  setTitle(_title) {
    this.setState({
      title: _title,
    })
  }
  
  setTitles(_title, key) {
    this.setState({
      titles: {
        ...this.state.titles,
        [key]: _title,
      },
    })
  }

  flatten(input) {
    const stack = [ ...input ]
    const res = []
    while (stack.length) {
      // pop value from stack
      const next = stack.pop()
      if (Array.isArray(next)) {
        // push back array items, won't modify the original input
        stack.push(...next)
      } else {
        res.push(next)
      }
    }
    //reverse to restore input order
    return res.reverse()
  }

  setTitleFromSelect(_id) {
    let title = this.flatten(this.props.widgets.map(section => section.pages))
    title = title.find(page => page.widget_id === _id ).title

    this.setState({
      title: title,
    })
  }


  setViewMore() {
    this.setState({
      view_more: !this.state.view_more,
    })
  }

  setFormUi(_ui) {
    this.setState({
      form_ui: _ui,
    })
  }

  render() {
    return (
      <Col xs={12} className="manage-columns">
        <Col xs={10} xsOffset={1} className="title">
          <h1>{window.I18n.t('multi_widgets.edit_column.add_new_tile')}</h1>
        </Col>
        <Col xs={10} xsOffset={1} className="inner-content">
          <div className="alert alert-warning text-center" dangerouslySetInnerHTML={{__html: window.I18n.t('multi_widgets.edit_column.select_or_add_page', { view_more: window.I18n.t('multi_widgets.edit_column.view_more')} )}}/>
          <Col xs={10} xsOffset={1} className="form-row">
            <Col xs={4} className="labels">
              <div className="radio">
                <label>
                  <input type="radio" id="existing_page_checkbox" name="form_ui" value="existing_page" disabled={this.props.widgets.length === 0 ? true : false} defaultChecked={this.props.widgets.length === 0 ? false : true} onChange={ e => this.setFormUi(e.target.value) } />
                  {window.I18n.t('multi_widgets.edit_column.select_existing')}
                </label>
              </div>
            </Col>
            { (this.state.form_ui === 'existing_page' && this.props.widgets.length !== 0)
              ? <Col xs={6} className="form-group">
                  <select name="columnsCount"
                          className="form-control"
                          onChange={ e => { this._widget = e.target.value; this.setTitleFromSelect(e.target.value) } } >
                    { this.props.widgets.map((section, index) => (
                      <optgroup key={ `gg-section-${index}` } label={ section.title }>
                        { section.pages.map((page, _index) => (
                          <option key={ `gg-page-${index}-${_index}` } value={ page.widget_id }>
                            { page.title }
                          </option>
                        )) }
                      </optgroup>
                    )) }
                  </select>
                </Col> : null }
          </Col>
          <Col>
          { this.state.form_ui === 'existing_page'
            ? <Col xs={10} xsOffset={1} className="form-row remove-padding">
                <Col xs={10} xsOffset={1} className="form-row">
                  {!this.state.shouldHideButton &&
                  <Row xs={10}>
                    <Col xs={2} className="labels">{window.I18n.t('multi_widgets.edit_column.title_label')}</Col>
                    <Col xs={7} className="form-group no-margin-bottom">
                      <input type="text"
                            placeholder={window.I18n.t('multi_widgets.edit_column.title_label')}
                            className="form-control"
                            value={ this.state.titles[this.props.currentLanguage] }
                            onChange={ e => this.setTitles(e.target.value, this.props.currentLanguage) } />
                    </Col>
                    <Col xs={3}>
                      <div className="labels link" onClick={ () => this.setTitles('', this.props.currentLanguage) }>
                        {window.I18n.t('multi_widgets.edit_column.clear_title')}
                      </div>
                    </Col>
                  </Row>
                  }
                  {this.state.usesTranslations && !this.state.shouldHideButton && 
                    <Row>
                      <a xs={10} className="translation_check" href="javascript:void(0)"
                              onClick={ e => { e.preventDefault(); this.setState({ shouldHide: false, shouldHideButton: true }) } } >
                      </a>
                      <label xs={2} className="translation_text"
                              onClick={ e => { e.preventDefault(); this.setState({ shouldHide: false, shouldHideButton: true }) } } >
                              {window.I18n.t('pages.edit.portal_page_options.add_translation')}
                      </label>
                    </Row>
                  }
                  {this.state.availableLanguages.map(key => (
                    <Row key={key} className={this.state.shouldHide ? 'hidden' : undefined}>
                      <Col xs={2} className="labels margin-bottom-20">{window.I18n.t('pages.edit.portal_page_options.title_' + key)}</Col>
                      <Col xs={7} className="form-group no-margin-bottom">
                        <input type="text"
                                placeholder={window.I18n.t('multi_widgets.edit_column.title_label')}
                                className="form-control"
                                value={ this.state.titles[key] }
                                onChange={ e => this.setTitles(e.target.value, key) } />
                      </Col>
                      <Col xs={3}>
                      <div className="labels link" onClick={ () => this.setTitles('', key) }>
                        {window.I18n.t('multi_widgets.edit_column.clear_title')}
                      </div>
                    </Col>
                    </Row>
                  ))}
                </Col>
              </Col> : null }
          </Col>

          { this.state.form_ui === 'existing_page'
            ? <Col xs={10} xsOffset={1} className="no-padding-left">
                <Col xs={4} className="labels"></Col>
                <Col xs={6} className="checkbox">
                  <div>
                    <label>
                      <input type="checkbox"
                             onChange={ () => this.setViewMore() }
                             defaultChecked />
                      <span dangerouslySetInnerHTML={{__html: window.I18n.t('multi_widgets.edit_column.show_view_more_link', {view_more: window.I18n.t('multi_widgets.edit_column.view_more')} )}}/>
                    </label>
                  </div>
                </Col>
              </Col> : null }

          <Col xs={10} xsOffset={1} className="form-row remove-padding">
            <Col xs={4} className="labels">
              <div className="radio">
                <label>
                  <input type="radio" id="new_page_checkbox" name="form_ui" value="new_page" defaultChecked={this.props.widgets.length === 0 ? true : false} onChange={ e => this.setFormUi(e.target.value) } />
                  {window.I18n.t('multi_widgets.edit_column.or_add_new_page')}
                </label>
              </div>
            </Col>
            { this.state.form_ui === 'new_page'
              ? <Col xs={6} className="form-group">
                  <button className="btn btn-primary"
                          onClick={ e => { e.preventDefault(); this.props.addNewPage() } }>
                    {window.I18n.t('multi_widgets.edit_column.add_new_page')}
                  </button>
                </Col> : null }
          </Col>
        </Col>

        <Col xs={10} xsOffset={1} className="button-row">
          <button className="button submit save-page save-with-external-link btn btn-primary pull-right"
                  onClick={ e => { e.preventDefault(); this.props.createColumn(this.props.blockID, this._widget, this.props.position, this.state.title, this.state.titles, this.state.view_more) } } >
                  {window.I18n.t('multi_widgets.save')}
          </button>
          <a className="button cancel grey btn btn-default pull-right margin-right-10"
             onClick={ e => { e.preventDefault(); this.props.cancel() } } >
            {window.I18n.t('multi_widgets.cancel')}
          </a>
        </Col>
      </Col>
    )
  }
}

EditColumns.propTypes = {
  widgets: PropTypes.array,
  blockID: PropTypes.any,
  position: PropTypes.string,
  createColumn: PropTypes.func.isRequired,
  addNewPage: PropTypes.func,
  cancel: PropTypes.func,
  usesTranslations: PropTypes.bool,
  availableLanguages: PropTypes.any,
  currentLanguage: PropTypes.any,
}

export default EditColumns
