import { createSelector } from 'reselect'
import { getPairingsFilteredByCourse } from './index'

const getStartingHole = state => state.filters.selectedHole

const getPairingsFilteredByStartingHole = createSelector(
  [ getStartingHole, getPairingsFilteredByCourse ],
  ( hole, foursomes ) => {
    if (hole === 'all') {
      return foursomes
    } else {
      return foursomes.filter( foursome => foursome.startingHole === hole)
    }
  }
)

export default getPairingsFilteredByStartingHole
