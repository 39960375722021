import React from 'react'
import configureStore from 'Shared/configure_store'
import { GlobalProvider } from 'Shared/global_context'
import PropTypes from 'prop-types'
import _ from 'lodash'
import TvShowSetupContainer from './tv_show_setup_container'
import setupReducer from '../reducers/tv_shows_setup'
import { NONE, XXS_SLIDES_COL_COUNT } from '../constants'

const TvShowSetupRoot = (props) => {
  const state = defaultConfiguration(props)
  const store = configureStore(setupReducer, state)

  return <GlobalProvider store={ store }><TvShowSetupContainer paths={ props.paths } extra={ props.extra } brand={ props.brand } canUseUsgaTheme={ props.canUseUsgaTheme }></TvShowSetupContainer></GlobalProvider>
}

const defaultConfiguration = (props) => {
  const {
    initialSettings,
    slides,
    slidesSourceData,
    libraryRounds,
    libraryTextHash,
  } = props

  const SPONSOR_LOGOS = [
    // 'https://i.pinimg.com/originals/fc/0c/60/fc0c60591f90c99c94e0afe9245886b0.png',
    // 'https://images.squarespace-cdn.com/content/v1/5964fece1b10e32d75d3db74/1551890845477-K4V8WRT5MJK5BIFVB6HP/ke17ZwdGBToddI8pDm48kEEXJqT6UZzo6_T_IEtk8lR7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QPOohDIaIeljMHgDF5CVlOqpeNLcJ80NK65_fV7S1UczENqjGi06s9wEQCnHDJzOWQBIE3BchO9-ZFyrxVDTT7zs2yPjc1ECvpa5Zm_kMqw/titleist-logo-png-transparent.png',
    // 'https://purepng.com/public/uploads/large/purepng.com-coca-cola-logococa-colacokecarbonated-soft-drinksoft-drink-1411527233307isidq.png',
    // 'https://gritdaily.com/wp-content/uploads/2020/06/Nike.jpg',
  ]
  Object.keys(libraryRounds).forEach( roundId => {
    libraryRounds[roundId].checked = false
    libraryRounds[roundId].input_name = 'round_' + (roundId).toString()
  })

  return {
    general: {
      hasUnsavedChanges: false,
      id: _.get(initialSettings, 'id'),
      currentRoundId: _.get(initialSettings, 'round_id'),
      viewDisplayed: 'main',
      name: _.get(initialSettings, 'name', ''),
      backgroundImage: _.get(initialSettings, 'general_settings.backgroundImage', NONE),
      sponsorLogos: _.get(initialSettings, 'general_settings.sponsorLogos', SPONSOR_LOGOS),
      hasUSGATheme: _.get(initialSettings, 'general_settings.hasUSGATheme') === 'true',
      isTemplate: _.get(initialSettings, 'is_template'),
      popups: {
        backgroundImage: {
          show: false,
          title: !window.I18n ? '' : window.I18n.t('tv_shows.containers.tv_show_setup_root.change_background_image'),
          uploadText: !window.I18n ? '' : window.I18n.t('tv_shows.containers.tv_show_setup_root.upload_background_image'),
          saveText: !window.I18n ? '' : window.I18n.t('tv_shows.containers.tv_show_setup_root.select_background'),
          saveDisabledText: !window.I18n ? '' : window.I18n.t('tv_shows.containers.tv_show_setup_root.selecting_background'),
        },
      },
    },
    header: {
      hasUnsavedChanges: false,
      isVisible: _.get(initialSettings, 'header_settings.isVisible') !== undefined ? initialSettings.header_settings.isVisible === 'true' : true,
      text: _.get(initialSettings, 'header_settings.text', null),
      height: parseInt(_.get(initialSettings, 'header_settings.height', 80)),
      selectedBackground: _.get(initialSettings, 'header_settings.selectedBackground', 'none'),
      backgroundColor: _.get(initialSettings, 'header_settings.backgroundColor', '#065c6f'),
      backgroundImage: _.get(initialSettings, 'header_settings.backgroundImage', 'https://i.pinimg.com/originals/96/42/1a/96421a97b83034f52c410befe7cefa20.jpg'),
      backgroundOpacity: parseInt(_.get(initialSettings, 'header_settings.backgroundOpacity', 73)),
      leftLogo: _.get(initialSettings, 'header_settings.leftLogo', NONE),
      rightLogo: _.get(initialSettings, 'header_settings.rightLogo', NONE),
      popups: {
        leftLogo: {
          show: false,
          title: !window.I18n ? '' : window.I18n.t('tv_shows.containers.tv_show_setup_root.change_left_logo'),
          uploadText: !window.I18n ? '' : window.I18n.t('tv_shows.containers.tv_show_setup_root.upload_logo'),
          saveText: !window.I18n ? '' : window.I18n.t('tv_shows.containers.tv_show_setup_root.select_logo'),
          saveDisabledText: !window.I18n ? '' : window.I18n.t('tv_shows.containers.tv_show_setup_root.selecting_logo'),
        },
        rightLogo: {
          show: false,
          title: !window.I18n ? '' : window.I18n.t('tv_shows.containers.tv_show_setup_root.change_right_logo'),
          uploadText: !window.I18n ? '' : window.I18n.t('tv_shows.containers.tv_show_setup_root.upload_logo'),
          saveText: !window.I18n ? '' : window.I18n.t('tv_shows.containers.tv_show_setup_root.select_logo'),
          saveDisabledText: !window.I18n ? '' : window.I18n.t('tv_shows.containers.tv_show_setup_root.selecting_logo'),
        },
        backgroundImage: {
          show: false,
          title: !window.I18n ? '' : window.I18n.t('tv_shows.containers.tv_show_setup_root.change_background_image'),
          uploadText: !window.I18n ? '' : window.I18n.t('tv_shows.containers.tv_show_setup_root.upload_background_image'),
          saveText: !window.I18n ? '' : window.I18n.t('tv_shows.containers.tv_show_setup_root.select_background'),
          saveDisabledText: !window.I18n ? '' : window.I18n.t('tv_shows.containers.tv_show_setup_root.selecting_background'),
        },
      },
    },
    footer: {
      hasUnsavedChanges: false,
      isVisible: _.get(initialSettings, 'footer_settings.isVisible') !== undefined ? initialSettings.footer_settings.isVisible === 'true' : true,
      text: _.get(initialSettings, 'footer_settings.text', null),
      height: parseInt(_.get(initialSettings, 'footer_settings.height', 20)),
      selectedBackground: _.get(initialSettings, 'footer_settings.selectedBackground', 'none'),
      backgroundColor: _.get(initialSettings, 'footer_settings.backgroundColor', '#065c6f'),
      backgroundImage: _.get(initialSettings, 'footer_settings.backgroundImage', 'https://i.pinimg.com/originals/96/42/1a/96421a97b83034f52c410befe7cefa20.jpg'),
      backgroundOpacity: parseInt(_.get(initialSettings, 'footer_settings.backgroundOpacity', 73)),
      popups: {
        backgroundImage: {
          show: false,
          title: !window.I18n ? '' : window.I18n.t('tv_shows.containers.tv_show_setup_root.change_background_image'),
          uploadText: !window.I18n ? '' : window.I18n.t('tv_shows.containers.tv_show_setup_root.upload_background_image'),
          saveText: !window.I18n ? '' : window.I18n.t('tv_shows.containers.tv_show_setup_root.select_background'),
          saveDisabledText: !window.I18n ? '' : window.I18n.t('tv_shows.containers.tv_show_setup_root.selecting_background'),
        },
      },
    },
    slides: {
      hasUnsavedChanges: false,
      slidePopup: {
        showSlidePopup: false,
        configuration: {
          type: 'none',
        },
      },
      sourceData: slidesSourceData || {},
      tvShowSlides: slides,
      currentColsCount: XXS_SLIDES_COL_COUNT,
      slidesLayout: [],
    },
    library: {
      hasUnsavedChanges: false,
      setTemplate: false,
      autoIncludeOption: 'do_not_include',
      textHash: libraryTextHash,
      rounds: libraryRounds,
    },
  }
}

TvShowSetupRoot.propTypes = {
  paths: PropTypes.object.isRequired,
  extra: PropTypes.object.isRequired,
  brand: PropTypes.string.isRequired,
  canUseUsgaTheme: PropTypes.bool.isRequired,
  libraryTextHash: PropTypes.object.isRequired,
  libraryRounds: PropTypes.object.isRequired,
}

export default TvShowSetupRoot
