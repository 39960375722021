import { createSelector } from 'reselect'
import _ from 'lodash'

import { mapToSelectArray } from 'Shared/helpers'

const getRoundsById = state => state.rounds

const getRoundOptions = createSelector(
  [ getRoundsById ],
  (roundsById) => {
    const sortedRounds = _.sortBy(roundsById, 'playAt')
    return mapToSelectArray(sortedRounds)
  }
)

export default getRoundOptions
