export const changeTeamColumnDisplay = (tableToDisplay) => {
  const teamDiv = $(tableToDisplay).find('table.result_scope:not(.ryder) td.name div.team')
  const teamAffiliationDiv = $(tableToDisplay).find('table.result_scope:not(.ryder) td.name div.affiliation.team')
  const playerDiv = $(tableToDisplay).find('table.result_scope:not(.ryder) td.name div.player')
  if (teamDiv.length > 0) {
    playerDiv.addClass('hidden')
    teamDiv.removeClass('hidden')
    teamAffiliationDiv.removeClass('hidden')
    alignFlagsUsga(tableToDisplay)
  }
}

export const removeAMPMFromTime = (tableToDisplay) => {
  let thruDivHeaders = $(tableToDisplay).find('table.result_scope:not(.ryder) th.thru')
  thruDivHeaders = thruDivHeaders.add($(tableToDisplay).find('table.result_scope:not(.ryder) th.past_round_thru'))

  let thruDivs = $(tableToDisplay).find('table.result_scope:not(.ryder) td.thru')
  thruDivs = thruDivs.add($(tableToDisplay).find('table.result_scope:not(.ryder) td.past_round_thru'))

  let AMPresent = false
  let PMPresent = false
  for (let i = 0; i < thruDivs.length; i++) {
    const thruText = $(thruDivs[i]).text()
    if (thruText.match(/(AM|am)/g)) {
      AMPresent = true
    }
    if (thruText.match(/(PM|pm)/g)) {
      PMPresent = true
    }
  }

  if (AMPresent && PMPresent) {
    $(thruDivHeaders).css('min-width', '5em')
  } else {
    for (let i = 0; i < thruDivs.length; i++) {
      const thruText = $(thruDivs[i]).text()
      $(thruDivs[i]).text(thruText.replace(/(AM|PM|am|pm)/, ''))
    }
  }
  return AMPresent && PMPresent
}

export const adjustColWidth = (containerSelector, sliderFontSize, bothAMPM, applyUSGATheme, matchPlay) => {
  if (!applyUSGATheme || matchPlay) {
    return
  }

  let thruDivHeaders = $(`${containerSelector} table:not(.ryder) th.thru`)
  thruDivHeaders = thruDivHeaders.add($(`${containerSelector} table:not(.ryder) th.past_round_thru`))

  $(`${containerSelector} table:not(.ryder) th:not(.name, .pos)`).css('width', sliderFontSize / 5 + '%')
  if (bothAMPM) {
    thruDivHeaders.css('width', (sliderFontSize - 5) / 2.5 + '%')
  } else {
    thruDivHeaders.css('width', sliderFontSize / 5 + '%')
  }
  $(`${containerSelector} table:not(.ryder) th.pos`).css('width', (sliderFontSize - 5) / 5 + '%') 
}

const alignFlagsUsga = (tableToDisplay) => {
  const countryFlags = $(tableToDisplay).find('table.result_scope:not(.ryder) td.name .flag-icon')
  const teamLogos = $(tableToDisplay).find('table.result_scope:not(.ryder) td.name .image-wrapper .div-image .image')
  countryFlags.css('vertical-align', 'baseline')
  teamLogos.css('vertical-align', 'text-top')

  const affiliation = $(tableToDisplay)
    .find('table.result_scope:not(.ryder) td.name .affiliation')
    .text()
  if (affiliation.trim() === '') {
    const stateFlags = $(tableToDisplay).find('table.result_scope:not(.ryder) td.name .flags svg')
    stateFlags.css('vertical-align', 'baseline')
  }
}

export const adjustCutListColspan = (tableToDisplay) => {
  const rows = $(tableToDisplay).find('tr.aggregate-row')
  if (rows.length === 0) {
    return
  }  

  const noOfColumns = $(rows[0]).children('td').length
  if (noOfColumns > 0) {
    $(tableToDisplay)
      .find('td.cut_list_tr, tr.total_purse_allocated td, tr.total_points_allocated td, td.section_list_tr')
      .attr('colSpan', noOfColumns)
  }
}
