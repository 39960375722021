import { connect } from 'react-redux'
import _ from 'lodash'

import NavigatorBookmarksComponent from '../components/navigator_bookmarks_component'

import { formatNavigatorOption } from '../navigator_helpers'

import { reorderBookmarks } from '../actions'

const mapStateToProps = state => {
  const navigatorOptions = state.navigatorOptions
  const bookmarkedNavigatorOptions = _.sortBy(state.bookmarks, 'position').map(
    bookmark => formatNavigatorOption(navigatorOptions[bookmark.id], state)
  )

  return {
    bookmarkedNavigatorOptions,
  }
}

const mapDispatchToProps = dispatch => ({
  reorderBookmarks: (fromOptionId, toOptionId) => dispatch(reorderBookmarks(fromOptionId, toOptionId)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavigatorBookmarksComponent)
