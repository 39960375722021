import { createSelector } from 'reselect'
import _ from 'lodash'
import getVisibleCategoryIds from './get_visible_category_ids'

const getCategoriesById = (state) => state.categories

const getSortedVisibleCategoryIds = createSelector(
  [ getVisibleCategoryIds, getCategoriesById ],
  (categoryIds, categoriesbyId) => {
    return _.sortBy(categoryIds, categoryId => categoriesbyId[categoryId].position)
  }
)

export default getSortedVisibleCategoryIds
