import { GlgPopup, GlgTooltipIcon } from 'SharedComponents'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Dropdown, Label, MenuItem } from 'react-bootstrap'

const SettingsModal = (props) => {

  const onSave = () => {

    const answer = customField !== 'default' ? window.confirm(window.I18n.t('member_cards.permanent_action_confirmation')) : true
    if (answer) {
      props.saveSettings(customField, handicapNameOption, userNameOption)
    }

    setCustomField('default')
  }

  const onSyncAll = (event) => {
    event.preventDefault()
    props.syncAllMrRecords()
    props.onClose()
  }

  const onRelinkAll = (event) => {
    event.preventDefault()
    props.relinkAllGolfers()
    props.onClose()
  }

  const onRecalculate = (event) => {
    event.preventDefault()
    props.recalculateAll()
    props.onClose()
  }

  const [ customField, setCustomField ] = useState('default')
  const [ handicapNameOption, setHandicapNameOption ] = useState(props.handicapNamingRule)
  const [ userNameOption, setUserNameOption ] = useState(props.userNamingRule)

  const STRENGTH_LABELS = {
    'strong': 'success',
    'medium': 'warning',
    'weak': 'danger',
    'manual': 'default',
    'original': 'default',
  }
  
  const NAME_OPTIONS = {
    'full_name': 'Full Name',
    'last_name': 'Last Name',
  }
  // labelLines are the ones without dropdowns

  const labelLine = (strength, text) => {
    return (
      <div className='setting-div'>
        <Label 
          className='strength-label settings' 
          bsStyle={STRENGTH_LABELS[strength]}>
            {window.I18n.t(`member_cards.${strength}`)}
        </Label>
        {text}
      </div> 
    )
  }

  // selectLines are the ones with a dropdown attached, so the settings part of the modal

  const selectLine = (strength, text, options, selectedOption, changeOption) => {
    return (
      <div className='setting-div'>
        <Label 
          className='strength-label settings' 
          bsStyle={STRENGTH_LABELS[strength]}>
            {window.I18n.t(`member_cards.${strength}`)}
        </Label>
        {text}
        <Dropdown className='settings-dropdown' id='settings-dropdown'>
          <Dropdown.Toggle>
            {options[selectedOption]}
          </Dropdown.Toggle>
          <Dropdown.Menu style={{overflow: 'auto', maxHeight: '12em'}}>
            {
              Object.keys(options).map( (key) => {
                return (
                  <MenuItem key = {key} eventKey = {key} onSelect= { (option) => {
                    changeOption(option)
                  } } >{options[key]}</MenuItem>
                )
              })
            }
          </Dropdown.Menu>
          { 
            ![ 'full_name', 'last_name', 'default' ].includes(selectedOption) &&
            <GlgTooltipIcon tooltip={window.I18n.t('member_cards.custom_field_tooltip', { field: options[selectedOption] })}/>
          }
        </Dropdown>

      </div> 
    ) 
  }

  return (
      <GlgPopup 
      title={window.I18n.t('member_cards.settings')}
      saveButtonText={window.I18n.t('member_cards.save')}
      saveButtonEnabled={ true }
      show={ props.show }
      modalClass='settings-modal'
      onClose={ props.onClose }
      onSave={ onSave }
      manualClose={ false }>
        <div className='settings-container'>
          <div className='settings-modal-header'>
            {window.I18n.t('member_cards.settings_description')}
          </div>
          { !props.ssoName && (
            props.mcIdentifierSet
              ? labelLine('strong', window.I18n.t('member_cards.match_on_custom_field', { custom_field_name: props.possibleCustomFields[customField]}))
              : selectLine('strong', window.I18n.t('member_cards.match_on'), props.possibleCustomFields, customField, setCustomField)
          )}
          { props.ssoName && labelLine('strong', window.I18n.t('member_cards.match_on_sso', {sso_name: props.ssoName}))}
          { props.ssoName && labelLine('strong', window.I18n.t('member_cards.match_on_identifier'))}
          {labelLine('strong', window.I18n.t('member_cards.match_on_handicap_and_email', { handicap_service: props.handicapProvider }))}
          {selectLine('strong', window.I18n.t('member_cards.match_on_handicap_and', {handicap_service: props.handicapProvider}), NAME_OPTIONS, handicapNameOption, setHandicapNameOption )}
          {labelLine('medium', window.I18n.t('member_cards.match_on_user_and_email'))}
          {selectLine('medium', window.I18n.t('member_cards.match_on_user_and'), NAME_OPTIONS, userNameOption, setUserNameOption )}
          {labelLine('weak', window.I18n.t('member_cards.match_on_email_and_name'))}
        </div>
        <h4>{window.I18n.t('member_cards.global_actions')}</h4>
        <div className="global-actions-link">
          <a href='#' onClick={onSyncAll}>{window.I18n.t('member_cards.sync_all_members')}</a>
        </div>
        <div className="global-actions-link">
          <a href='#' onClick={onRelinkAll}>{window.I18n.t('member_cards.link_all_members_without_card')}</a>
        </div>
        <div className="global-actions-link">
          <a href='#' onClick={onRecalculate}>{window.I18n.t('member_cards.recalculate_season_points')}</a>
        </div>
      </GlgPopup>
  )
}

SettingsModal.propTypes = {
  onClose: PropTypes.func,
  saveSettings: PropTypes.func,
  show: PropTypes.bool,
  possibleCustomFields: PropTypes.object,
  handicapProvider: PropTypes.string,
  ssoName: PropTypes.string,
  mcIdentifierSet: PropTypes.bool,
  handicapNamingRule: PropTypes.string,
  userNamingRule: PropTypes.string,
  syncAllMrRecords: PropTypes.func,
  relinkAllGolfers: PropTypes.func,
  recalculateAll: PropTypes.func,
}

export default SettingsModal
