import { createSelector } from 'reselect'
import { getPairingsFilteredByStartingHole } from './index'

const getSortOption = state => state.filters.sortByOption
const getOrder = state => state.filters.sortOrder

const getSortedPairings = createSelector(
  [ getSortOption, getOrder, getPairingsFilteredByStartingHole ],
  (sortOption, order, foursomes) => {
    if (sortOption === 'pairing') {
      return foursomes.slice(0).sort( (f1, f2) => {
        if (order === 'asc') {
          return f1.position - f2.position
        } else {
          return f2.position - f1.position
        }
      })
    } else if (sortOption === 'course_and_tee') {
      return foursomes.slice(0).sort( (f1, f2) => {
        if (order === 'asc') {
          if ( f1.courseName === f2.courseName ) {
            return f1.teeTime < f2.teeTime ? -1 : 1
          } else {
            return f1.courseName < f2.courseName ? -1 : 1
          }
        } else {
          if ( f2.courseName === f1.courseName) {
            return f2.teeTime < f1.teeTime ? -1 : 1
          } else {
            return f2.courseName < f1.courseName ? -1 : 1
          }
        }        
      })
    }
  }
)

export default getSortedPairings
