import _ from 'lodash'
import { connect } from 'react-redux'
import fuzzySearch from '../fuzzy_search'

import NavigatorPanel from '../components/navigator_panel_component'

import {
  fetchInitialData,
  changeFilter,
  saveBookmarksTemplate,
  clearAllBookmarks,
  changeDropdownState,
} from '../actions'

import { formatNavigatorOption } from '../navigator_helpers'

const mapStateToProps = state => {
  const navigatorOptions = state.navigatorOptions
  let visibleNavigatorOptions = _.filter(navigatorOptions, { disabled: false })

  const results = fuzzySearch(
    visibleNavigatorOptions,
    state.misc.navigatorFilter, {
      relevancyFilter: 0.3,
    }
  )

  visibleNavigatorOptions = results.map(navigatorOption => formatNavigatorOption(navigatorOption, state))

  const context = state.misc.context
  const savedTemplateOptionIds = state.misc.savedTemplateOptionIds || []
  const bookmarks = state.bookmarks
  const anyBookmarks = !_.isEmpty(bookmarks)
  const currentBookmarkedOptions = _.sortBy(bookmarks, 'position')
  const currentBookmarkedOptionIds = _.map(currentBookmarkedOptions, 'id')
  const changedBookmarkedOptionIds = !_.isEqual(savedTemplateOptionIds,
                                                currentBookmarkedOptionIds)
  const showSaveBookmarks = context === 'event' && anyBookmarks && changedBookmarkedOptionIds

  return {
    navigatorOptions: visibleNavigatorOptions,
    navigatorFilter: state.misc.navigatorFilter,
    dataIsLoading: state.misc.dataIsLoading,
    dataIsRequested: state.misc.dataIsRequested,
    context,
    showSaveBookmarks,
    anyBookmarks,
  }
}

const mapDispatchToProps = dispatch => ({
  fetchInitialData: () => dispatch(fetchInitialData()),
  onFilterChange: filter => dispatch(changeFilter(filter)),
  saveBookmarksTemplate: () => dispatch(saveBookmarksTemplate()),
  clearAllBookmarks: () => dispatch(clearAllBookmarks()),
  changeDropdownState: isOpen => dispatch(changeDropdownState(isOpen)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavigatorPanel)
