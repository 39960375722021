import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import { Row, Col } from 'react-flexbox-grid'

import { GlgIcon } from 'SharedComponents'

class ViewType extends PureComponent {
  constructor(props) {
    super(props)
    this.handleCondensedViewClick = this.handleCondensedViewClick.bind(this)
    this.handleRegularViewClick = this.handleRegularViewClick.bind(this)
  }

  handleCondensedViewClick() {
    if (!this.props.disabled) {
      this.props.onChange('viewType', 'condensed')
    }
  }

  handleRegularViewClick() {
    if (!this.props.disabled) {
      this.props.onChange('viewType', 'regular')
    }
  }
  render() {
    const condensedViewStatus = this.props.viewType === 'condensed' ? 'active' : 'inactive'
    const condensedClasses = `condensed-view ${ condensedViewStatus }`
    const regularViewStatus = this.props.viewType === 'regular' ? 'active' : 'inactive'
    const regularClasses = `list-view ${ regularViewStatus }`
    const iconClass = this.props.disabled ? '' : 'clickable'
    const condensedViewTooltip = <Tooltip id="condensed-view-tooltip">  {
      !window.I18n ? '' : window.I18n.t('customer_directories.filters.view_type_condensed')
    }</Tooltip>
    const regularViewTooltip = <Tooltip id="regular-view-tooltip">  {
      !window.I18n ? '' : window.I18n.t('customer_directories.filters.view_type_detailed')
    }</Tooltip>
    
    let displayView = true
    let width = window.innerWidth
    try {
      width = window.parent.innerWidth
    } catch (err) {
      // 1 == 1
    }
    if (window.glg.new_design_shared.isMobile() || width < 992) {
      displayView = false
    }
    
    return <Row center="xs" className={ 'view-type-filter ' + (displayView ? '' : 'hidden') }><Col xs={true}><div onClick={ this.handleCondensedViewClick } className={ condensedClasses }><OverlayTrigger placement="top" overlay={ condensedViewTooltip }><GlgIcon icon="four-lines" iconClass={ iconClass }></GlgIcon></OverlayTrigger></div></Col><Col xs={true} className="with-separator"><div onClick={ this.handleRegularViewClick } className={ regularClasses }><OverlayTrigger placement="top" overlay={ regularViewTooltip }><GlgIcon icon="list-view" iconClass={ iconClass }></GlgIcon></OverlayTrigger></div></Col></Row>
  }
}

ViewType.propTypes = {
  viewType: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default ViewType
