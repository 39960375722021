import { createSelector } from 'reselect'

const getPlayers = state => state.misc.players
const getNoRows = state => state.misc.noRowsOnCourseLeaderboard

const getPlayersOnBoard = createSelector(
  [ getPlayers, getNoRows ],
  ( players, noRows ) => {
    return [ ...Array(noRows) ].map( (_, pos) => {
      const player = players.find( p => p.position === pos)
      return player === undefined ? { id: `${pos}`, empty: true, position: pos } : { ...player, empty: false }
    })
  }
)

export default getPlayersOnBoard
