import React from 'react'
import PropTypes from 'prop-types'


const OttErrorPopup = ({message, handleClose}) => {
  return (
    <div className={'popup-container-style'}>
      <div className={'popup-style'}>
        <div className={'ott-title-footer-style'}> {message} </div>
        <div className={'button-container-style'}>
          <div className={'back-button-style'} onClick={handleClose}>
            Back
          </div>
        </div>
      </div>
    </div>
  )
}

OttErrorPopup.propTypes = {
  message: PropTypes.string,
  handleClose: PropTypes.func,
}

export default OttErrorPopup
