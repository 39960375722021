import React from 'react'
import Flyers from './Flyers'
import PropTypes from 'prop-types'

const FlyersList = (props) => {

  const hasCustomFlyers = props.customFlyers.length !== 0

  return (
    <div className="list-of-flyers">
      <div className="list-1">
        <Flyers
          flyers={props.customFlyers}
          canDelete={true}
          leagueId={props.leagueId}
          customerId={props.customerId}
          isAdmin={props.isAdmin}
          isSystemTemplate={false}
          showCategory={hasCustomFlyers}
        />
      </div>
      <div className="list-2">
        <Flyers
          flyers={props.systemFlyers}
          canDelete={false}
          leagueId={props.leagueId}
          customerId={props.customerId}
          isAdmin={props.isAdmin}
          isSystemTemplate={true}
          showCategory={!hasCustomFlyers}
          />
      </div>
    </div>
  )
}

FlyersList.propTypes = {
  customFlyers: PropTypes.array,
  systemFlyers: PropTypes.array,
  leagueId: PropTypes.string,
  customerId: PropTypes.number,
  isAdmin: PropTypes.bool,
}

export default FlyersList
