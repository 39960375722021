/*
 * Returns leagues that pass all filters
 * Received optional 3rd parameter categoryId: it only takes leagues from that category
 */
import _ from 'lodash'
// import moment from 'moment'
import createDeepSelector from './create_deep_selector'

import checkShowSeasons from './check_show_seasons'
import checkShowTours from './check_show_tours'
// import { getTodayWithTimezone } from 'Shared/helpers'
// import RegistrationFilterTypes from '../registration_filter_types'

const getLeaguesById = state => state.leagues
const getCategoryLeagueIds = (state, categoryId) => {
  if (categoryId) {
    return state.categories[categoryId].leagues
  } else {
    return _.keys(state.leagues)
  }
}
const getCategoriesById = state => state.categories
const getFilters = state => {
  const filters = _.clone(state.filters)
  const showSeasons = checkShowSeasons(state, { checkLeagues: true })
  if (!showSeasons) {
    filters['season'] = 'all'
  }
  const showTours = checkShowTours(state, { checkLeagues: true })
  if (!showTours) {
    filters['tour'] = 'all'
  }
  return filters
}

export const filterLeagueIds = (leagueIds, leaguesById, categoriesById, filters) => {
  let {
    leagueName,
  } = filters
  const {
    season,
    tour,
    // registration,
    category,
  } = filters

  leagueIds = Object.keys(leaguesById).filter(value => leagueIds.includes(value))

  if (leagueName.length > 0) {
    leagueName = leagueName.toLowerCase()
    leagueIds = leagueIds.filter(leagueId => (
      leaguesById[leagueId].name.toLowerCase().includes(leagueName)
    ))
  }

  if (season !== 'all') {
    leagueIds = leagueIds.filter(leagueId => (
      leaguesById[leagueId].season === season ||
      (season === 'none' && _.isEmpty(leaguesById[leagueId].season))
    ))
  }

  // This is useful only for list view
  if (category !== 'all') {
    leagueIds = leagueIds.filter(leagueId => (
      leaguesById[leagueId].category === category
    ))
  }

  if (tour !== 'all') {
    leagueIds = leagueIds.filter(leagueId => (
      leaguesById[leagueId].tour === tour ||
      (tour === 'none' && _.isEmpty(leaguesById[leagueId].tour))
    ))
  }

  // if (registration !== RegistrationFilterTypes.ALL) {
  //   let filterMethod
  //   if (registration === RegistrationFilterTypes.UPCOMING) {
  //     filterMethod = leagueId => {
  //       const startDate = leaguesById[leagueId].startDate
  //       const today = getTodayWithTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)
  //       return moment(today).isBefore(startDate)
  //     }
  //   } else if (registration === RegistrationFilterTypes.PAST) {
  //     filterMethod = leagueId => {
  //       const startDate = leaguesById[leagueId].startDate
  //       const today = getTodayWithTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)
  //       return moment(startDate).isBefore(today)
  //     }
  //   } else if (registration === RegistrationFilterTypes.OPEN) {
  //     filterMethod = leagueId => {
  //       const data = leaguesById[leagueId].openRegistrationData
  //       return data.hasOpenRegistration && data.openRegistrationStatus === 'open'
  //     }
  //   } else if (registration === RegistrationFilterTypes.WILL_OPEN) {
  //     filterMethod = leagueId => {
  //       const data = leaguesById[leagueId].openRegistrationData
  //       const today = getTodayWithTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)
  //       const earliestMembershipOpeningDate = _.min(data.membershipProgramsInfo.map(mp => mp.startDate))
  //       const earliestRegistrationOpening = data.registrationInvitationListStartsOn ||
  //                                           earliestMembershipOpeningDate ||
  //                                           data.registrationStartsOn
  //       return data.hasOpenRegistration &&
  //              earliestRegistrationOpening &&
  //              today.isBefore(moment(earliestRegistrationOpening)) &&
  //              (!data.registrationEndsOn ||
  //               moment(earliestRegistrationOpening).isBefore(moment(data.registrationEndsOn)))
  //     }
  //   } else if (registration === RegistrationFilterTypes.OPEN_FOR_INVITATION) {
  //     filterMethod = leagueId => {
  //       const data = leaguesById[leagueId].openRegistrationData
  //       return data.hasOpenRegistration && data.openRegistrationStatus === 'invitation_list'
  //     }
  //   } else if (registration === RegistrationFilterTypes.OPEN_FOR_MEMBERSHIP) {
  //     filterMethod = leagueId => {
  //       const data = leaguesById[leagueId].openRegistrationData
  //       return data.hasOpenRegistration && data.openRegistrationStatus === 'membership'
  //     }
  //   } else if (registration === RegistrationFilterTypes.CLOSED) {
  //     filterMethod = leagueId => {
  //       const data = leaguesById[leagueId].openRegistrationData
  //       return data.hasOpenRegistration && data.openRegistrationStatus === ''
  //     }
  //   } else if (registration === RegistrationFilterTypes.WAITLIST_NOT_EMPTY) {
  //     filterMethod = leagueId => (
  //       leaguesById[leagueId].golfers.waitlisted > 0
  //     )
  //   } else if (registration === RegistrationFilterTypes.PENDING_LIST_NOT_EMPTY) {
  //     filterMethod = leagueId => (
  //       leaguesById[leagueId].golfers.pending > 0
  //     )
  //   } else if (registration === RegistrationFilterTypes.NON_ARCHIVED) {
  //     filterMethod = leagueId => {
  //       const leagueCategoryId = leaguesById[leagueId].category
  //       const leagueCategory = categoriesById[leagueCategoryId]
  //       return leagueCategory && leagueCategory.specialStatus !== 'archived'
  //     }
  //   }
  //   leagueIds = leagueIds.filter(filterMethod)
  // }

  return leagueIds
}

const getVisibleLeagueIds = createDeepSelector(
  [ getCategoryLeagueIds, getLeaguesById, getCategoriesById, getFilters ],
  filterLeagueIds
)

export default getVisibleLeagueIds
