import createDeepSelector from './create_deep_selector'
import _ from 'lodash'

const getCategoriesNamesById = state => _.mapValues(state.categories, category => category.name)

const getCategoriesNames = createDeepSelector(
  [ getCategoriesNamesById ],
  _.identity
)

export default getCategoriesNames
