import { createSelector } from 'reselect'

const getCloneLeaguesDataList = state => state.misc.cloneLeaguesDataList

const getFromLeaguesOptions = createSelector(
  [ getCloneLeaguesDataList ],
  (cloneLeaguesDataList) => cloneLeaguesDataList
)

export default getFromLeaguesOptions
