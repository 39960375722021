// Libraries
import React, { useState, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import 'moment-timezone'
import Toggle from 'react-bootstrap-toggle'
import DatePicker from 'react-datepicker'

// Shared Components
import PaginationComponent from '../../player_activity/components/participation_history/pagination_component'
import SaveBar from './save_bar_component'
import { GlgLoadingSpinner, GlgSelect, GlgMultiSelect } from 'SharedComponents'
import Checkbox from '../../dashboard/components/content/event_setup/checkbox'
import CalendarCustomInput from '../../dashboard/components/content/event_setup/calendar_custom_input'

// Key Settings specifics
import {
  editLeague, editRound, updateSettings, fetchTableData, toggleLeagueCheckbox, 
  toggleRoundCheckbox, markDataAsUnfetched, markDataAsFetched, editLeagueRegistration,
  editLeagueCoursesEnabled, loadCoursesEnabled,
} from '../actions'
import flagSvg from '../../../../assets/images/key_settings/flag.svg'
import fieldsIcon from '../../../../assets/images/bootstrap_theme/members/fields.png'
import { Scrollbar } from 'react-scrollbars-custom'
import KeySettingsFilteringTab from './key_settings_filtering_tab'
import { generateTimeItems } from '../key_settings_utils'
import KeySettingsBulkEdit from './key_settings_bulk_edit'
import KeySettingsTh from './key_settings_th'
import KeySettingsThGroup from './key_settings_th_group'
import GlgTooltip from 'SharedComponents/glg_tooltip'

// Constants & Statics
const elementsOnPage = 100

const defaultVisibleColumns = {
  leagueName: true,
  roundName: true,
  date: true,
  qualifying: true,
  autoScorePosting: true,
  masterRosterCoupling: true,
  settingLevel: true,
  minNumberOfMembers: true,
  maxNumberOfMembers: true,
  minNumberOfGuests: true,
  maxNumberOfGuests: true,
  registrationOpensInvitation: true,
  registrationOpens: true,
  registrationCloses: true,
  communicationOfficer: true,
  hiService: true,
  autoHiUpdateFromMrIntoEr: true,
  openTeeTimes: true,
  enableDefaultCourseAndTee: true,
  defaultCourse: true,
  holeType: true,
  defaultTeeM: true,
  defaultTeeF: true,
  autoMoveRoundToInProgress: true,
  moveToInProgressAt: true,
  autoMoveRoundToCompleted: true,
  moveToCompletedAt: true,
  tournaments: true,
  coursesEnabled: true,
  memberRegistrationFields: true,
  guestRegistrationFields: true,
}

const columnsLabels = {
  leagueName: !window.I18n ? '' : window.I18n.t('key_settings.table.league_name'),
  roundName: !window.I18n ? '' : window.I18n.t('key_settings.table.round_name'),
  date: !window.I18n ? '' : window.I18n.t('key_settings.table.date'),
  qualifying: !window.I18n ? '' : window.I18n.t('key_settings.table.qualifying'),
  autoScorePosting: !window.I18n ? '' : window.I18n.t('key_settings.table.auto_score_posting'),
  masterRosterCoupling: !window.I18n ? '' : window.I18n.t('key_settings.table.master_roster_coupling'),
  settingLevel: !window.I18n ? '' : window.I18n.t('key_settings.table.setting_level'),
  minNumberOfMembers: !window.I18n ? '' : window.I18n.t('key_settings.table.min_number_of_members'),
  maxNumberOfMembers: !window.I18n ? '' : window.I18n.t('key_settings.table.max_number_of_members'),
  minNumberOfGuests: !window.I18n ? '' : window.I18n.t('key_settings.table.min_number_of_guests'),
  maxNumberOfGuests: !window.I18n ? '' : window.I18n.t('key_settings.table.max_number_of_guests'),
  registrationOpensInvitation: !window.I18n ? '' : window.I18n.t('key_settings.table.registration_opens_invitation'),
  registrationOpens: !window.I18n ? '' : window.I18n.t('key_settings.table.registration_opens'),
  registrationCloses: !window.I18n ? '' : window.I18n.t('key_settings.table.registration_closes'),
  communicationOfficer: !window.I18n ? '' : window.I18n.t('key_settings.table.communication_officer'),
  hiService: !window.I18n ? '' : window.I18n.t('key_settings.table.hi_service'),
  autoHiUpdateFromMrIntoEr: !window.I18n ? '' : window.I18n.t('key_settings.table.auto_hi_update_from_mr_into_er'),
  openTeeTimes: !window.I18n ? '' : window.I18n.t('key_settings.table.open_tee_times'),
  enableDefaultCourseAndTee: !window.I18n ? '' : window.I18n.t('key_settings.table.show_default_tee'),
  defaultCourse: !window.I18n ? '' : window.I18n.t('key_settings.table.default_course'),
  holeType: !window.I18n ? '' : window.I18n.t('key_settings.table.hole_type'),
  defaultTeeM: !window.I18n ? '' : window.I18n.t('key_settings.table.default_tee_m'),
  defaultTeeF: !window.I18n ? '' : window.I18n.t('key_settings.table.default_tee_f'),
  autoMoveRoundToInProgress: !window.I18n ? '' : window.I18n.t('key_settings.table.auto_move_round_to_in_progress'),
  moveToInProgressAt: !window.I18n ? '' : window.I18n.t('key_settings.table.move_to_in_progress_at'),
  autoMoveRoundToCompleted: !window.I18n ? '' : window.I18n.t('key_settings.table.auto_move_round_to_completed'),
  moveToCompletedAt: !window.I18n ? '' : window.I18n.t('key_settings.table.move_to_completed_at'),
  tournaments: !window.I18n ? '' : window.I18n.t('key_settings.table.tournaments'),
  coursesEnabled: !window.I18n ? '' : window.I18n.t('key_settings.table.courses_enabled'),
  memberRegistrationFields: !window.I18n ? '' : window.I18n.t('key_settings.table.member_registration_fields'),
  guestRegistrationFields: !window.I18n ? '' : window.I18n.t('key_settings.table.guest_registration_fields'),
}

// Component
const Layout = () => {
  const dispatch = useDispatch()

  const loadVisibleColumnsFromLocalStorage = () => {
    localStorage.clear()
    const storedColumns = localStorage.getItem('visibleColumns')
    return storedColumns ? JSON.parse(storedColumns) : defaultVisibleColumns
  }

  const saveVisibleColumnsToLocalStorage = (visibleColumns) => {
    localStorage.setItem('visibleColumns', JSON.stringify(visibleColumns))
  }

  // Local state
  const [ currentPage, setCurrentPage ] = useState(1)
  const [ searchQuery, setSearchQuery ] = useState('')
  const [ loading, setLoading ] = useState(false)
  const [ expandedLeagues, setExpandedLeagues ] = useState([])
  const [ isInitialLoading, setIsInitialLoading ] = useState(true)
  const [ visibleColumns, setVisibleColumns ] = useState(loadVisibleColumnsFromLocalStorage)
  const [ selectedCategories, setSelectedCategories ] = useState([ { value: 'All', label: 'All' } ])
  const [ selectedSeasons, setSelectedSeasons ] = useState([ { value: 'All', label: 'All' } ])
  const [ advancedQueryData, setAdvancedQueryData ] = useState([])
  const [ bulkEditData, setBulkEditData ] = useState([])
  const [ showColumnsFilter, setShowColumnsFilter ] = useState(false)
  const [ showPastRecords, setShowPastRecords ] = useState(false)
  const [ isRoundMiscellaneousExpanded, setIsRoundMiscellaneousExpanded ] = useState(false)
  const [ isRoundStatusAutomationExpanded, setIsRoundStatusAutomationExpanded ] = useState(false)
  const [ isRegistrationCenterExpanded, setIsRegistrationCenterExpanded ] = useState(false)
  const [ isDefaultCourseAndTeesExpanded, setIsDefaultCourseAndTeesExpanded ] = useState(false)
  const [ countExpandedTabs, setCountExpandedTabs ] = useState(0)

  // Redux states
  const state = useSelector(state => state)
  const data = state['table']['data']['table']
  const modifiedData = state['table']['modifiedData']
  const modifiedDataCount = state['table']['modifiedDataCount']
  const totalLeagues = state['table']['data']['total_leagues']
  const totalRounds = state['table']['data']['total_rounds']
  const currentSeason = state['table']['current_season']
  const leaguesAndRoundsSelected = state['table']['data']['leagues_and_rounds_selected']
  const isDataFetched = state['table']['isDataFetched']
  const canInvitationList = state['table']['data']['can_invitation_list']
  const canAlwaysShowMrCoupling = state['table']['data']['can_always_show_mr_coupling']
  const canOpenRegistration = state['table']['data']['can_open_registration']
  const isUkWhs = state['table']['data']['is_uk_whs']
  const isMounted = useRef(false)
  const filterRef = useRef(null)

  // Use Effects
  useEffect(() => {
    if (!isMounted.current) {
      return
    }

    refreshTable(false, currentPage, () => {
      setLoading(false)
    })
  }, [ currentPage ])

  useEffect(() => {
    if (!isMounted.current) {
      return
    }

    setCurrentPage(1)

    const timer = setTimeout(() => {
      refreshTable(false, 1, () => {
        setLoading(false)
      })
    }, 500)

    return () => clearTimeout(timer) 
  }, [ searchQuery ])

  useEffect(() => {
    if (!isMounted.current) {
      return
    }

    setCurrentPage(1)

    if (isInitialLoading) {
      setIsInitialLoading(false)
      return
    }
    refreshTable(true, 1)
  }, [ selectedCategories, selectedSeasons, advancedQueryData, showPastRecords ])

  useEffect(() => {
    setSelectedSeasons([ { value: currentSeason, label: currentSeason } ])
    dispatch(markDataAsFetched())
    dispatch(loadCoursesEnabled())
    isMounted.current = true
  }, [ ])


  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    const updatedColumns = { ...visibleColumns }

    if (!canInvitationList) {
      delete updatedColumns.registrationOpensInvitation
    }

    if (!canAlwaysShowMrCoupling) {
      delete updatedColumns.masterRosterCoupling
    }

    if (!canOpenRegistration) {
      delete updatedColumns.minNumberOfMembers
      delete updatedColumns.maxNumberOfMembers
      delete updatedColumns.minNumberOfGuests
      delete updatedColumns.maxNumberOfGuests
      delete updatedColumns.registrationOpens
      delete updatedColumns.registrationCloses
    }

    if (!isUkWhs) {
      delete updatedColumns.qualifying
      delete updatedColumns.autoScorePosting
    }

    setVisibleColumns(updatedColumns)
    
    saveVisibleColumnsToLocalStorage(updatedColumns)
  }, [ canInvitationList, canAlwaysShowMrCoupling, canOpenRegistration, isUkWhs ])

  // Render and Helper Functions
  const refreshTable = (recreateLeaguesAndRoundsSelected, page, callback) => {
    dispatch(markDataAsUnfetched())

    const categoriesString = generateFilterString(selectedCategories)
    const seasonsString = generateFilterString(selectedSeasons)

    dispatch(fetchTableData(page, searchQuery, categoriesString, seasonsString, advancedQueryData, recreateLeaguesAndRoundsSelected, callback, showPastRecords))
  }

  const renderLeaguesRoundsInfo = () => {
    if (!data) {
      return null
    }

    return (
      <div className="league-info-box">
        <span className="info-section">
          <span className="number">{totalLeagues}</span>
          <span className="label">{!window.I18n ? '' : window.I18n.t('key_settings.table.events_and_leagues')}</span>
        </span>
        <span className="vertical-line"></span>
        <span className="info-section">
          <span className="number">{totalRounds}</span>
          <span className="label">{!window.I18n ? '' : window.I18n.t('key_settings.table.rounds')}</span>
        </span>
      </div>
    )
  }

  const renderColumnsFilter = () => {
    return (
      <div className="columns-filter-dropdown" ref={filterRef}>
        <button className='columns-filter-btn' onClick={handleClickColumnsFilter}>
          <img className="columns-filter-icon" src={fieldsIcon} alt="" />
          <span>{!window.I18n ? '' : window.I18n.t('key_settings.table.columns')}</span>
        </button>
        {showColumnsFilter &&
          <div className='columns-filter-dropdown-content'>
            {Object
            .entries(visibleColumns)
            .filter(([ columnName ]) => ![ 'leagueName', 'roundName', 'settingLevel' ].includes(columnName))
            .sort((a, b) => {
              // Sort by the label text
              const labelA = columnsLabels[a[0]]
              const labelB = columnsLabels[b[0]]
              return labelA.localeCompare(labelB)
            })
            .map(([ columnName, isVisible ], index, array) => (
              <div className="columns-filter-dropdown-content-row" key={columnName}>
                <Checkbox
                  className="custom-checkbox"
                  selected={isVisible}
                  setOption={() => toggleColumnVisibility(columnName)}
                />
                <label>{columnsLabels[columnName]}</label>
                {index < array.length - 1 && ' '} 
              </div>
            ))}
          </div>
        }
      </div>
    )
  }  

  const renderSearchBar = () => {
    return (
      <div className='leagues-search-bar'>
        <input className='search-leagues-input' type="text" value={searchQuery} onChange={(e) => handleSearchQuery(e)} placeholder={!window.I18n ? '' : window.I18n.t('key_settings.table.search_league')} />
        <button className='clear-button' type="button" onClick={handleClearSearch}>
          <span className='fa fa-eraser'></span>
        </button>
      </div>
    )
  }

  const [ leaguesDataValidations, disableSaveButton ] = (() => {
    let tempDisableSaveButton = false

    const validations = Object.keys(data).reduce((validationMapAcc, leagueId) => {
      const isRegEnabledAndOpen = data[leagueId]['registration_form']['enable_open_registration'] && data[leagueId]['registration_form']['open_registration']

      const startOpenDate = moment(
        modifiedData[leagueId]?.registration_form?.registration_starts_on || 
        data[leagueId]?.registration_form?.registration_starts_on, 
        'YYYY-MM-DD HH:mm:ss'
      )
      const endOpenDate = moment(
        modifiedData[leagueId]?.registration_form?.registration_ends_on || 
        data[leagueId]?.registration_form?.registration_ends_on, 
        'YYYY-MM-DD HH:mm:ss'
      )
      const isOpenDateAfterCloseDate = startOpenDate.isValid() && endOpenDate.isValid() && startOpenDate.isAfter(endOpenDate)

      // Validation for open registration for invitation dates
      const startOpenDateInvitation = moment(
        modifiedData[leagueId]?.registration_form?.registration_starts_on_for_invitations || 
        data[leagueId]?.registration_form?.registration_starts_on_for_invitations, 
        'YYYY-MM-DD HH:mm:ss'
      )
      const endOpenDateInvitation = moment(
        modifiedData[leagueId]?.registration_form?.registration_ends_on || 
        data[leagueId]?.registration_form?.registration_ends_on, 
        'YYYY-MM-DD HH:mm:ss'
      )
      const isOpenDateInvitationAfterCloseDate = startOpenDateInvitation.isValid() && endOpenDateInvitation.isValid() && startOpenDateInvitation.isAfter(endOpenDateInvitation)
      
      const minNumberOfMembers = parseInt(
        modifiedData[leagueId]?.registration_form?.min_number_of_members
          ? modifiedData[leagueId].registration_form.min_number_of_members || 0
          : data[leagueId]?.registration_form?.min_number_of_members || 0
      ) 

      const maxNumberOfMembers = parseInt(
        modifiedData[leagueId]?.registration_form?.max_number_of_members
          ? modifiedData[leagueId].registration_form.max_number_of_members || 0
          : data[leagueId]?.registration_form?.max_number_of_members || 0
      )
        
      //Validation for min number of members
      const isMinNumberOfMembersValid = minNumberOfMembers <= maxNumberOfMembers


      const minNumberOfGuests = parseInt(
        modifiedData[leagueId]?.registration_form?.min_number_of_guests
          ? modifiedData[leagueId].registration_form.min_number_of_guests || 0
          : data[leagueId]?.registration_form?.min_number_of_guests || 0
      ) 

      const maxNumberOfGuests = parseInt(
        modifiedData[leagueId]?.registration_form?.max_number_of_guests
          ? modifiedData[leagueId].registration_form.max_number_of_guests || 0
          : data[leagueId]?.registration_form?.max_number_of_guests || 0
      )

      //Validation for min number of guests
      const isMinNumberOfGuestsValid = minNumberOfGuests <= maxNumberOfGuests
      
      if (isRegEnabledAndOpen) {
        tempDisableSaveButton ||= (
          isOpenDateAfterCloseDate || 
          isOpenDateInvitationAfterCloseDate || 
          !isMinNumberOfMembersValid || 
          !isMinNumberOfGuestsValid
        )
      }
      
      validationMapAcc[leagueId] = {
        isOpenDateAfterCloseDate: isOpenDateAfterCloseDate,
        isOpenDateInvitationAfterCloseDate: isOpenDateInvitationAfterCloseDate,
        isMinNumberOfMembersValid: isMinNumberOfMembersValid,
        isMinNumberOfGuestsValid: isMinNumberOfGuestsValid,
      }

      return validationMapAcc
    }, {})

    return [ validations, tempDisableSaveButton ]
  })()

  const renderKeyTable = () => {
    if (!data) {
      return null
    }
  
    const leagueKeys = Object.keys(data)
  
    return (
      <Scrollbar style={{height: '60vh'}}>
        <table className="key_settings table table-drop table-striped index_table tables_v2 no-margin-bottom">
          <thead>
            <tr>
              <th></th>
              {visibleColumns.leagueName && 
                <KeySettingsTh settingName={!window.I18n ? '' : window.I18n.t('key_settings.table.league_name')} isHeaderTwoLeveled={countExpandedTabs > 0} />
              }
              {visibleColumns.roundName && 
                <KeySettingsTh settingName={!window.I18n ? '' : window.I18n.t('key_settings.table.round_name')} isHeaderTwoLeveled={countExpandedTabs > 0} />
              }
              {visibleColumns.settingLevel &&
                <KeySettingsTh settingName={!window.I18n ? '' : window.I18n.t('key_settings.table.setting_level')} isHeaderTwoLeveled={countExpandedTabs > 0} />
              }
              {visibleColumns.date &&
                <KeySettingsTh settingName={!window.I18n ? '' : window.I18n.t('key_settings.table.date')} isHeaderTwoLeveled={countExpandedTabs > 0} />
              }
              {visibleColumns.masterRosterCoupling && canAlwaysShowMrCoupling &&
                <KeySettingsTh settingName={!window.I18n ? '' : window.I18n.t('key_settings.table.master_roster_coupling')} isHeaderTwoLeveled={countExpandedTabs > 0} />
              }

              <KeySettingsThGroup 
                name={!window.I18n ? '' : window.I18n.t('key_settings.table.round_miscellaneous')} 
                isExpanded={isRoundMiscellaneousExpanded}
                isHeaderTwoLeveled={countExpandedTabs > 0}
                onToggle={() => { 
                  isRoundMiscellaneousExpanded ? setCountExpandedTabs(countExpandedTabs - 1) : setCountExpandedTabs(countExpandedTabs + 1)
                  setIsRoundMiscellaneousExpanded(!isRoundMiscellaneousExpanded) 
                }}
              >
                {visibleColumns.qualifying && isUkWhs &&
                  <KeySettingsTh settingName={!window.I18n ? '' : window.I18n.t('key_settings.table.qualifying')} />
                }
                {visibleColumns.autoScorePosting && isUkWhs &&
                  <KeySettingsTh settingName={!window.I18n ? '' : window.I18n.t('key_settings.table.auto_score_posting')} />
                }
                {visibleColumns.openTeeTimes &&
                  <KeySettingsTh settingName={!window.I18n ? '' : window.I18n.t('key_settings.table.open_tee_times')} />
                }
              </KeySettingsThGroup>

              <KeySettingsThGroup 
                name={!window.I18n ? '' : window.I18n.t('key_settings.table.round_status_automation')}
                isExpanded={isRoundStatusAutomationExpanded}
                isHeaderTwoLeveled={countExpandedTabs > 0}
                onToggle={() => {
                  isRoundStatusAutomationExpanded ? setCountExpandedTabs(countExpandedTabs - 1) : setCountExpandedTabs(countExpandedTabs + 1)
                  setIsRoundStatusAutomationExpanded(!isRoundStatusAutomationExpanded)
                }}
              >
                {visibleColumns.autoMoveRoundToInProgress &&
                  <KeySettingsTh settingName={!window.I18n ? '' : window.I18n.t('key_settings.table.auto_move_round_to_in_progress')} categoryName={!window.I18n ? '' : window.I18n.t('key_settings.table.round_status_automation')} />
                }
                {visibleColumns.moveToInProgressAt &&
                  <KeySettingsTh settingName={!window.I18n ? '' : window.I18n.t('key_settings.table.move_to_in_progress_at')} />
                }
                {visibleColumns.autoMoveRoundToCompleted &&
                  <KeySettingsTh settingName={!window.I18n ? '' : window.I18n.t('key_settings.table.auto_move_round_to_completed')} />
                }
                {visibleColumns.moveToCompletedAt &&
                  <KeySettingsTh settingName={!window.I18n ? '' : window.I18n.t('key_settings.table.move_to_completed_at')} />
                }
              </KeySettingsThGroup>

              <KeySettingsThGroup 
                name={!window.I18n ? '' : window.I18n.t('key_settings.table.registration_center')}
                isExpanded={isRegistrationCenterExpanded}
                isHeaderTwoLeveled={countExpandedTabs > 0}
                onToggle={() => {
                  isRegistrationCenterExpanded ? setCountExpandedTabs(countExpandedTabs - 1) : setCountExpandedTabs(countExpandedTabs + 1)
                  setIsRegistrationCenterExpanded(!isRegistrationCenterExpanded)
                }}
              >
                {visibleColumns.minNumberOfMembers && canOpenRegistration &&
                  <KeySettingsTh settingName={!window.I18n ? '' : window.I18n.t('key_settings.table.min_number_of_members')} categoryName={!window.I18n ? '' : window.I18n.t('key_settings.table.registration_center')} />
                }
                {visibleColumns.maxNumberOfMembers && canOpenRegistration &&
                  <KeySettingsTh settingName={!window.I18n ? '' : window.I18n.t('key_settings.table.max_number_of_members')} />
                }
                {visibleColumns.minNumberOfGuests && canOpenRegistration &&
                  <KeySettingsTh settingName={!window.I18n ? '' : window.I18n.t('key_settings.table.min_number_of_guests')} />
                }
                {visibleColumns.maxNumberOfGuests && canOpenRegistration &&
                  <KeySettingsTh settingName={!window.I18n ? '' : window.I18n.t('key_settings.table.max_number_of_guests')} />
                }
                {visibleColumns.registrationOpensInvitation && canInvitationList && canOpenRegistration &&
                  <KeySettingsTh settingName={!window.I18n ? '' : window.I18n.t('key_settings.table.registration_opens_invitation')} />
                }
                {visibleColumns.registrationOpens && canOpenRegistration &&
                  <KeySettingsTh settingName={!window.I18n ? '' : window.I18n.t('key_settings.table.registration_opens')} />
                }
                {visibleColumns.registrationCloses && canOpenRegistration &&
                  <KeySettingsTh settingName={!window.I18n ? '' : window.I18n.t('key_settings.table.registration_closes')} />
                }
                {visibleColumns.memberRegistrationFields &&
                  <KeySettingsTh settingName={!window.I18n ? '' : window.I18n.t('key_settings.table.member_registration_fields')} />
                }
                {visibleColumns.guestRegistrationFields &&
                  <KeySettingsTh settingName={!window.I18n ? '' : window.I18n.t('key_settings.table.guest_registration_fields')} />
                }
              </KeySettingsThGroup>

              {visibleColumns.communicationOfficer &&
                <KeySettingsTh settingName={!window.I18n ? '' : window.I18n.t('key_settings.table.communication_officer')} isHeaderTwoLeveled={countExpandedTabs > 0} />
              }
              {visibleColumns.hiService &&
                <KeySettingsTh settingName={!window.I18n ? '' : window.I18n.t('key_settings.table.hi_service')} isHeaderTwoLeveled={countExpandedTabs > 0} />
              }
              {visibleColumns.autoHiUpdateFromMrIntoEr &&
                <KeySettingsTh settingName={!window.I18n ? '' : window.I18n.t('key_settings.table.auto_hi_update_from_mr_into_er')} isHeaderTwoLeveled={countExpandedTabs > 0} />
              }

              <KeySettingsThGroup 
                name={!window.I18n ? '' : window.I18n.t('key_settings.table.default_course_and_tees')}
                isExpanded={isDefaultCourseAndTeesExpanded}
                isHeaderTwoLeveled={countExpandedTabs > 0}
                onToggle={() => {
                  isDefaultCourseAndTeesExpanded ? setCountExpandedTabs(countExpandedTabs - 1) : setCountExpandedTabs(countExpandedTabs + 1)
                  setIsDefaultCourseAndTeesExpanded(!isDefaultCourseAndTeesExpanded)
                }}
              >
                {visibleColumns.enableDefaultCourseAndTee &&
                  <KeySettingsTh settingName={!window.I18n ? '' : window.I18n.t('key_settings.table.show_default_tee')} isHeaderTwoLeveled={countExpandedTabs > 0} />
                }
                {visibleColumns.defaultCourse &&
                  <KeySettingsTh settingName={!window.I18n ? '' : window.I18n.t('key_settings.table.default_course')} isHeaderTwoLeveled={countExpandedTabs > 0} />
                }
                {visibleColumns.defaultTeeM &&
                  <KeySettingsTh settingName={!window.I18n ? '' : window.I18n.t('key_settings.table.default_tee_m')} isHeaderTwoLeveled={countExpandedTabs > 0} />
                }
                {visibleColumns.defaultTeeF &&
                  <KeySettingsTh settingName={!window.I18n ? '' : window.I18n.t('key_settings.table.default_tee_f')} isHeaderTwoLeveled={countExpandedTabs > 0} />
                }
                {visibleColumns.holeType &&
                  <KeySettingsTh settingName={!window.I18n ? '' : window.I18n.t('key_settings.table.hole_type')} isHeaderTwoLeveled={countExpandedTabs > 0} />
                }
              </KeySettingsThGroup>

              {visibleColumns.coursesEnabled &&
                <KeySettingsTh settingName={!window.I18n ? '' : window.I18n.t('key_settings.table.courses_enabled')} isHeaderTwoLeveled={countExpandedTabs > 0} />
              }
              {visibleColumns.tournaments &&
                <KeySettingsTh settingName={!window.I18n ? '' : window.I18n.t('key_settings.table.tournaments')} isHeaderTwoLeveled={countExpandedTabs > 0} />
              }
            </tr>
          </thead>
          {(!isDataFetched || loading) ? (
            <tbody className='table-refreshing'></tbody>
          ) : (
            <tbody>
              {
              leagueKeys.map((league) => {
                const isExpanded = expandedLeagues.includes(league)
                const roundsData = data[league]['rounds']
                const roundKeys = roundsData ? Object.keys(roundsData) : []
                const leagueLink = `/leagues/${league}`
                const leagueDate = data[league]['first_round_date']
                const leagueIsPast = leaguesAndRoundsSelected[league]['is_past']
                const leagueIsSelected = leaguesAndRoundsSelected[league]['is_selected']
                const defaultCourseId = modifiedData[league] && modifiedData[league].variable_settings_default_course !== undefined 
                                        ? modifiedData[league].variable_settings_default_course
                                        : data[league].variable_settings_default_course
                const isOpenDateAfterCloseDate = leaguesDataValidations[league]?.isOpenDateAfterCloseDate
                const isOpenDateInvitationAfterCloseDate = leaguesDataValidations[league]?.isOpenDateInvitationAfterCloseDate
                const isMinNumberOfMembersValid = leaguesDataValidations[league]?.isMinNumberOfMembersValid
                const isMinNumberOfGuestsValid = leaguesDataValidations[league]?.isMinNumberOfGuestsValid

                return (
                  <React.Fragment key={league}>
                    {visibleColumns.leagueName && (
                      <tr className={isExpanded ? 'expanded-league' : 'collapsed-league'}>
                        <td>
                          <Checkbox
                            selected={leagueIsSelected}
                            setOption={() => handleSelectLeague(league)}
                            className="custom-checkbox"
                            isDisabled={leagueIsPast}
                          />
                        </td>
                        <td>
                          <div>
                            <span style={{ cursor: 'pointer' }} onClick={() => toggleExpandLeague(league)} className="arrow-button">{isExpanded ? '▼' : '►'}</span>
                            <a href={leagueLink} target='_blank'>
                              {data[league].name}
                            </a>
                          </div>
                        </td>
                        {visibleColumns.roundName && (
                          <td></td>
                        )}
                        {visibleColumns.settingLevel && (
                          <td className="event-level">
                            <div className="event-level-background">
                              <span className="event-level-text">{!window.I18n ? '' : window.I18n.t('key_settings.table.event_level')}</span>
                            </div>
                          </td>
                        )}
                        {visibleColumns.date && (
                          <td>
                            <div className="td-inner width-180-px">
                              <DatePicker
                                readOnly={true}
                                customInput={ <CalendarCustomInput isDisabled={true} /> }
                                selected={new Date(leagueDate).getFullYear() === 1970 ? null : new Date(leagueDate)}
                                dateFormat='MM/dd/yyyy'
                                popperClassName="custom-datepicker-popper"
                                popperPlacement="auto"
                              />
                            </div>
                          </td>
                        )}
                        {canAlwaysShowMrCoupling && visibleColumns.masterRosterCoupling && (
                          <td>
                            <div className="td-inner">
                              <Toggle
                                onClick={ () => { handleEditLeague(league, 'master_roster_coupling', !(modifiedData[league] !== undefined && modifiedData[league].master_roster_coupling !== undefined ? modifiedData[league].master_roster_coupling : data[league].master_roster_coupling)) }}
                                on={!window.I18n ? '' : window.I18n.t('key_settings.generic.toggle_on')}
                                off={!window.I18n ? '' : window.I18n.t('key_settings.generic.toggle_off')}
                                className='rounded btn'
                                active={ modifiedData[league] !== undefined && modifiedData[league].master_roster_coupling !== undefined ? 
                                  modifiedData[league].master_roster_coupling : data[league].master_roster_coupling }
                                disabled={leagueIsPast}
                              />

                              {modifiedData[league] !== undefined && modifiedData[league].master_roster_coupling !== undefined &&
                                <GlgTooltip tooltip='Unsaved Change' placement="top"><img src={flagSvg} alt="" /></GlgTooltip>
                              }
                            </div>
                          </td>
                        )}

                        { isRoundMiscellaneousExpanded ? (<>
                            {isUkWhs && visibleColumns.qualifying && (
                                <td></td>
                            )}
                            {isUkWhs && visibleColumns.autoScorePosting && (
                                <td></td>
                            )}
                            {visibleColumns.openTeeTimes && (
                              <td></td>
                            )}
                          </>) : ((isUkWhs && (visibleColumns.qualifying || visibleColumns.autoScorePosting) || visibleColumns.openTeeTimes) && <td></td>
                        )}

                        { isRoundStatusAutomationExpanded ? (
                          <>
                            {visibleColumns.autoMoveRoundToInProgress && <td></td>}
                            {visibleColumns.moveToInProgressAt && <td></td>}
                            {visibleColumns.autoMoveRoundToCompleted && <td></td>}
                            {visibleColumns.moveToCompletedAt && <td></td>}
                          </>
                        ) : ((visibleColumns.autoMoveRoundToInProgress || visibleColumns.moveToInProgressAt || visibleColumns.autoMoveRoundToCompleted || visibleColumns.moveToCompletedAt) && <td></td>
                        )}

                        { isRegistrationCenterExpanded ? (<>
                            {canOpenRegistration && visibleColumns.minNumberOfMembers && (
                                <td>
                                  <div className="td-inner">
                                    <input 
                                      value={modifiedData[league] !== undefined && modifiedData[league].registration_form !== undefined && modifiedData[league].registration_form.min_number_of_members ? (modifiedData[league]['registration_form']['min_number_of_members'] || 0) : (data[league]['registration_form']['min_number_of_members'] || 0) } 
                                      min="0" 
                                      max="12" 
                                      required 
                                      className="form-control-input" 
                                      type="number"
                                      onChange={(event) => handleEditLeagueRegistration(league, 'min_number_of_members', event.target.value)}
                                      disabled={leagueIsPast || data[league]['registration_form']['enable_open_registration'] === false || data[league]['registration_form']['open_registration'] === false}
                                    />

                                    {modifiedData[league] !== undefined && isMinNumberOfMembersValid && modifiedData[league].registration_form !== undefined && modifiedData[league].registration_form.min_number_of_members &&
                                      <GlgTooltip tooltip='Unsaved Change' placement="top"><img src={flagSvg} alt="" /></GlgTooltip>
                                    }

                                    {(!leagueIsPast && data[league]['registration_form']['enable_open_registration'] && data[league]['registration_form']['open_registration'] && !isMinNumberOfMembersValid) && (
                                      <span className="info_circle_warning_reg">
                                        <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                                        <div className="custom-tooltip">
                                          {!window.I18n ? '' : (
                                            !isMinNumberOfMembersValid 
                                              ? window.I18n.t('key_settings.table.min_members_flag')
                                              : window.I18n.t('key_settings.table.either_guests_or_members_flags')
                                          )}
                                        <div className="tooltip-arrow"></div>
                                        </div>
                                      </span>
                                    )}
                                  </div>
                                </td>
                            )}
                            {canOpenRegistration && visibleColumns.maxNumberOfMembers && (
                                <td>
                                  <div className="td-inner">
                                    <input 
                                      value={modifiedData[league] !== undefined && modifiedData[league].registration_form !== undefined && modifiedData[league].registration_form.max_number_of_members ? (modifiedData[league]['registration_form']['max_number_of_members'] || 0) : (data[league]['registration_form']['max_number_of_members'] || 0) } 
                                      min="0" 
                                      max="12" 
                                      required 
                                      className="form-control-input" 
                                      type="number"
                                      onChange={(event) => handleEditLeagueRegistration(league, 'max_number_of_members', event.target.value)}
                                      disabled={leagueIsPast || data[league]['registration_form']['enable_open_registration'] === false || data[league]['registration_form']['open_registration'] === false}
                                    />

                                    {modifiedData[league] !== undefined && isMinNumberOfMembersValid && modifiedData[league].registration_form !== undefined && modifiedData[league].registration_form.max_number_of_members &&
                                      <GlgTooltip tooltip='Unsaved Change' placement="top"><img src={flagSvg} alt="" /></GlgTooltip>
                                    }

                                    {(!leagueIsPast && data[league]['registration_form']['enable_open_registration'] && data[league]['registration_form']['open_registration'] && !isMinNumberOfMembersValid) && (
                                      <span className="info_circle_warning_reg">
                                        <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                                        <div className="custom-tooltip">
                                          {!window.I18n ? '' : window.I18n.t('key_settings.table.max_members_flag')}
                                        <div className="tooltip-arrow"></div>
                                        </div>
                                      </span>
                                    )}
                                  </div>
                                </td>
                            )}
                            {canOpenRegistration && visibleColumns.minNumberOfGuests && (
                                <td>
                                  <div className="td-inner">
                                    <input 
                                      value={modifiedData[league] !== undefined && modifiedData[league].registration_form !== undefined && modifiedData[league].registration_form.min_number_of_guests ? (modifiedData[league]['registration_form']['min_number_of_guests'] || 0) : (data[league]['registration_form']['min_number_of_guests'] || 0)} 
                                      min="0" 
                                      max="12" 
                                      required 
                                      className="form-control-input" 
                                      type="number"
                                      onChange={(event) => handleEditLeagueRegistration(league, 'min_number_of_guests', event.target.value)}
                                      disabled={leagueIsPast || data[league]['registration_form']['enable_open_registration'] === false || data[league]['registration_form']['open_registration'] === false}
                                    />

                                    {modifiedData[league] !== undefined && isMinNumberOfGuestsValid && modifiedData[league].registration_form !== undefined && modifiedData[league].registration_form.min_number_of_guests &&
                                      <GlgTooltip tooltip='Unsaved Change' placement="top"><img src={flagSvg} alt="" /></GlgTooltip>
                                    }

                                    {((!leagueIsPast && data[league]['registration_form']['enable_open_registration'] && data[league]['registration_form']['open_registration']) && !isMinNumberOfGuestsValid) && (
                                      <span className="info_circle_warning_reg">
                                        <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                                        <div className="custom-tooltip">
                                          {!window.I18n ? '' : (
                                            !isMinNumberOfMembersValid 
                                              ? window.I18n.t('key_settings.table.min_guests_flag')
                                              : window.I18n.t('key_settings.table.either_guests_or_members_flags')
                                          )}
                                        <div className="tooltip-arrow"></div>
                                        </div>
                                      </span>
                                    )}
                                  </div>
                                </td>
                            )}
                            {canOpenRegistration && visibleColumns.maxNumberOfGuests && (
                                <td>
                                  <div className="td-inner">
                                    <input 
                                      value={modifiedData[league] !== undefined && modifiedData[league].registration_form !== undefined && modifiedData[league].registration_form.max_number_of_guests ? (modifiedData[league]['registration_form']['max_number_of_guests'] || 0) : (data[league]['registration_form']['max_number_of_guests'] || 0)}
                                      min="0" 
                                      max="12" 
                                      required 
                                      className="form-control-input"
                                      type="number"
                                      onChange={(event) => handleEditLeagueRegistration(league, 'max_number_of_guests', event.target.value)}
                                      disabled={leagueIsPast || data[league]['registration_form']['enable_open_registration'] === false || data[league]['registration_form']['open_registration'] === false}
                                    />

                                    {modifiedData[league] !== undefined && isMinNumberOfGuestsValid && modifiedData[league].registration_form !== undefined && modifiedData[league].registration_form.max_number_of_guests &&
                                      <GlgTooltip tooltip='Unsaved Change' placement="top"><img src={flagSvg} alt="" /></GlgTooltip>
                                    }

                                    {(!leagueIsPast && data[league]['registration_form']['enable_open_registration'] && data[league]['registration_form']['open_registration'] && !isMinNumberOfGuestsValid) && (
                                      <span className="info_circle_warning_reg">
                                        <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                                        <div className="custom-tooltip">
                                          {!window.I18n ? '' : window.I18n.t('key_settings.table.max_guests_flag')}
                                        <div className="tooltip-arrow"></div>
                                        </div>
                                      </span>
                                    )}
                                  </div>
                                </td>
                            )}
                            {canOpenRegistration && canInvitationList && visibleColumns.registrationOpensInvitation && (
                              <td>
                                <div className="td-inner">
                                  <DatePicker
                                    showTimeSelect
                                    timeIntervals={60}
                                    className="form-text form-control form-control-date-picker date_for_age datepicker_past"
                                    readOnly={leagueIsPast || data[league]['registration_form']['enable_open_registration'] === false || data[league]['registration_form']['open_registration'] === false}
                                    selected={modifiedData[league] !== undefined && modifiedData[league].registration_form !== undefined && modifiedData[league].registration_form.registration_starts_on_for_invitations
                                              ? 
                                                moment(modifiedData[league]['registration_form']['registration_starts_on_for_invitations'], 'YYYY-MM-DD HH:mm:ss')
                                                  .toDate()
                                              : 
                                                data[league]['registration_form']['registration_starts_on_for_invitations'] && moment(data[league]['registration_form']['registration_starts_on_for_invitations'], 'YYYY-MM-DD HH:mm:ss')
                                                  .toDate()
                                              }
                                    dateFormat='MM/dd/yyyy h:mm aa'
                                    onChange={(date) => {
                                      handleEditLeagueRegistration(league, 'registration_starts_on_for_invitations', moment(date)
                                      .format('YYYY-MM-DD HH:mm:ss'))
                                    }}
                                    popperClassName="custom-datepicker-popper"
                                    popperPlacement="auto-end, auto, auto-start, bottom, bottom-end, bottom-start, left, left-end, left-start, right, right-end, right-start"
                                  />

                                  {modifiedData[league] !== undefined && !isOpenDateInvitationAfterCloseDate && modifiedData[league].registration_form !== undefined && modifiedData[league].registration_form.registration_starts_on_for_invitations &&
                                    <GlgTooltip tooltip='Unsaved Change' placement="top"><img src={flagSvg} alt="" /></GlgTooltip>
                                  }

                                  {(isOpenDateInvitationAfterCloseDate) && (
                                    <span className="info_circle_warning_reg">
                                      <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                                      <div className="custom-tooltip">
                                        {!window.I18n ? '' : window.I18n.t('key_settings.table.registration_open_date_flag')}
                                      <div className="tooltip-arrow"></div>
                                      </div>
                                    </span>
                                  )}
                                </div>
                              </td>
                            )}
                            {canOpenRegistration && visibleColumns.registrationOpens && (
                                <td>
                                  <div className="td-inner">
                                    <DatePicker
                                      showTimeSelect
                                      timeIntervals={60}
                                      className="form-text form-control form-control-date-picker date_for_age datepicker_past"
                                      readOnly={leagueIsPast || data[league]['registration_form']['enable_open_registration'] === false || data[league]['registration_form']['open_registration'] === false}
                                      selected={modifiedData[league] !== undefined && modifiedData[league].registration_form !== undefined && modifiedData[league].registration_form.registration_starts_on
                                                ? 
                                                  moment(modifiedData[league]['registration_form']['registration_starts_on'], 'YYYY-MM-DD HH:mm:ss')
                                                    .toDate()
                                                : 
                                                  data[league]['registration_form']['registration_starts_on'] && moment(data[league]['registration_form']['registration_starts_on'], 'YYYY-MM-DD HH:mm:ss')
                                                    .toDate()
                                                }
                                      dateFormat='MM/dd/yyyy h:mm aa'
                                      onChange={(date) => {
                                        handleEditLeagueRegistration(league, 'registration_starts_on', moment(date)
                                        .format('YYYY-MM-DD HH:mm:ss'))
                                      }}
                                      popperClassName="custom-datepicker-popper"
                                      popperPlacement="auto-end, auto, auto-start, bottom, bottom-end, bottom-start, left, left-end, left-start, right, right-end, right-start"
                                    />
                                    
                                    {modifiedData[league] !== undefined && !isOpenDateAfterCloseDate && modifiedData[league].registration_form !== undefined && modifiedData[league].registration_form.registration_starts_on &&
                                      <GlgTooltip tooltip='Unsaved Change' placement="top"><img src={flagSvg} alt="" /></GlgTooltip>
                                    }

                                    {isOpenDateAfterCloseDate && (
                                      <span className="info_circle_warning_reg">
                                        <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                                        <div className="custom-tooltip">
                                          {!window.I18n ? '' : window.I18n.t('key_settings.table.registration_open_date_flag')}
                                        <div className="tooltip-arrow"></div>
                                        </div>
                                      </span>
                                    )}
                                  </div>
                                </td>
                            )}
                            {canOpenRegistration && visibleColumns.registrationCloses && (
                                <td>
                                  <div className="td-inner">
                                    <DatePicker
                                      showTimeSelect
                                      timeIntervals={60}
                                      className="form-text form-control form-control-date-picker date_for_age datepicker_past"
                                      readOnly={leagueIsPast || data[league]['registration_form']['enable_open_registration'] === false || data[league]['registration_form']['open_registration'] === false}
                                      selected={modifiedData[league] !== undefined && modifiedData[league].registration_form !== undefined && modifiedData[league].registration_form.registration_ends_on
                                                ? 
                                                  moment(modifiedData[league]['registration_form']['registration_ends_on'], 'YYYY-MM-DD HH:mm:ss')
                                                  .toDate()
                                                : 
                                                  data[league]['registration_form']['registration_ends_on'] && moment(data[league]['registration_form']['registration_ends_on'], 'YYYY-MM-DD HH:mm:ss')
                                                  .toDate()
                                                }
                                      dateFormat='MM/dd/yyyy h:mm aa'
                                      onChange={(date) => {
                                        handleEditLeagueRegistration(league, 'registration_ends_on', moment(date)
                                        .format('YYYY-MM-DD HH:mm:ss'))
                                      }}
                                      popperClassName="custom-datepicker-popper"
                                      popperPlacement="auto-end, auto, auto-start, bottom, bottom-end, bottom-start, left, left-end, left-start, right, right-end, right-start"
                                    />
                                    {modifiedData[league] !== undefined && modifiedData[league].registration_form !== undefined && modifiedData[league].registration_form.registration_ends_on &&
                                      <GlgTooltip tooltip='Unsaved Change' placement="top"><img src={flagSvg} alt="" /></GlgTooltip>
                                    }
                                  </div>
                                </td>
                            )}
                            {visibleColumns.memberRegistrationFields && (
                              data[league]['registration_form']['member_registration_fields'].length > 0
                                ?
                                  <td>
                                    <ol className="tournaments-ol">
                                      {data[league]['registration_form']['member_registration_fields'].map((field, index) => (
                                        <li key={index} className='tournaments'>
                                          <div className='ol-group'>
                                            <a href={`${leagueLink}/edit_registration_form`} target='_blank' className='tournaments-elements'>
                                              {field.name}
                                            </a>
                                            {field.required && <label>{!window.I18n ? '' : window.I18n.t('key_settings.table.required')}</label>}
                                          </div>
                                        </li>
                                      ))}
                                    </ol> 
                                  </td>
                                : 
                                  <td> 
                                    <span className="no-tournaments"> {!window.I18n ? '' : window.I18n.t('key_settings.table.no_member_registration_fields')} </span>
                                  </td>
                            )}
                            {visibleColumns.guestRegistrationFields && (
                              data[league]['registration_form']['guest_registration_fields'].length > 0
                                ?
                                  <td>
                                    <ol className="tournaments-ol">
                                      {data[league]['registration_form']['guest_registration_fields'].map((field, index) => (
                                        <li key={index} className='tournaments'>
                                          <div className='ol-group'>
                                            <a href={`${leagueLink}/edit_registration_form`} target='_blank' className='tournaments-elements'>
                                              {field.name}
                                            </a>
                                            {field.required && <label>{!window.I18n ? '' : window.I18n.t('key_settings.table.required')}</label>}
                                          </div>
                                        </li>
                                      ))}
                                    </ol> 
                                  </td>
                                : 
                                  <td> 
                                    <span className="no-tournaments"> {!window.I18n ? '' : window.I18n.t('key_settings.table.no_guest_registration_fields')} </span>
                                  </td>
                            )}
                          </>) : (
                          canOpenRegistration && (
                            visibleColumns.minNumberOfMembers ||
                            visibleColumns.maxNumberOfMembers ||
                            visibleColumns.minNumberOfGuests ||
                            visibleColumns.maxNumberOfGuests ||
                            visibleColumns.registrationOpensInvitation ||
                            visibleColumns.registrationOpens ||
                            visibleColumns.registrationCloses ||
                            visibleColumns.memberRegistrationFields ||
                            visibleColumns.guestRegistrationFields
                          ) && <td></td>
                        )}    
                        {visibleColumns.communicationOfficer && (
                          <td>
                            <div className="td-inner">
                              <GlgSelect
                                items={[
                                  ...(state['table']['data']['customer_managers']).map(manager => ({
                                    value: manager.user_id.toString(),
                                    label: manager.full_name,
                                  })),
                                  ...(data[league].event_managers).map(manager => ({
                                    value: manager.user_id.toString(),
                                    label: manager.full_name,
                                  })),
                                ]}
                                value={
                                  modifiedData[league]?.communication_officer ? modifiedData[league].communication_officer :
                                  data[league]?.communication_officer ? data[league].communication_officer.user_id.toString() : null
                                }
                                disabled={ leagueIsPast }
                                placeholder='Select...'
                                className="default-glg-select"
                                menuPosition='fixed'
                                noPreFill
                                usePortal
                                menuShouldBlockScroll
                                onChange={(e) => handleEditLeague(league, 'communication_officer', e)}
                              />

                            {modifiedData[league] !== undefined && modifiedData[league].communication_officer !== undefined &&
                              <GlgTooltip tooltip='Unsaved Change' placement="top"><img src={flagSvg} alt="" /></GlgTooltip>
                            }
                            </div>
                          </td>
                        )}
                        {visibleColumns.hiService && (
                          <td>
                            <div className="td-inner">
                              <GlgSelect
                                items={
                                  (state['table']['data']['hi_services']).map(service => ({
                                        value: service[1],
                                        label: service[0],
                                  }))
                                }
                                value={
                                  modifiedData[league]?.handicaps_updated_via ? modifiedData[league].handicaps_updated_via : 
                                  data[league]?.handicaps_updated_via ? data[league].handicaps_updated_via : 
                                  null
                                }
                                disabled={ leagueIsPast }
                                onChange={(e) => handleEditLeague(league, 'handicaps_updated_via', e)}
                                placeholder={!window.I18n ? '' : window.I18n.t('key_settings.generic.select')}
                                className="default-glg-select"
                                menuPosition='fixed'
                                usePortal
                                noPreFill
                                menuShouldBlockScroll
                              />

                            {modifiedData[league] !== undefined && modifiedData[league].handicaps_updated_via !== undefined &&
                              <GlgTooltip tooltip='Unsaved Change' placement="top"><img src={flagSvg} alt="" /></GlgTooltip>
                            }
                            </div>
                          </td>
                        )}
                        {visibleColumns.autoHiUpdateFromMrIntoEr && (
                          <td>
                            <div className="td-inner">
                              <Toggle
                                onClick={ () => { handleEditLeague(league, 'variable_settings_auto_update_hi_from_mr', !(modifiedData[league] !== undefined && modifiedData[league].variable_settings_auto_update_hi_from_mr !== undefined ? modifiedData[league].variable_settings_auto_update_hi_from_mr : data[league].variable_settings_auto_update_hi_from_mr)) }}
                                on={!window.I18n ? '' : window.I18n.t('key_settings.generic.toggle_on')}
                                off={!window.I18n ? '' : window.I18n.t('key_settings.generic.toggle_off')}
                                className='rounded btn'
                                active={ modifiedData[league] !== undefined && modifiedData[league].variable_settings_auto_update_hi_from_mr !== undefined ? 
                                  modifiedData[league].variable_settings_auto_update_hi_from_mr : data[league].variable_settings_auto_update_hi_from_mr }
                                disabled={leagueIsPast || 
                                  (data[league] && data[league].handicaps_updated_via === 'manual' && 
                                  (!modifiedData[league] || modifiedData[league].handicaps_updated_via === undefined))}
                              />

                              {modifiedData[league] !== undefined && modifiedData[league].variable_settings_auto_update_hi_from_mr !== undefined &&
                                <GlgTooltip tooltip='Unsaved Change' placement="top"><img src={flagSvg} alt="" /></GlgTooltip>
                              }
                            </div>
                          </td>
                        )}
                        { isDefaultCourseAndTeesExpanded ? (<>
                        {visibleColumns.enableDefaultCourseAndTee && (
                            <td>
                              <div className="td-inner">
                                <Toggle
                                  onClick={ () => { handleEditLeague(league, 'variable_settings_show_default_tee_by_gender', !(modifiedData[league] !== undefined && modifiedData[league].variable_settings_show_default_tee_by_gender !== undefined ? modifiedData[league].variable_settings_show_default_tee_by_gender : data[league].variable_settings_show_default_tee_by_gender)) }}
                                  on={!window.I18n ? '' : window.I18n.t('key_settings.generic.toggle_on')}
                                  off={!window.I18n ? '' : window.I18n.t('key_settings.generic.toggle_off')}
                                  className='rounded btn'
                                  active={ modifiedData[league] !== undefined && modifiedData[league].variable_settings_show_default_tee_by_gender !== undefined ? 
                                    modifiedData[league].variable_settings_show_default_tee_by_gender : data[league].variable_settings_show_default_tee_by_gender }
                                  disabled={ leagueIsPast }
                                />

                                {modifiedData[league] !== undefined && modifiedData[league].variable_settings_show_default_tee_by_gender !== undefined &&
                                  <GlgTooltip tooltip='Unsaved Change' placement="top"><img src={flagSvg} alt="" /></GlgTooltip>
                                }
                              </div>
                            </td>
                          )}
                          {visibleColumns.defaultCourse && (
                              <td>
                                <div className="td-inner">
                                  <GlgSelect   
                                    items={
                                      data[league]['courses_enabled']
                                      ?
                                        Object.entries(data[league]['courses_enabled'])
                                          .map(([ courseId, course ]) => ({
                                            value: courseId.toString(),
                                            label: course['name'],
                                          }))
                                          .sort((a, b) => a.label.localeCompare(b.label))
                                      : []
                                    }       
                                    value={ defaultCourseId }
                                    onChange={(e) => handleEditLeague(league, 'variable_settings_default_course', e)}
                                    disabled={
                                      leagueIsPast ||
                                      (modifiedData?.[league]?.variable_settings_show_default_tee_by_gender === false ||
                                      (modifiedData?.[league]?.variable_settings_show_default_tee_by_gender === undefined && 
                                      data[league]?.variable_settings_show_default_tee_by_gender === false))
                                    }                                
                                    placeholder={!window.I18n ? '' : window.I18n.t('key_settings.generic.select')}
                                    className="default-glg-select"
                                    menuPosition='fixed'
                                    noPreFill
                                    usePortal
                                    menuShouldBlockScroll
                                  />

                                {modifiedData[league] !== undefined && modifiedData[league].variable_settings_default_course !== undefined &&
                                  <GlgTooltip tooltip='Unsaved Change' placement="top"><img src={flagSvg} alt="" /></GlgTooltip>
                                }
                                </div>
                              </td>
                          )}
                          {visibleColumns.defaultTeeM && (
                              <td>
                                <div className="td-inner">
                                  <GlgSelect
                                    items={
                                      defaultCourseId !== -1 && data[league]['courses_enabled']?.[defaultCourseId]
                                      ? data[league]['courses_enabled'][defaultCourseId]['tees']
                                        .map(tee => ({
                                          value: tee['id'],
                                          label: tee['name'],
                                        }))
                                        .sort((a, b) => a.label.localeCompare(b.label))
                                      : []
                                    }                                                          
                                    value={
                                      modifiedData[league]?.variable_settings_default_tee_m ? modifiedData[league].variable_settings_default_tee_m : 
                                      data[league]?.variable_settings_default_tee_m ? data[league].variable_settings_default_tee_m : 
                                      null
                                    }
                                    onChange={(e) => handleEditLeague(league, 'variable_settings_default_tee_m', e)}
                                    disabled={
                                      leagueIsPast ||
                                      (modifiedData?.[league]?.variable_settings_show_default_tee_by_gender === false ||
                                      (modifiedData?.[league]?.variable_settings_show_default_tee_by_gender === undefined && 
                                      data[league]?.variable_settings_show_default_tee_by_gender === false))
                                    }
                                    placeholder={!window.I18n ? '' : window.I18n.t('key_settings.generic.select')}
                                    className="default-glg-select"
                                    menuPosition='fixed'
                                    noPreFill
                                    usePortal
                                    menuShouldBlockScroll
                                  />

                                {modifiedData[league] !== undefined && modifiedData[league].variable_settings_default_tee_m !== undefined &&
                                  <GlgTooltip tooltip='Unsaved Change' placement="top"><img src={flagSvg} alt="" /></GlgTooltip>
                                }
                                </div>
                              </td>
                          )}
                          {visibleColumns.defaultTeeF && (
                              <td>
                                <div className="td-inner">
                                  <GlgSelect
                                    items={
                                      defaultCourseId !== -1 && data[league]['courses_enabled']?.[defaultCourseId]
                                      ? data[league]['courses_enabled'][defaultCourseId]['tees']
                                        .map(tee => ({
                                          value: tee['id'],
                                          label: tee['name'],
                                        }))
                                        .sort((a, b) => a.label.localeCompare(b.label))
                                      : []
                                    }                                      
                                    value={
                                      modifiedData[league]?.variable_settings_default_tee_f ? modifiedData[league].variable_settings_default_tee_f : 
                                      data[league]?.variable_settings_default_tee_f ? data[league].variable_settings_default_tee_f : 
                                      null
                                    }
                                    onChange={(e) => handleEditLeague(league, 'variable_settings_default_tee_f', e)}
                                    disabled={
                                      leagueIsPast ||
                                      (modifiedData?.[league]?.variable_settings_show_default_tee_by_gender === false ||
                                      (modifiedData?.[league]?.variable_settings_show_default_tee_by_gender === undefined && 
                                      data[league]?.variable_settings_show_default_tee_by_gender === false))
                                    }      
                                    placeholder={!window.I18n ? '' : window.I18n.t('key_settings.generic.select')}
                                    className="default-glg-select"
                                    menuPosition='fixed'
                                    noPreFill
                                    usePortal
                                    menuShouldBlockScroll
                                  />

                                {modifiedData[league] !== undefined && modifiedData[league].variable_settings_default_tee_f !== undefined &&
                                  <GlgTooltip tooltip='Unsaved Change' placement="top"><img src={flagSvg} alt="" /></GlgTooltip>
                                }
                                </div>
                              </td>
                          )}
                          {visibleColumns.holeType && (
                              <td className="hole-type-td">
                                <div className="td-inner">
                                  <GlgSelect
                                    items={
                                      [
                                        { value: '1', label: !window.I18n ? '' : window.I18n.t('key_settings.generic.all18')},
                                        { value: '3', label: !window.I18n ? '' : window.I18n.t('key_settings.generic.back9') },
                                        { value: '2', label: !window.I18n ? '' : window.I18n.t('key_settings.generic.front9') },
                                      ]
                                    }
                                    value={modifiedData[league]?.variable_settings_hole_type ? modifiedData[league].variable_settings_hole_type : 
                                      data[league]?.variable_settings_hole_type ? data[league].variable_settings_hole_type : 
                                      null}
                                    onChange={(e) => handleEditLeague(league, 'variable_settings_hole_type', e)}
                                    disabled={
                                      leagueIsPast ||
                                      (modifiedData?.[league]?.variable_settings_show_default_tee_by_gender === false ||
                                      (modifiedData?.[league]?.variable_settings_show_default_tee_by_gender === undefined && 
                                      data[league]?.variable_settings_show_default_tee_by_gender === false))
                                    }    
                                    placeholder={!window.I18n ? '' : window.I18n.t('key_settings.generic.select')}
                                    className="small-glg-select"
                                    menuPosition='fixed'
                                    noPreFill
                                    usePortal
                                    menuShouldBlockScroll
                                  />

                                {modifiedData[league] !== undefined && modifiedData[league].variable_settings_hole_type !== undefined &&
                                  <GlgTooltip tooltip='Unsaved Change' placement="top"><img src={flagSvg} alt="" /></GlgTooltip>
                                }
                                </div>
                              </td>
                          )}
                        </>) : (
                          (visibleColumns.enableDefaultCourseAndTee || visibleColumns.defaultCourse || visibleColumns.defaultTeeM || visibleColumns.defaultTeeF || visibleColumns.holeType) && <td></td>
                        )}
                        {visibleColumns.coursesEnabled && (
                          <td>
                            <div className="td-inner">
                              <GlgMultiSelect 
                                items={
                                  state['table']['data']['customer_courses'].map(course => ({
                                    value: course[0],
                                    label: course[1],    
                                    isChecked: Object.keys(
                                      modifiedData[league]?.courses_enabled 
                                        ? modifiedData[league].courses_enabled
                                        : data[league]?.courses_enabled || []
                                    ).includes(course[0]),
                                  }))   
                                }
                                onCheckboxToggle={ (courseKey, isChecked) => handleEditLeagueCoursesEnabled(league, courseKey, isChecked) }                              
                                placeholder='No Courses Enabled'   
                              />

                            {modifiedData[league] !== undefined && modifiedData[league].variable_settings_default_course !== undefined &&
                              <GlgTooltip tooltip='Unsaved Change' placement="top"><img src={flagSvg} alt="" /></GlgTooltip>
                            }
                            </div>
                          </td>
                        )}
                        {visibleColumns.tournaments && (
                            <td></td>
                        )}
                      </tr>
                    )}
                    {isExpanded && roundKeys.map((round, roundIndex) => {
                      const roundIdentifier = `${league}-${round}`
                      const roundLink = `/leagues/${league}/rounds/${round}/edit`
                      const tournamentLink = `${leagueLink}/rounds/${round}/tournaments2/specs`
                      const roundIsPast = leaguesAndRoundsSelected[league]['are_rounds_selected'][round]['is_past']
                      const roundIsSelected = leaguesAndRoundsSelected[league]['are_rounds_selected'][round]['is_selected']
                      const defaultRoundCourseId = getRoundNestedValue(league, round, 'variable_settings_default_course', modifiedData, data)

                      return (
                        <tr key={roundIdentifier} className="collapsed-league">
                          <td>
                            <Checkbox
                              selected={roundIsSelected}
                              setOption={() => handleSelectRound(league, round)}
                              className="custom-checkbox"
                              isDisabled={roundIsPast}
                            />
                          </td>
                          {visibleColumns.leagueName && (
                            <td>
                              {roundIndex === 0 ? '' : null}
                            </td>
                          )}
                          {visibleColumns.roundName && (
                            <td>
                              <a href={roundLink} target='_blank'>
                                {data[league]['rounds'][round].name}
                              </a>
                            </td>
                          )}
                          {visibleColumns.settingLevel && (
                            <td className="event-level">
                              <div className="round-level-background">
                                <span className="event-level-text">{!window.I18n ? '' : window.I18n.t('key_settings.table.round_level')}</span>
                              </div>
                            </td>
                          )}
                          {visibleColumns.date && (
                            <td>
                              <div className="td-inner width-180-px">
                                <DatePicker
                                  readOnly={roundIsPast}
                                  customInput={<CalendarCustomInput isDisabled={roundIsPast} />}
                                  selected={
                                    modifiedData[league] !== undefined && modifiedData[league]['rounds'] !== undefined && modifiedData[league]['rounds'][round] !== undefined && modifiedData[league]['rounds'][round].play_at !== undefined
                                    ? 
                                      moment(modifiedData[league]['rounds'][round].play_at, 'YYYY-MM-DD').toDate()
                                    : 
                                      data[league]['rounds'][round].play_at && moment(data[league]['rounds'][round].play_at, 'YYYY-MM-DD').toDate()
                                  }
                                  dateFormat='MM/dd/yyyy'
                                  onChange={(date) => {
                                    handleEditRound(league, round, 'play_at', moment(date).format('YYYY-MM-DD'))
                                  }}
                                  popperClassName="custom-datepicker-popper"
                                  popperPlacement="auto"
                                />
                                  {isRoundModifiedDataFilled(league, round, 'play_at', modifiedData) &&
                                    <GlgTooltip tooltip='Unsaved Change' placement="top"><img src={flagSvg} alt="" /></GlgTooltip>
                                  }
                              </div>
                            </td>
                          )}
                          {canAlwaysShowMrCoupling && visibleColumns.masterRosterCoupling && (
                            <td></td>
                          )}

                          {isRoundMiscellaneousExpanded ? (<>
                              {isUkWhs && visibleColumns.qualifying && (
                                <td>
                                  <div className='td-inner'>
                                    <Toggle 
                                      on={!window.I18n ? '' : window.I18n.t('key_settings.generic.toggle_on')}
                                      off={!window.I18n ? '' : window.I18n.t('key_settings.generic.toggle_off')}
                                      className='rounded btn'
                                      onClick={ () => { 
                                        handleEditRound(league, round, 'variable_settings_whsuk_is_qualifying', 
                                          !getRoundNestedValue(league, round, 'variable_settings_whsuk_is_qualifying', modifiedData, data))
                                      }}
                                      active={ getRoundNestedValue(league, round, 'variable_settings_whsuk_is_qualifying', modifiedData, data) }
                                      disabled={roundIsPast}
                                    />
                                    {isRoundModifiedDataFilled(league, round, 'variable_settings_whsuk_is_qualifying', modifiedData) &&
                                      <GlgTooltip tooltip='Unsaved Change' placement="top"><img src={flagSvg} alt="" /></GlgTooltip>
                                    }
                                    </div>
                                  </td>
                              )}
                              {isUkWhs && visibleColumns.autoScorePosting && (
                                <td>
                                  <div className="td-inner">
                                    <Toggle
                                      on={!window.I18n ? '' : window.I18n.t('key_settings.generic.toggle_on')}
                                      off={!window.I18n ? '' : window.I18n.t('key_settings.generic.toggle_off')}
                                      className='rounded btn'
                                      onClick={ () => { 
                                        handleEditRound(league, round, 'variable_settings_automatically_post_scores', 
                                          !getRoundNestedValue(league, round, 'variable_settings_automatically_post_scores', modifiedData, data)) 
                                      }}
                                      active={ getRoundNestedValue(league, round, 'variable_settings_automatically_post_scores', modifiedData, data) }
                                      disabled={roundIsPast}
                                    />
                                    {isRoundModifiedDataFilled(league, round, 'variable_settings_automatically_post_scores', modifiedData) &&
                                      <GlgTooltip tooltip='Unsaved Change' placement="top"><img src={flagSvg} alt="" /></GlgTooltip>
                                    }
                                  </div>
                                </td>
                              )}
                              {visibleColumns.openTeeTimes && (
                                <td>
                                  <div className="td-inner">
                                    <Toggle
                                      on={!window.I18n ? '' : window.I18n.t('key_settings.generic.toggle_on')}
                                      off={!window.I18n ? '' : window.I18n.t('key_settings.generic.toggle_off')}
                                      className='rounded btn'
                                      active={ data[league]['rounds'][round].allow_players_to_choose_tee_time }
                                      disabled={ true }
                                    />
                                  </div>
                                </td>
                              )}
                            </>) : ((isUkWhs && (visibleColumns.qualifying || visibleColumns.autoScorePosting) || visibleColumns.openTeeTimes) && <td></td>
                          )}

                          {isRoundStatusAutomationExpanded ? (<>
                              {visibleColumns.autoMoveRoundToInProgress && (
                                <td>
                                  <div className="td-inner">
                                    <Toggle
                                      on={!window.I18n ? '' : window.I18n.t('key_settings.generic.toggle_on')}
                                      off={!window.I18n ? '' : window.I18n.t('key_settings.generic.toggle_off')}
                                      className='rounded btn'
                                      onClick={ () => { 
                                        handleEditRound(league, round, 'variable_settings_auto_move_in_progress', 
                                          !getRoundNestedValue(league, round, 'variable_settings_auto_move_in_progress', modifiedData, data)) 
                                      }}
                                      active={ getRoundNestedValue(league, round, 'variable_settings_auto_move_in_progress', modifiedData, data) }
                                      disabled={roundIsPast}
                                    />
                                    {isRoundModifiedDataFilled(league, round, 'variable_settings_auto_move_in_progress', modifiedData) &&
                                      <GlgTooltip tooltip='Unsaved Change' placement="top"><img src={flagSvg} alt="" /></GlgTooltip>
                                    }
                                  </div>
                                </td>
                              )}
                              {visibleColumns.moveToInProgressAt && (
                                <td>
                                  <div className="td-inner">
                                    <GlgSelect
                                      items={ generateTimeItems() }
                                      value={ getRoundNestedValue(league, round, 'variable_settings_auto_move_in_progress_hour', modifiedData, data) }
                                      onChange={(e) => handleEditRound(league, round, 'variable_settings_auto_move_in_progress_hour', e)}
                                      disabled={
                                        roundIsPast ||
                                        (modifiedData?.[league]?.['rounds']?.[round]?.variable_settings_auto_move_in_progress === false ||
                                        (modifiedData?.[league]?.['rounds']?.[round]?.variable_settings_auto_move_in_progress === undefined && 
                                        !data[league]['rounds'][round].variable_settings_auto_move_in_progress))
                                      }                                        
                                      placeholder={!window.I18n ? '' : window.I18n.t('key_settings.generic.select')}
                                      className="default-glg-select"
                                      menuPosition='fixed'
                                      noPreFill
                                      usePortal
                                      menuShouldBlockScroll
                                    />
                                  {isRoundModifiedDataFilled(league, round, 'variable_settings_auto_move_in_progress_hour', modifiedData) &&
                                    <GlgTooltip tooltip='Unsaved Change' placement="top"><img src={flagSvg} alt="" /></GlgTooltip>
                                  }
                                  </div>
                                </td>
                              )}
                              {visibleColumns.autoMoveRoundToCompleted && (
                                <td>
                                  <div className="td-inner">
                                    <Toggle
                                      on={!window.I18n ? '' : window.I18n.t('key_settings.generic.toggle_on')}
                                      off={!window.I18n ? '' : window.I18n.t('key_settings.generic.toggle_off')}
                                      className='rounded btn'
                                      onClick={ () => { 
                                        handleEditRound(league, round, 'variable_settings_auto_move_to_completed', 
                                          !getRoundNestedValue(league, round, 'variable_settings_auto_move_to_completed', modifiedData, data)) 
                                      }}
                                      active={ getRoundNestedValue(league, round, 'variable_settings_auto_move_to_completed', modifiedData, data) }
                                      disabled={roundIsPast}
                                    />
                                    {isRoundModifiedDataFilled(league, round, 'variable_settings_auto_move_to_completed', modifiedData) &&
                                      <GlgTooltip tooltip='Unsaved Change' placement="top"><img src={flagSvg} alt="" /></GlgTooltip>
                                    }
                                  </div>
                                </td>
                              )}
                              {visibleColumns.moveToCompletedAt && (
                                <td>
                                  <div className="td-inner">
                                    <GlgSelect
                                      items={ generateTimeItems() }
                                      value={ getRoundNestedValue(league, round, 'variable_settings_auto_move_to_completed_hour', modifiedData, data) }
                                      onChange={(e) => handleEditRound(league, round, 'variable_settings_auto_move_to_completed_hour', e)}
                                      disabled={
                                        roundIsPast ||
                                        (modifiedData?.[league]?.['rounds']?.[round]?.variable_settings_auto_move_to_completed === false ||
                                        (modifiedData?.[league]?.['rounds']?.[round]?.variable_settings_auto_move_to_completed === undefined && 
                                        !data[league]['rounds'][round].variable_settings_auto_move_to_completed))
                                      }                                        
                                      placeholder={!window.I18n ? '' : window.I18n.t('key_settings.generic.select')}
                                      className="default-glg-select"
                                      menuPosition='fixed'
                                      noPreFill
                                      usePortal
                                      menuShouldBlockScroll
                                    />
                                  {isRoundModifiedDataFilled(league, round, 'variable_settings_auto_move_to_completed_hour', modifiedData) &&
                                    <GlgTooltip tooltip='Unsaved Change' placement="top"><img src={flagSvg} alt="" /></GlgTooltip>
                                  }
                                  </div>
                                </td>
                              )}
                            </>) : ((visibleColumns.autoMoveRoundToInProgress || visibleColumns.moveToInProgressAt || visibleColumns.autoMoveRoundToCompleted || visibleColumns.moveToCompletedAt) && <td></td>
                          )}

                          {isRegistrationCenterExpanded ? (<>
                              {canOpenRegistration && visibleColumns.minNumberOfMembers && (
                                <td></td>
                              )}
                              {canOpenRegistration && visibleColumns.maxNumberOfMembers && (
                                <td></td>
                              )}
                              {canOpenRegistration && visibleColumns.minNumberOfGuests && (
                                <td></td>
                              )}
                              {canOpenRegistration && visibleColumns.maxNumberOfGuests && (
                                <td></td>
                              )}
                              {canOpenRegistration && visibleColumns.registrationOpensInvitation && (
                                <td></td>
                              )}
                              {canOpenRegistration && visibleColumns.registrationOpens && (
                                <td></td>
                              )}
                              {canOpenRegistration && visibleColumns.registrationCloses && (
                                <td></td>
                              )}
                              {canOpenRegistration && visibleColumns.memberRegistrationFields && (
                                <td></td>
                              )}
                              {canOpenRegistration && visibleColumns.guestRegistrationFields && (
                                <td></td>
                              )}
                            </>) : (
                            canOpenRegistration && (
                              visibleColumns.minNumberOfMembers ||
                              visibleColumns.maxNumberOfMembers ||
                              visibleColumns.minNumberOfGuests ||
                              visibleColumns.maxNumberOfGuests ||
                              visibleColumns.registrationOpensInvitation ||
                              visibleColumns.registrationOpens ||
                              visibleColumns.registrationCloses ||
                              visibleColumns.memberRegistrationFields ||
                              visibleColumns.guestRegistrationFields
                            ) && <td></td>
                          )}
                          {visibleColumns.communicationOfficer && (
                            <td></td>
                          )}
                          {visibleColumns.hiService && (
                            <td></td>
                          )}
                          {visibleColumns.autoHiUpdateFromMrIntoEr && (
                            <td></td>
                          )}
                          {isDefaultCourseAndTeesExpanded ? (<>
                            {visibleColumns.enableDefaultCourseAndTee && (
                              <td>
                                <div className="td-inner">
                                  <Toggle
                                    onClick={ () => { handleEditRound(league, round, 'variable_settings_show_default_tee_by_gender', !(modifiedData?.[league]?.['rounds']?.[round]?.variable_settings_show_default_tee_by_gender !== undefined ? modifiedData[league]['rounds'][round].variable_settings_show_default_tee_by_gender : data[league]['rounds'][round].variable_settings_show_default_tee_by_gender)) }}
                                    on={!window.I18n ? '' : window.I18n.t('key_settings.generic.toggle_on')}
                                    off={!window.I18n ? '' : window.I18n.t('key_settings.generic.toggle_off')}
                                    className='rounded btn'
                                    active={ getRoundNestedValue(league, round, 'variable_settings_show_default_tee_by_gender', modifiedData, data) }
                                    disabled={ roundIsPast }
                                  />

                                  {isRoundModifiedDataFilled(league, round, 'variable_settings_show_default_tee_by_gender', modifiedData) &&
                                    <GlgTooltip tooltip='Unsaved Change' placement="top"><img src={flagSvg} alt="" /></GlgTooltip>
                                  }
                                </div>
                              </td>
                            )}
                            {visibleColumns.defaultCourse && (
                                <td>
                                  <div className="td-inner">
                                    <GlgSelect
                                      items={
                                        data[league]['courses_enabled']
                                        ?
                                          Object.entries(data[league]['courses_enabled'])
                                            .map(([ courseId, course ]) => ({
                                              value: courseId.toString(),
                                              label: course['name'],
                                            }))
                                            .sort((a, b) => a.label.localeCompare(b.label))
                                        : []
                                      }                                                                       
                                      value={ defaultRoundCourseId }
                                      onChange={(e) => handleEditRound(league, round, 'variable_settings_default_course', e)}
                                      disabled={
                                        roundIsPast ||
                                        (modifiedData?.[league]?.['rounds']?.[round]?.variable_settings_show_default_tee_by_gender === false ||
                                        (modifiedData?.[league]?.['rounds']?.[round]?.variable_settings_show_default_tee_by_gender === undefined && 
                                        data[league]['rounds'][round].variable_settings_show_default_tee_by_gender === false))
                                      }                                        
                                      placeholder={!window.I18n ? '' : window.I18n.t('key_settings.generic.select')}
                                      className="default-glg-select"
                                      menuPosition='fixed'
                                      noPreFill
                                      usePortal
                                      menuShouldBlockScroll
                                    />
                                  {isRoundModifiedDataFilled(league, round, 'variable_settings_default_course', modifiedData) &&
                                    <GlgTooltip tooltip='Unsaved Change' placement="top"><img src={flagSvg} alt="" /></GlgTooltip>
                                  }
                                  </div>
                                </td>
                            )}
                            {visibleColumns.defaultTeeM && (
                                <td>
                                  <div className="td-inner">
                                    <GlgSelect
                                      items={
                                        defaultRoundCourseId !== -1 && data[league]['courses_enabled']?.[defaultRoundCourseId]
                                        ? data[league]['courses_enabled'][defaultRoundCourseId]['tees']
                                          .map(tee => ({
                                            value: tee['id'],
                                            label: tee['name'],
                                          }))
                                          .sort((a, b) => a.label.localeCompare(b.label))
                                        : []
                                      }             
                                      value={ getRoundNestedValue(league, round, 'variable_settings_default_tee_m', modifiedData, data) }
                                      onChange={(e) => handleEditRound(league, round, 'variable_settings_default_tee_m', e)}
                                      disabled={
                                        roundIsPast ||
                                        (modifiedData?.[league]?.['rounds']?.[round]?.variable_settings_show_default_tee_by_gender === false ||
                                        (modifiedData?.[league]?.['rounds']?.[round]?.variable_settings_show_default_tee_by_gender === undefined && 
                                        data[league]['rounds'][round].variable_settings_show_default_tee_by_gender === false))
                                      }                                        
                                      placeholder={!window.I18n ? '' : window.I18n.t('key_settings.generic.select')}
                                      className="default-glg-select"
                                      menuPosition='fixed'
                                      noPreFill
                                      usePortal
                                      menuShouldBlockScroll
                                    />
                                  {isRoundModifiedDataFilled(league, round, 'variable_settings_default_tee_m', modifiedData) &&
                                    <GlgTooltip tooltip='Unsaved Change' placement="top"><img src={flagSvg} alt="" /></GlgTooltip>
                                  }
                                  </div>
                                </td>
                            )}
                            {visibleColumns.defaultTeeF && (
                                <td>
                                  <div className="td-inner">
                                    <GlgSelect
                                      items={
                                        defaultRoundCourseId !== -1 && data[league]['courses_enabled']?.[defaultRoundCourseId]
                                        ? data[league]['courses_enabled'][defaultRoundCourseId]['tees']
                                          .map(tee => ({
                                            value: tee['id'],
                                            label: tee['name'],
                                          }))
                                          .sort((a, b) => a.label.localeCompare(b.label))
                                        : []
                                      }
                                      value={ getRoundNestedValue(league, round, 'variable_settings_default_tee_f', modifiedData, data) }
                                      onChange={(e) => handleEditRound(league, round, 'variable_settings_default_tee_f', e)}
                                      disabled={
                                        roundIsPast ||
                                        (modifiedData?.[league]?.['rounds']?.[round]?.variable_settings_show_default_tee_by_gender === false ||
                                        (modifiedData?.[league]?.['rounds']?.[round]?.variable_settings_show_default_tee_by_gender === undefined && 
                                        data[league]['rounds'][round].variable_settings_show_default_tee_by_gender === false))
                                      }                                        
                                      placeholder={!window.I18n ? '' : window.I18n.t('key_settings.generic.select')}
                                      className="default-glg-select"
                                      menuPosition='fixed'
                                      noPreFill
                                      usePortal
                                      menuShouldBlockScroll
                                    />
                                  {isRoundModifiedDataFilled(league, round, 'variable_settings_default_tee_f', modifiedData) &&
                                    <GlgTooltip tooltip='Unsaved Change' placement="top"><img src={flagSvg} alt="" /></GlgTooltip>
                                  }
                                  </div>
                                </td>
                            )}
                            {visibleColumns.holeType && (
                                <td className="hole-type-td">
                                  <div className="td-inner">
                                    <GlgSelect
                                      items={
                                        [
                                          { value: '1', label: !window.I18n ? '' : window.I18n.t('key_settings.generic.all18')},
                                          { value: '3', label: !window.I18n ? '' : window.I18n.t('key_settings.generic.back9') },
                                          { value: '2', label: !window.I18n ? '' : window.I18n.t('key_settings.generic.front9') },
                                        ]
                                      }
                                      value={ getRoundNestedValue(league, round, 'variable_settings_hole_type', modifiedData, data) }
                                      onChange={(e) => handleEditRound(league, round, 'variable_settings_hole_type', e)}
                                      disabled={
                                        roundIsPast ||
                                        (modifiedData?.[league]?.['rounds']?.[round]?.variable_settings_show_default_tee_by_gender === false ||
                                        (modifiedData?.[league]?.['rounds']?.[round]?.variable_settings_show_default_tee_by_gender === undefined && 
                                        data[league]['rounds'][round].variable_settings_show_default_tee_by_gender === false))
                                      }                                        
                                      placeholder={!window.I18n ? '' : window.I18n.t('key_settings.generic.select')}
                                      className="small-glg-select"
                                      menuPosition='fixed'
                                      noPreFill
                                      usePortal
                                      menuShouldBlockScroll
                                    />
                                  {isRoundModifiedDataFilled(league, round, 'variable_settings_hole_type', modifiedData) &&
                                    <GlgTooltip tooltip='Unsaved Change' placement="top"><img src={flagSvg} alt="" /></GlgTooltip>
                                  }
                                  </div>
                                </td>
                            )}
                            </>) : (
                              (visibleColumns.enableDefaultCourseAndTee || visibleColumns.defaultCourse || visibleColumns.defaultTeeM || visibleColumns.defaultTeeF || visibleColumns.holeType) && <td></td>
                            )}
                            {visibleColumns.coursesEnabled && (
                              <td></td>
                            )}
                            {visibleColumns.tournaments && (
                              data[league]['rounds'][round]['tournaments'].length > 0 
                                ?
                                  <td className="tournaments-td">
                                    <ol className="tournaments-ol">
                                      {data[league]['rounds'][round]['tournaments'].map((tournament, index) => (
                                        <li key={index} className='tournaments'>
                                          <div>
                                            <a href={`${tournamentLink}/${tournament.id}/edit`} target='_blank' className='tournaments-elements'>
                                              {tournament.name}
                                            </a>
                                            <span className='tournaments-elements'>{tournament.format}</span>
                                            <span className='tournaments-elements'>|</span>
                                            <span className='tournaments-elements'>{tournament.competition}</span>
                                            <span className='tournaments-elements'>|</span>
                                            <span className='tournaments-elements'>{tournament.balls}</span>
                                          </div>
                                        </li>
                                      ))}
                                    </ol> 
                                  </td>
                                : 
                                  <td className="tournaments-td"> 
                                    <span className="no-tournaments"> {!window.I18n ? '' : window.I18n.t('key_settings.table.no_tournaments')} </span>
                                  </td>
                            )}
                        </tr>
                      )
                    })}
                  </React.Fragment>
                )                                  
              })}
            </tbody>
          )}
        </table>
      {(!isDataFetched || loading) && (
        <div className="spinner-container">
          <GlgLoadingSpinner />
          <div className="spinner-text">{!window.I18n ? '' : window.I18n.t('key_settings.table.refreshing_table')}</div>
        </div>
      )}
      </Scrollbar>
    )
  }
  
  const toggleExpandLeague = (leagueKey) => {
    setExpandedLeagues((prevExpanded) =>
      prevExpanded.includes(leagueKey)
        ? prevExpanded.filter((key) => key !== leagueKey)
        : [ ...prevExpanded, leagueKey ]
    )
  }

  const handleEditLeague = (leagueKey, field, value) => {
    dispatch(editLeague(leagueKey, field, value))
  }

  const handleEditLeagueRegistration = (leagueKey, field, value) => {
    console.log(value)
    dispatch(editLeagueRegistration(leagueKey, field, value))
  }

  const handleEditLeagueCoursesEnabled = (leagueKey, courseKey, isEnabled) => {
    dispatch(editLeagueCoursesEnabled(leagueKey, courseKey, isEnabled))
  }

  const handleSelectLeague = (leagueId) => {
    dispatch(toggleLeagueCheckbox(leagueId))
  }

  const handleSelectRound = (leagueId, roundId) => {
    dispatch(toggleRoundCheckbox(leagueId, roundId))
  }

  const handleEditRound = (leagueKey, roundKey, field, value) => {
    dispatch(editRound(leagueKey, roundKey, field, value))
  }

  const handleSubmit = () => {    
    dispatch(updateSettings())
    .then(() => {
      refreshTable(true, currentPage)
    })
    .catch(error => {
      console.error('Error occurred during update:', error)
    })
  }

  const handleOnCancel = () => {
    window.location.reload()
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
    setLoading(true)
  }

  const handleSearchQuery = (event) => {
    const value = event.target.value

    setSearchQuery(value)
  }

  const handleClearSearch = () => {
    setSearchQuery('')
  }

  const toggleColumnVisibility = (columnName) => {
    const updatedVisibleColumns = { ...visibleColumns, [columnName]: !visibleColumns[columnName] }
    setVisibleColumns(updatedVisibleColumns)
    saveVisibleColumnsToLocalStorage(updatedVisibleColumns)
  }

  const generateFilterString = (selectedOptions) => {
    return selectedOptions.map((option) => option.value).join(';')
  }

  const handleClickColumnsFilter = (e) => {
    e.preventDefault()
    setShowColumnsFilter(!showColumnsFilter)
  }

  const handleClickOutside = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setShowColumnsFilter(false)
    }
  }

  const getRoundNestedValue = (league, round, key, modifiedData, data) => {
    if (modifiedData && modifiedData[league] && modifiedData[league]['rounds'] && modifiedData[league]['rounds'][round] && modifiedData[league]['rounds'][round][key] !== undefined) {
      return modifiedData[league]['rounds'][round][key]
    } else if (data && data[league] && data[league]['rounds'] && data[league]['rounds'][round] && data[league]['rounds'][round][key] !== undefined) {
      return data[league]['rounds'][round][key]
    }
    return undefined
  }

  const isRoundModifiedDataFilled = (league, round, key, modifiedData) => (
    modifiedData !== undefined &&
    modifiedData[league] !== undefined &&
    modifiedData[league]['rounds'] !== undefined &&
    modifiedData[league]['rounds'][round] !== undefined &&
    modifiedData[league]['rounds'][round][key] !== undefined
  )

  return (
    <div>
      <KeySettingsFilteringTab
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories} 
        setAdvancedQueryData={setAdvancedQueryData}
        showPastRecords={showPastRecords}
        setShowPastRecords={setShowPastRecords}
        selectedSeasons={selectedSeasons}
        setSelectedSeasons={setSelectedSeasons}
      />

      <KeySettingsBulkEdit 
        bulkEditData={bulkEditData}
        setBulkEditData={setBulkEditData}
        refreshTable={refreshTable}
        currentPage={currentPage}
        expandedLeagues={expandedLeagues}
        setExpandedLeagues={setExpandedLeagues}
      />

      <div className="table-top-toolbar">
        {renderLeaguesRoundsInfo()}
        {renderColumnsFilter()}
        {renderSearchBar()}
        <div className='pagination-component-wrapper'>
          <PaginationComponent
            page={currentPage}
            elementsCount={totalLeagues}
            setCurrentPage={handlePageChange}
            elementsOnPage={elementsOnPage}
          />
        </div>
      </div>
      {renderKeyTable()}
      {modifiedDataCount > 0 && 
        <SaveBar onCancel={handleOnCancel} onSave={handleSubmit} disableHelper={disableSaveButton}/>
      }
    </div>
  )
}

export default Layout
