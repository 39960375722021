import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'

class RegStatusComponent extends PureComponent {

  statusDisplay(status) {
    if (status === (!window.I18n ? '' : window.I18n.t('customer_directories.reg_status.membership_program'))) {
      status = !window.I18n ? '' : window.I18n.t('customer_directories.reg_status.open')
    }

    return status.toUpperCase()
  }

  statusTranslation(status) {
    return !window.I18n ? '' : window.I18n.t(`customer_directories.reg_status.${status.toLowerCase().replace(/ /g, '_')}`)
  }

  opensDate() {
    const today = moment().tz(this.props.timezone)
    const minOpeningDate = _.min(this.props.openingDates.map(date => moment(date).tz(this.props.timezone)))
    const opensDate = _.find(_.compact([ this.props.invStartsAt, minOpeningDate, this.props.startsAt ]), date => {
      return moment(date).locale(window.I18n.locale)
                  .tz(this.props.timezone)
                  .isAfter(today)
    })
    return moment(opensDate).locale(window.I18n.locale)
                            .tz(this.props.timezone)
                            .format('ddd, MMM  DD[\n]h:mm A zz')
                            .toUpperCase()
  }
  
  render() {
    let regStatusClass
    if (_.includes([ !window.I18n ? '' : window.I18n.t('customer_directories.reg_status.open'), !window.I18n ? '' : window.I18n.t('customer_directories.reg_status.invitation_list'), !window.I18n ? '' : window.I18n.t('customer_directories.reg_status.membership_program') ], this.statusTranslation(this.props.status))){
      regStatusClass = this.props.registered ? 'reg-status-registered' : 'reg-status-open'
    } else if (this.statusTranslation(this.props.status) === (!window.I18n ? '' : window.I18n.t('customer_directories.reg_status.opens'))) {
      regStatusClass = 'reg-status-opens'
    } else if (this.statusTranslation(this.props.status) === (!window.I18n ? '' : window.I18n.t('customer_directories.reg_status.lottery_opens'))) {
      regStatusClass = 'reg-status-lottery-opens'
    } else if (this.statusTranslation(this.props.status) === (!window.I18n ? '' : window.I18n.t('customer_directories.reg_status.lottery_open'))) {
      regStatusClass = this.props.hasEnteredLottery ? 'reg-status-lottery-entered' : 'reg-status-lottery-open'
    } else { 
      regStatusClass = this.props.registered ? 'reg-status-registered' : 'reg-status-closed'
    }
    
    let underlineClass
    regStatusClass === 'reg-status-registered' || regStatusClass === 'reg-status-opens' || (regStatusClass === 'reg-status-open' && !(this.props.endsAt === null)) || regStatusClass === 'reg-status-lottery-entered' || regStatusClass === 'reg-status-lottery-opens' || (regStatusClass === 'reg-status-lottery-open' && !(this.props.lotteryEndsAt === null)) ? underlineClass = 'status-underline' : underlineClass = ''
    
    if (regStatusClass === 'reg-status-open') {
      regStatusClass += ' reg-status-open-condensed'
    }
    
    if (this.statusTranslation(this.props.status) === (!window.I18n ? '' : window.I18n.t('customer_directories.reg_status.invitation_list'))) {
      regStatusClass += ' invitation-font-size'
    } else if (this.statusTranslation(this.props.status) === (!window.I18n ? '' : window.I18n.t('customer_directories.reg_status.closed')) && this.props.registered) {
      regStatusClass += ' registered-closed-font-size'
    } else {
      regStatusClass += ' font-size-12'
    }

    let statusXsString
    if (this.statusTranslation(this.props.status) === (!window.I18n ? '' : window.I18n.t('customer_directories.reg_status.opens')) && this.props.startsAt) {
      const today = moment().tz(this.props.timezone)
      const minOpeningDate = _.min(this.props.openingDates.map(date => moment(date).tz(this.props.timezone)))
      const opensDate = _.find(_.compact([ this.props.invStartsAt, minOpeningDate, this.props.startsAt ]), date => {
        return moment(date).locale(window.I18n.locale)
                           .tz(this.props.timezone)
                           .isAfter(today)
      })
      statusXsString = ' ● ' + moment(opensDate).locale(window.I18n.locale)
                                                .tz(this.props.timezone)
                                                .format('MMM  DD[\n]h:mm A zz')
                                                .toUpperCase()
    } else if ( this.statusTranslation(this.props.status) === (!window.I18n ? '' : window.I18n.t('customer_directories.reg_status.open')) && this.props.endsAt && !this.props.registered) {
      statusXsString = ` ● ${!window.I18n ? '' : window.I18n.t('customer_directories.reg_status.closes_on')} ` + moment(this.props.endsAt).tz(this.props.timezone)
                                                                  .format('MMM  DD[\n]h:mm A zz')
                                                                  .toUpperCase()
    } else if (( this.statusTranslation(this.props.status) === (!window.I18n ? '' : window.I18n.t('customer_directories.reg_status.open') || this.statusTranslation(this.props.status) === (!window.I18n ? '' : window.I18n.t('customer_directories.reg_status.closed')))) && this.props.registered) {
      statusXsString = ` ● ${!window.I18n ? '' : window.I18n.t('customer_directories.reg_status.registered')}`
    } else if ( this.statusTranslation(this.props.status) === (!window.I18n ? '' : window.I18n.t('customer_directories.reg_status.lottery_opens')) && this.props.lotteryStartsAt ) {
      statusXsString = ' ● ' + moment(this.props.lotteryStartsAt).tz(this.props.timezone)
                                                                  .format('MMM  DD[\n]h:mm A zz')
                                                                  .toUpperCase()
    } else if ( this.statusTranslation(this.props.status) === (!window.I18n ? '' : window.I18n.t('customer_directories.reg_status.lottery_open')) && this.props.lotteryEndsAt && !this.props.hasEnteredLottery) {
      statusXsString = ` ● ${!window.I18n ? '' : window.I18n.t('customer_directories.reg_status.lottery_closes_on')} ` + moment(this.props.lotteryEndsAt).tz(this.props.timezone)
                                                                  .format('MMM  DD[\n]h:mm A zz')
                                                                  .toUpperCase()
    } else if ( this.statusTranslation(this.props.status) === (!window.I18n ? '' : window.I18n.t('customer_directories.reg_status.lottery_open')) && this.props.hasEnteredLottery) {
      statusXsString = ` ● ${!window.I18n ? '' : window.I18n.t('customer_directories.reg_status.entered_for_lottery')}`
    }
    let xsDisplay = this.statusTranslation(this.props.status).toUpperCase()
    if (statusXsString) {
      xsDisplay += statusXsString
    }

    let registeredText = ''
    if (this.props.waitlist) {
      registeredText = this.props.waitlist === 'regular' ? !window.I18n ? '' : window.I18n.t('customer_directories.reg_status.waitlisted') : !window.I18n ? '' : window.I18n.t('customer_directories.reg_status.pending')
    } else {
      registeredText = !window.I18n ? '' : window.I18n.t('customer_directories.reg_status.registered')
    }

    let lotteryEnteredText = ''
    if (this.props.hasEnteredLottery) {
      lotteryEnteredText = !window.I18n ? '' : window.I18n.t('customer_directories.reg_status.entered_for_lottery')
    }

    let hideRegistration = ''
    if (this.props.shouldHideRegistration) {
      hideRegistration += ' hidden'
    }

    return <td className={regStatusClass + ' reg-status' + hideRegistration} rowSpan={this.props.rowSpan}><div className="semi-bold margin-right-3 hidden-directories-xs"><p className={underlineClass + ' text-center'}>{ this.statusDisplay(this.statusTranslation(this.props.status))}</p>{ this.statusTranslation(this.props.status) === (!window.I18n ? '' : window.I18n.t('customer_directories.reg_status.opens')) && <p className="reg-date margin-right-3">{ this.opensDate() }</p>
          }{ this.statusTranslation(this.props.status) === (!window.I18n ? '' : window.I18n.t('customer_directories.reg_status.lottery_opens')) && <p className="reg-date margin-right-3">{ moment(this.props.lotteryStartsAt).locale(window.I18n.locale)
                .tz(this.props.timezone)
                .format('ddd, MMM  DD[\n]h:mm A zz')
                .toUpperCase() }</p>
          }{ _.includes([ !window.I18n ? '' : window.I18n.t('customer_directories.reg_status.open'), !window.I18n ? '' : window.I18n.t('customer_directories.reg_status.invitation_list'), !window.I18n ? '' : window.I18n.t('customer_directories.reg_status.membership_status') ], this.statusTranslation(this.props.status)) && this.props.endsAt && !this.props.registered && <div className="margin-right-3"><div className="close-message">{ !window.I18n ? '' : window.I18n.t('customer_directories.reg_status.closes_on') }</div><p className="reg-date">{moment(this.props.endsAt).locale(window.I18n.locale)
                                          .tz(this.props.timezone)
                                          .format('ddd, MMM  DD[\n]h:mm A zz')
                                          .toUpperCase()}</p></div>
          }{ this.statusTranslation(this.props.status) === (!window.I18n ? '' : window.I18n.t('customer_directories.reg_status.lottery_open')) && this.props.lotteryEndsAt && !this.props.hasEnteredLottery && <div className="margin-right-3"><div className="close-message">{ !window.I18n ? '' : window.I18n.t('customer_directories.reg_status.lottery_closes_on') }</div><p className="reg-date">{moment(this.props.lotteryEndsAt).locale(window.I18n.locale)
                                          .tz(this.props.timezone)
                                          .format('ddd, MMM  DD[\n]h:mm A zz')
                                          .toUpperCase()}</p></div>
          }{ this.props.registered && <p className="reg-text">{registeredText}</p>}{ this.props.hasEnteredLottery && !this.props.registered && <p className="reg-text">{lotteryEnteredText}</p>}</div><div className="semi-bold margin-right-3 visible-directories-xs"><p className={'text-center'}>{xsDisplay}</p></div></td>
  }
}

RegStatusComponent.propTypes = {
  status: PropTypes.string,
  registered: PropTypes.bool,
  endsAt: PropTypes.string,
  startsAt: PropTypes.string,
  invStartsAt: PropTypes.string,
  openingDates: PropTypes.array,
  rowSpan: PropTypes.number,
  timezone: PropTypes.string,
  waitlist: PropTypes.string,
  lotteryStartsAt: PropTypes.string,
  lotteryEndsAt: PropTypes.string,
  hasEnteredLottery: PropTypes.bool,
  shouldHideRegistration: PropTypes.bool,
}
export default RegStatusComponent
