import { connect } from 'react-redux'
import Widget from '../components/widget'
import { loadWidgetContent } from '../actions/index'

import Preloader from '../components/content/preloader'

import React from 'react'
import RoundsCount from '../components/content/rounds_count'
import PlayersCount from '../components/content/players_count'
import RegistrationsCount from '../components/content/registrations_count'
import ManagersList from '../components/content/managers_list'
import EventOverview from '../components/content/event_overview'
import RoundsOverview from '../components/content/rounds_overview'
import EventPlayers from '../components/content/event_players'
import PortalContainer from '../containers/content/portal_container'
import DftContainer from '../containers/content/dft_container'
import ReleasePurse from '../components/content/release_purse'
import RoundPlayers from '../components/content/round_players'
import Brackets from '../components/content/brackets'
import EmailAndTextsContainer from '../containers/content/email_and_texts_container'
import EventTournaments from '../components/content/event_tournaments'
import HostVenueContainer from '../containers/content/host_venue_container'
import EventSetupOtherOptionsContainer from '../containers/content/event_setup_other_options_container'
import EventSetupHandicapsContainer from '../containers/content/event_setup_handicaps_container'
import EventSetupScoringContainer from '../containers/content/event_setup_scoring_container'
import EventSetupRoundSignupsContainer from '../containers/content/event_setup_round_signups_container'
import EventSetupPairingsContainer from '../containers/content/event_setup_pairings_container'
import EventSetupCourseDetailsContainer from '../containers/content/event_setup_course_details_container'
import EventSetupRoundsContainer from '../containers/content/event_setup_rounds_container'
import EventSetupTeeSheetContainer from '../containers/content/event_setup_tee_sheet_container'
import EventSetupDivisionsContainer from '../containers/content/event_setup_divisions_container'
import EventSetupFlightsContainer from '../containers/content/event_setup_flights_container'
import EventSetupTournamentsContainer from '../containers/content/event_setup_tournaments_container'
import EventSetupRegistrationContainer from '../containers/content/event_setup_registration_container'
import EventSetupImportGolfersContainer from '../containers/content/event_setup_import_golfers_container'

import HandicapAnalysisContainer from '../containers/content/handicap_analysis_container'
import PrePlayAuditContainer from '../containers/content/pre_play_audit_container'
import PairingsContainer from '../containers/content/pairings_container'
import ScoringContainer from '../containers/content/scoring_container'
import RoundProgressContainer from '../containers/content/round_progress_container'
import Leaderboard from '../components/content/leaderboard'
import ReportsContainer from '../containers/content/reports_container'
import RoundTournamentsContainer from '../containers/content/round_tournaments_container'


const mapStateToProps = (state, ownProps) => {
  return {
    widget: ownProps.widget,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadWidgetContent: () => {
    if (typeof ownProps.widget.content === 'undefined') {
      dispatch(loadWidgetContent(ownProps.widget.data_path, ownProps.widget.id))
    }
  },
  selectComponent: () => {
    switch (ownProps.widget.content_type) {
      case 'rounds_count':
        return { widgetComponent: <RoundsCount content={ ownProps.widget.content } />, widgetClass: 'dashboard-widget-small' }
      case 'players_count':
        return { widgetComponent: <PlayersCount content={ ownProps.widget.content } />, widgetClass: 'dashboard-widget-small' }
      case 'registrations_count':
        return { widgetComponent: <RegistrationsCount content={ ownProps.widget.content } />, widgetClass: 'dashboard-widget-small' }
      case 'managers_list':
        return { widgetComponent: <ManagersList content={ ownProps.widget.content } />, widgetClass: 'dashboard-widget-small' }
      case 'event_overview':
        return { widgetComponent: <EventOverview content={ ownProps.widget.content } />, widgetClass: 'dashboard-widget-medium' }
      case 'rounds_overview':
        return { widgetComponent: <RoundsOverview content={ ownProps.widget.content } />, widgetClass: 'dashboard-widget-medium' }
      case 'event_players':
        return { widgetComponent: <EventPlayers content={ ownProps.widget.content } />, widgetClass: 'dashboard-widget-medium' }
      case 'portal':
        return { widgetComponent: <PortalContainer content={ ownProps.widget.content } widgetId={ ownProps.widget.id } refresh={ loadWidgetContent(ownProps.widget.data_path, ownProps.widget.id) } />, widgetClass: 'dashboard-widget-medium' }
      case 'dft':
        return { widgetComponent: <DftContainer content={ ownProps.widget.content } widgetId={ ownProps.widget.id } />, widgetClass: 'dashboard-widget-medium' }
      case 'release_purse':
        return { widgetComponent: <ReleasePurse content={ ownProps.widget.content } widgetId={ ownProps.widget.id } />, widgetClass: 'dashboard-widget-medium' }
      case 'round_players':
        return { widgetComponent: <RoundPlayers content={ ownProps.widget.content } widgetId={ ownProps.widget.id } />, widgetClass: 'dashboard-widget-medium' }
      case 'brackets':
        return { widgetComponent: <Brackets content={ ownProps.widget.content } />, widgetClass: 'dashboard-widget-medium' }
      case 'email_and_texts':
        return { widgetComponent: <EmailAndTextsContainer content={ ownProps.widget.content } widgetId={ ownProps.widget.id } />, widgetClass: 'dashboard-widget-medium' }
      case 'event_tournaments':
        return { widgetComponent: <EventTournaments content={ ownProps.widget.content } widgetId={ ownProps.widget.id } />, widgetClass: 'dashboard-widget-medium' }
      case 'host_venue':
        return { widgetComponent: <HostVenueContainer content={ ownProps.widget.content } widgetId={ ownProps.widget.id } />, widgetClass: 'dashboard-widget-medium' }

      case 'handicap_analysis':
        return { widgetComponent: <HandicapAnalysisContainer content={ ownProps.widget.content } widgetId={ ownProps.widget.id } />, widgetClass: 'dashboard-widget-medium-wide' }
      case 'pre_play_audit':
        return { widgetComponent: <PrePlayAuditContainer content={ ownProps.widget.content } widgetId={ ownProps.widget.id } refresh={ loadWidgetContent(ownProps.widget.data_path, ownProps.widget.id) } />, widgetClass: 'dashboard-widget-medium-narrow' }
      case 'pairings':
        return { widgetComponent: <PairingsContainer content={ ownProps.widget.content } widgetId={ ownProps.widget.id } refresh={ loadWidgetContent(ownProps.widget.data_path, ownProps.widget.id) } />, widgetClass: 'dashboard-widget-medium-narrow' }
      case 'scoring':
        return { widgetComponent: <ScoringContainer content={ ownProps.widget.content } widgetId={ ownProps.widget.id } refresh={ loadWidgetContent(ownProps.widget.data_path, ownProps.widget.id) } />, widgetClass: 'dashboard-widget-medium-narrow' }
      case 'round_progress':
        return { widgetComponent: <RoundProgressContainer content={ ownProps.widget.content } widgetId={ ownProps.widget.id } />, widgetClass: 'dashboard-widget-medium-narrow' }
      case 'leaderboard':
        return { widgetComponent: <Leaderboard content={ ownProps.widget.content } />, widgetClass: 'dashboard-widget-medium-wide' }
      case 'reports':
        return { widgetComponent: <ReportsContainer content={ ownProps.widget.content } widgetId={ ownProps.widget.id } leagueId={ ownProps.widget.league_id } />, widgetClass: 'dashboard-widget-large' }
      case 'round_tournaments':
        return { widgetComponent: <RoundTournamentsContainer content={ ownProps.widget.content } widgetId={ ownProps.widget.id } refresh={ loadWidgetContent(ownProps.widget.data_path, ownProps.widget.id) } />, widgetClass: 'dashboard-widget-medium-wide' }
      case 'event_setup_other_options':
        return { widgetComponent: <EventSetupOtherOptionsContainer content={ ownProps.widget.content } widgetId={ ownProps.widget.id } refresh={ loadWidgetContent(ownProps.widget.data_path, ownProps.widget.id) } />, widgetClass: 'dashboard-widget-wide-fit' }
      case 'event_setup_handicaps':
        return { widgetComponent: <EventSetupHandicapsContainer content={ ownProps.widget.content } widgetId={ ownProps.widget.id } refresh={ loadWidgetContent(ownProps.widget.data_path, ownProps.widget.id) } />, widgetClass: 'dashboard-widget-medium' }
      case 'event_setup_scoring':
        return { widgetComponent: <EventSetupScoringContainer content={ ownProps.widget.content } widgetId={ ownProps.widget.id } refresh={ loadWidgetContent(ownProps.widget.data_path, ownProps.widget.id) } />, widgetClass: 'dashboard-widget-medium' }
      case 'event_setup_round_signups':
        return { widgetComponent: <EventSetupRoundSignupsContainer content={ ownProps.widget.content } widgetId={ ownProps.widget.id } refresh={ loadWidgetContent(ownProps.widget.data_path, ownProps.widget.id) } />, widgetClass: 'dashboard-widget-medium' }
      case 'event_setup_pairings':
        return { widgetComponent: <EventSetupPairingsContainer content={ ownProps.widget.content } widgetId={ ownProps.widget.id } refresh={ loadWidgetContent(ownProps.widget.data_path, ownProps.widget.id) } />, widgetClass: 'dashboard-widget-medium' }
      case 'event_setup_course_details':
        return { widgetComponent: <EventSetupCourseDetailsContainer content={ ownProps.widget.content } widgetId={ ownProps.widget.id } refresh={ loadWidgetContent(ownProps.widget.data_path, ownProps.widget.id) } />, widgetClass: 'dashboard-widget-large' }
      case 'event_setup_rounds':
        return { widgetComponent: <EventSetupRoundsContainer content={ ownProps.widget.content } widgetId={ ownProps.widget.id } refresh={ loadWidgetContent(ownProps.widget.data_path, ownProps.widget.id) } />, widgetClass: 'dashboard-widget-large' }
      case 'event_setup_tee_sheet':
        return { widgetComponent: <EventSetupTeeSheetContainer content={ ownProps.widget.content } widgetId={ ownProps.widget.id } refresh={ loadWidgetContent(ownProps.widget.data_path, ownProps.widget.id) } />, widgetClass: 'dashboard-widget-large' }
      case 'event_setup_divisions':
        return { widgetComponent: <EventSetupDivisionsContainer content={ ownProps.widget.content } widgetId={ ownProps.widget.id } refresh={ loadWidgetContent(ownProps.widget.data_path, ownProps.widget.id) } />, widgetClass: 'dashboard-widget-large' }
      case 'event_setup_flights':
        return { widgetComponent: <EventSetupFlightsContainer content={ ownProps.widget.content } widgetId={ ownProps.widget.id } refresh={ loadWidgetContent(ownProps.widget.data_path, ownProps.widget.id) } />, widgetClass: 'dashboard-widget-large' }
      case 'event_setup_tournaments':
        return { widgetComponent: <EventSetupTournamentsContainer content={ ownProps.widget.content } widgetId={ ownProps.widget.id } refresh={ loadWidgetContent(ownProps.widget.data_path, ownProps.widget.id) } />, widgetClass: 'dashboard-widget-large' }
      case 'event_setup_registration':
        return { widgetComponent: <EventSetupRegistrationContainer content={ ownProps.widget.content } widgetId={ ownProps.widget.id } refresh={ loadWidgetContent(ownProps.widget.data_path, ownProps.widget.id) } />, widgetClass: 'dashboard-widget-large' }
      case 'event_setup_import_golfers':
        return { widgetComponent: <EventSetupImportGolfersContainer content={ ownProps.widget.content } widgetId={ ownProps.widget.id } refresh={ loadWidgetContent(ownProps.widget.data_path, ownProps.widget.id) } />, widgetClass: 'dashboard-widget-large' }
      default:
        return { widgetComponent: <Preloader content={ ownProps.widget.content } />, widgetClass: 'dashboard-widget-medium' }
    }
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Widget)
