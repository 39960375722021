import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { displayMode, DISPLAY_MODES } from '../../actions'

const AddTab = ({ dispatch }) => {
  return (
    <li role='presentation' className='new-tab'>
      <a as='li'
        role='tab'
        aria-selected='false'
        href='javascript:void(0)'
        id='player-activity-tabs-new-tab'
        onClick={() => { dispatch(displayMode(DISPLAY_MODES.TAB_FORM)) } }>
        { window.I18n.t('multi_widgets.add_new_tab') }
      </a>
    </li>
  )
}

AddTab.propTypes = {
  dispatch: PropTypes.func,
}

export default connect()(AddTab)
