import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import OttScreen from '../ott_screen'
import {switchPlayers, setActiveScreen, reinitialise, toggleSwapMode} from '../actions'
import {GlgTooltip} from '../../shared/components'
import OttErMembersDropdown from './ott_er_members_dropdown'
import { callAPI } from 'Shared/helpers'
import { getMinSlots } from '../selectors'

const filterDataByOwnedMemberPlayer = (data) => {
  const filteredData = data.reduce((result, item) => {
    const ownedPlayers = item.foursome_players.filter(
      (playerInfo) => playerInfo.owned_by_current_member === true && playerInfo.player !== null
    )

    if (ownedPlayers.length > 0) {
      ownedPlayers.forEach((playerInfo) => {
        result.push({
          foursome_id: item.foursome_id,
          position: item.foursome_players.indexOf(playerInfo),
          name: playerInfo.player[0],
          time: item.time || window.I18n.t('players_choose_tee_times.ott_2_0.table.tee') + data.indexOf(item),
        })
      })
    }

    return result
  }, [])

  filteredData.sort((a, b) => {
    const nameA = a.name.toLowerCase()
    const nameB = b.name.toLowerCase()

    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  })

  return filteredData
}

const getTeeTimeOwnedMemberPlayer = (data) => {
  const filteredData = data.reduce((result, item) => {
    const ownedPlayers = item.foursome_players.filter(
      (playerInfo) => playerInfo.owned_by_current_member === true && playerInfo.player !== null
    )

    if (ownedPlayers.length > 0) {
      ownedPlayers.forEach((playerInfo) => {
        result.push([
          item.time || window.I18n.t('players_choose_tee_times.ott_2_0.table.tee') + data.indexOf(item),
          item.foursome_id,
          item.foursome_players.indexOf(playerInfo) + 1,
        ])
      })
    }

    return result
  }, [])

  return filteredData
}

// eslint-disable-next-line react/prop-types
const Dropdown = ({options, currentOption, opIndex}) => {

  const dispatch = useDispatch()

  const [ isOpen, setIsOpen ] = useState(false)
  const [ selectedOption, setSelectedOption ] = useState(currentOption)
  const teeSheetData = useSelector(state => state)

  useEffect(() => {
    setSelectedOption(currentOption)
  }, [ teeSheetData, currentOption ])

  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  const handleSelectOption = (e, option) => {
    switchByFoursome(selectedOption, option)

    setSelectedOption(option)
    setIsOpen(false)
  }

  const switchByFoursome = (selectedOption, option) => {
    dispatch(switchPlayers(selectedOption[1], selectedOption[2] - 1, option[1], option[2] - 1))
  }

  return (
    <div className="dropdown-swap">
      <button className="dropdown-toggle-swap" onClick={handleToggle}>
        {selectedOption[opIndex]}
      </button>
      {isOpen && (
        <ul className="dropdown-toggle-swap dropdown-menu-swap">
          {/* eslint-disable-next-line react/prop-types */}
          {options.map((option, index) => (
            <li key={index} onClick={(e) => handleSelectOption(e, option)}>
              {selectedOption[opIndex] === option[opIndex] && <span className="selected-ticket">&#10003;</span>}
              {option[opIndex]}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

const OttHeader = () => {
  const teeSheetData = useSelector(state => state)
  const activeScreen = useSelector(state => state.activeScreen)
  const minSlots = useSelector(getMinSlots)
  const registeredMembers = useSelector(state => state.members)
  const bookBeforeRegister = useSelector(state => state.bookBeforeRegister)
  const currentMemberId = useSelector(state => state.currentMemberId)
  const remainingSlotsRegisterGolfers = useSelector(state => state.remainingSlotsRegisterGolfers)
  const dispatch = useDispatch()
  const [ isSwapPopupOpen, setSwapPopupOpen ] = useState(false)
  const [ selectedPlayerId, setSelectedPlayerId ] = useState(null)
  const leagueId = useSelector(state => state.leagueId)
  const roundId = useSelector(state => state.roundId)
  const isAdminMode = useSelector(state => state.isAdminMode)
  const [ isLoading, setIsLoading ] = useState(false)

  const [ windowWidth, setWindowWidth ] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [ window.innerWidth ])


  const enterChangeTeeTimeScreen = (e) => {
    e.preventDefault()
    dispatch(setActiveScreen(OttScreen.CHANGE_TEE_TIME))
  }

  const enterAddAdditionalPlayerScreen = (e) => {
    e.preventDefault()

    dispatch(setActiveScreen(OttScreen.ADD_ADDITIONAL_PLAYERS))
  }

  const changeToRemovePlayerMode = (e) => {
    e.preventDefault()
    dispatch(setActiveScreen(OttScreen.REMOVE_PLAYERS))
  }

  const openSwapPlayersPopup = () => {
    document.body.scrollTop = document.documentElement.scrollTop = 0
    dispatch(toggleSwapMode(true))
    setSwapPopupOpen(true)
  }

  const closeSwapPlayersPopup = () => {
    setSwapPopupOpen(false)
    dispatch(toggleSwapMode(false))
    reinitialiseState()
  }

  const exitMember = () => {
    exitMemberMode()
  }

  const doneSwapPlayersPopup = () => {
    dispatch(toggleSwapMode(false))
    setSwapPopupOpen(false)
  }

  const countDataByOwnedMember = (data) => {
    const filteredData = data.reduce((result, item) => {
      const ownedPlayers = item.foursome_players.filter(
        (playerInfo) => playerInfo.owned_by_current_member === true && playerInfo.player !== null
      )

      if (ownedPlayers.length > 0) {
        ownedPlayers.forEach((playerInfo) => {
          result.push({
            foursome_id: item.foursome_id,
            position: item.foursome_players.indexOf(playerInfo),
          })
        })
      }

      return result
    }, [])

    return filteredData.length
  }

  const isAdditionalButtonDisabled = (() => {
    return remainingSlotsRegisterGolfers <= 0
  })()

  const isRemoveButtonDisabled = (() => {
    return countDataByOwnedMember(teeSheetData.data) < 1
  })()

  useEffect(() => {
  }, [ teeSheetData ])

  const handleBecomeClick = (e) => {
    e.preventDefault()
    setIsLoading(true)

    const playerId = selectedPlayerId

    const url = `/leagues/${leagueId}/widgets/players_choose_tee_times/reinitialise_and_set_current_member_for_admin`
    callAPI(url, 'POST', { player_id: playerId, round_id: roundId })
      .then((resp) => {
        dispatch(reinitialise(resp.message))
        setIsLoading(false)
      })
      .catch(() => {
      })
  }

  const exitMemberMode = () => {

    const url = `/leagues/${leagueId}/widgets/players_choose_tee_times/reinitialise_and_set_current_member_for_admin`
    callAPI(url, 'POST', { round_id: roundId, clear_reservation: true})
      .then((resp) => {
        dispatch(reinitialise(resp.message))
      })
      .catch(() => {
      })
  }

  const reinitialiseState = () => {
    const url = `/leagues/${leagueId}/widgets/players_choose_tee_times/reinitialise_and_set_current_member_for_admin`
    callAPI(url, 'POST', { round_id: roundId, selected_member_id: currentMemberId })
      .then((resp) => {
        dispatch(reinitialise(resp.message))
      })
      .catch(() => {
      })
  }

  const renderRevertToAdminButton = () => {
    if (!isAdminMode) { return null }

    return (
      <div className={'back-button-style right-button-style revert-to-admin-button'} onClick={exitMember}>
        {window.I18n.t('players_choose_tee_times.ott_2_0.revert_to_admin')}
      </div>
    )
  }

  if (activeScreen === OttScreen.EDIT_BOOKING) {
    return (
      <>
        { renderRevertToAdminButton() }
        <div className={'button-container-header'}>
          <div className={'left-buttons-container-style'}>

            <GlgTooltip tooltip={'Move yourself and your playing partners to another tee time'} placement="top">
              <button key="changeTeeTimeBttnnnn" className={'change-tee-time-button'}
                      onClick={(e) => (enterChangeTeeTimeScreen(e))}>{window.I18n.t('players_choose_tee_times.ott_2_0.buttons.change_tee_time')}
              </button>
            </GlgTooltip>

            {(!isAdminMode && remainingSlotsRegisterGolfers >= 0) &&
              <GlgTooltip
                tooltip={isAdditionalButtonDisabled ? window.I18n.t('players_choose_tee_times.ott_2_0.tooltips.limit_reached') : window.I18n.t('players_choose_tee_times.ott_2_0.tooltips.additional_registration')}
                placement="top">
                <button
                  disabled={isAdditionalButtonDisabled}
                  className={isAdditionalButtonDisabled ? 'header-button-disabled' : 'change-tee-time-button-weider'}
                  onClick={(e) => enterAddAdditionalPlayerScreen(e)}>
                  {windowWidth <= 800 ?
                    window.I18n.t('players_choose_tee_times.ott_2_0.buttons.register_additional_mobile') : window.I18n.t('players_choose_tee_times.ott_2_0.buttons.register_additional_desktop')}
                </button>
              </GlgTooltip>
            }
          </div>
          <GlgTooltip
            tooltip={isRemoveButtonDisabled ? window.I18n.t('players_choose_tee_times.ott_2_0.tooltips.no_playing_partners') : window.I18n.t('players_choose_tee_times.ott_2_0.tooltips.remove_players')}
            placement="top">
            <button key="cancelBttn" disabled={isRemoveButtonDisabled}
                    className={isRemoveButtonDisabled ? 'cancel-bttn-disabled' : 'cancel-bttn'}
                    onClick={changeToRemovePlayerMode}>{window.I18n.t('players_choose_tee_times.ott_2_0.buttons.remove_player_s')}
            </button>
          </GlgTooltip>
        </div>
      </>
    )
  } else if (activeScreen === OttScreen.SIGNUP_ER_MEMBER) {
    return (<>{ renderRevertToAdminButton() }</>)
  } else if (activeScreen === OttScreen.ADMIN_OR_MANAGER_SCREEN) {
    return (
      <div className={'dropdown-container'}>
        <div className={'add-golfers-canvas-small'}>
          <label className="black-text">{window.I18n.t('players_choose_tee_times.ott_2_0.add_player_popup.select_golfer')}</label>
          <OttErMembersDropdown setSelectedPlayerId={setSelectedPlayerId} disablePlayers={false}/>

          <div div className={'button-container-style'}>
            <button className={`back-button-style ${selectedPlayerId ? 'enabled-bg-button-style' : 'disabled-bg-button-style'}`} onClick={handleBecomeClick} disabled={isLoading || !selectedPlayerId}>
              { isLoading ? window.I18n.t('players_choose_tee_times.ott_2_0.buttons.become_redirected') : window.I18n.t('players_choose_tee_times.ott_2_0.buttons.become') }
            </button>
          </div>
        </div>
      </div>

    )
  } else {
    if (bookBeforeRegister === false && activeScreen !== OttScreen.CHANGE_TEE_TIME && activeScreen !== OttScreen.REMOVE_PLAYERS) {
      return (
        <div className={'inner-box-style'}>
          {window.I18n.t('players_choose_tee_times.ott_2_0.tooltips.select_slots', {slotsNumber: `${registeredMembers !== null ? registeredMembers.length : 0}` } )}
        </div>
      )
    } else if (bookBeforeRegister === true && activeScreen !== OttScreen.CHANGE_TEE_TIME && activeScreen !== OttScreen.REMOVE_PLAYERS) {
      return (
        <>
          { renderRevertToAdminButton() }
          <div className={'inner-box-style'}>
            {window.I18n.t('players_choose_tee_times.ott_2_0.limits.min_slots')} {minSlots}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{window.I18n.t('players_choose_tee_times.ott_2_0.limits.max_slots')} {remainingSlotsRegisterGolfers}
          </div>
        </>
      )
    } else if (activeScreen === OttScreen.CHANGE_TEE_TIME) {
      return (

        <div>
          <button key="swapBttn"
                  className='change-tee-time-button ott-swap-button-margin-top-20px'
                  onClick={openSwapPlayersPopup}>{window.I18n.t('players_choose_tee_times.ott_2_0.buttons.swap_players')}
          </button>

          {isSwapPopupOpen && (
            <div className={'popup-container-style-swap'}>
              <div className={'popup-style-swap'}>
                <div className={'ott-title-footer-style'}> {window.I18n.t('players_choose_tee_times.ott_2_0.buttons.swap_players_caps')} </div>
                <div className={'swap-players-canvas'}>

                  {filterDataByOwnedMemberPlayer(teeSheetData.data).map((item, index) => (
                    <div key={index} className={'swap-players-member'}>
                      {item.name}
                      <div className={'swap-details-container'}>
                        <div className={'swap-tee-time'}>
                          {window.I18n.t('players_choose_tee_times.ott_2_0.table.tee_time')}:
                          <Dropdown options={getTeeTimeOwnedMemberPlayer(teeSheetData.data)}
                                    currentOption={[ item.time, item.foursome_id, item.position + 1 ]}
                                    opIndex={0}/>
                        </div>

                        <div className={'swap-slots'}>
                          {window.I18n.t('players_choose_tee_times.ott_2_0.table.slots')}:
                          <Dropdown options={getTeeTimeOwnedMemberPlayer(teeSheetData.data)}
                                    currentOption={[ item.time, item.foursome_id, item.position + 1 ]}
                                    opIndex={2}/></div>
                      </div>
                    </div>
                  ))}
                </div>


                <div className={'button-container-style'}>
                  <div className={'back-button-style right-button-style'} onClick={closeSwapPlayersPopup}>
                    {window.I18n.t('players_choose_tee_times.ott_2_0.buttons.cancel')}
                  </div>
                  <div className={'back-button-style enabled-bg-button-style'} onClick={doneSwapPlayersPopup}>
                    {window.I18n.t('players_choose_tee_times.ott_2_0.buttons.done')}
                  </div>
                </div>


              </div>
            </div>
          )}
        </div>
      )
    } else {
      return (<div></div>)
    }
  }
}

OttHeader .propTypes = {
  data2: PropTypes.any,
}

export default OttHeader

