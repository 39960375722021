import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { GlgPopup, GlgSelect, GlgSwitch, GlgRangeSlider } from 'SharedComponents'
import { Row, Col } from 'react-flexbox-grid'
import AlbumPickerComponent from '../album_picker_component'
import PhotoRollComponent from '../photo_roll_component'
import AlbumPhotoPickerComponent from '../album_photo_picker_component'
import _ from 'lodash'

class PhotosSlidePopupComponent extends Component {

  constructor(props) {
    super(props)

    this.state = {
      isSaving: false,
      roll: [],
      selectedAlbumPhotos: [],
      animation: 0,
    }

    this.addToRoll = this.addToRoll.bind(this)
    this.removeFromRoll = this.removeFromRoll.bind(this)
    this.saveToDB = this.saveToDB.bind(this)
    this.selectAlbum = this.selectAlbum.bind(this)
  }

  setInitialValues() {
    const backgroundOpacity = _.get(this.props, 'backgroundOpacity', 100)
    this.props.setBackgroundOpacity(backgroundOpacity)
  }

  componentDidUpdate(previousProps) {
    const { show, roll, animation } = this.props
    if (show) {
      if (!previousProps.show) {
        this.setInitialValues()
        if (roll) {
          this.setState({ 
            roll: Object.entries(roll).map((item) => item[1]),
            animation,
          })
        }
      }
    }
  }

  saveToDB() {
    const {
      roll,
      animation,
    } = this.state
    
    const {
      slideId,
      isVisible,
      backgroundImage,
      scrollRate,
      slidesCount,
      backgroundOpacity,
    } = this.props

    const slide = {
      slideId,
      position: slidesCount,
      visible: isVisible,
      type: 'photos',
      description: 'Photos Slide',
      background: backgroundImage,
      scrollRate: scrollRate,
      settings: {
        displayType: 'photos',
        backgroundOpacity,
        roll,
        animation,
      },
    }
    this.setState({ isSaving: true })
    $.ajax({
      url: this.props.paths.saveSlide,
      type: 'POST',
      data: slide,
      success: (response) => {
        if (slideId) {
          this.props.updateSlide({
            id: slideId,
            type: 'photos',
            description: 'Photos Slide',
            visible: slide.visible,
          })
        } else {
          this.props.addSlide({
            id: response.id,
            type: 'photos',
            description: 'Photos Slide',
            visible: slide.visible,
          })
        }

        this.setState({ isSaving: false }, this.props.onClose)
      },
      error: () => {
      },
    })
  }

  addToRoll(id, type, photo) {
    let roll = this.state.roll //eslint-disable-line 
    roll.push({ id, type, photo })
    this.setState({ roll })
  }

  removeFromRoll(id) {
    let roll = this.state.roll
    roll = roll.filter((v, k) => k !== id)
    this.setState({ roll })
  }

  selectAlbum(photos) {
    this.setState({
      selectedAlbumPhotos: photos,
    })
  }

  render() {
    const { selectedAlbumPhotos, roll, isSaving } = this.state
    const {
      slideId,
    } = this.props

    return <GlgPopup title={ `${slideId ? 'Edit' : 'New' } Photo Album Show` } saveButtonText={ slideId ? 'Update' : 'Save' } saveButtonEnabled={ roll.length > 0 } show={ this.props.show } modalClass={ 'photos-popup' } manualClose={ true } onClose={ this.props.onClose } onSave={ this.saveToDB } isSaving={ isSaving }><div id={'slide_setup'} className={'popup_container'}><div className="visible-switch justify-center"><GlgSwitch id={ 'leaderboard-visible-switch' } text={ [ 'Visible', 'Hidden' ] } widthInPixels={ 80 } checked={ !!this.props.isVisible } onChange={ () => { this.props.setVisible(!this.props.isVisible) } }></GlgSwitch></div><ul className="no-padding"><li className="form_row add-margin-top-20 add-padding-bottom-20"><div className="row popup-section-title no-padding-bottom"><Col xs={ 12 }>{ 'Content Transition' }</Col></div><div className="row popup-section vertical-align-items no-padding-bottom"><Col xs={ 12 } sm={ 6 }><Row className="vertical-align-items add-padding-top-5 add-padding-bottom-5"><Col xs={ 12 } md={ 4 } lg={ 4 }>{ 'Scroll Rate' }</Col><Col xs={ 12 } md={ 8 } lg={ 8 }><GlgRangeSlider value={ this.props.scrollRate !== undefined ? this.props.scrollRate : 0 } min={ 1 } max={ 20 } unit={ ' seconds' } onChange={ this.props.changeScrollRate }></GlgRangeSlider></Col></Row></Col><Col xs={ 12 } sm={ 6 }><Row className="vertical-align-items add-padding-top-5 add-padding-bottom-5"><Col xs={ 12 } md={ 4 } lg={ 3 }>{ 'Animation' }</Col><Col xs={ 12 } md={ 8 } lg={ 9 }><GlgSelect value={ this.state.animation } items={[ 
                          { value: 0, label: 'None' }, 
                          { value: 1, label: 'Zoom Out' }, 
                          { value: 2, label: 'Zoom In' }, 
                          { value: 3, label: 'Pan from left' },
                          { value: 4, label: 'Pan from right' },
                          { value: 5, label: 'Random' }, 
                        ]} onChange={ (v) => this.setState({ animation: v })}></GlgSelect></Col></Row></Col></div></li><li className="form_row add-margin-top-10 add-padding-bottom-10"><Col xs={ 6 }><div className="row popup-section-title"><Col xs={ 12 }>{ selectedAlbumPhotos.length > 0 ? 'Select Photos from this Album' : 'Select Album' }  </Col></div><div className="row popup-section no-padding-bottom"><Col xs={ 12 }><AlbumPickerComponent fetchImagesPath={ this.props.paths.fetchAlbumsCollection } uploadImagePath={ this.props.paths.uploadBackgroundImage } addToRoll={ this.addToRoll } title={'Select Album'} selectedAlbumPhotos={ selectedAlbumPhotos } selectAlbum={ this.selectAlbum }></AlbumPickerComponent><AlbumPhotoPickerComponent fetchImagesPath={ this.props.paths.fetchAlbumsCollection } uploadImagePath={ this.props.paths.uploadBackgroundImage } updateImageTitlePath={ this.props.paths.updatePhotoCaption } addToRoll={ this.addToRoll } title={'Select a photo'} selectedAlbumPhotos={ selectedAlbumPhotos } selectAlbum={ this.selectAlbum }></AlbumPhotoPickerComponent></Col></div></Col><Col xs={ 6 }><div className="row popup-section-title"><Col xs={ 12 }>{ 'Photo Roll' }  </Col></div><div className="row popup-section no-padding-bottom"><Col xs={ 12 }><PhotoRollComponent fetchImagesPath={ this.props.paths.fetchAlbumsCollection } uploadImagePath={ this.props.paths.uploadPhotoRollImage } addToRoll={ this.addToRoll } removeFromRoll={ this.removeFromRoll } items={ this.state.roll } title={'Photo Roll'}></PhotoRollComponent></Col></div></Col></li></ul></div></GlgPopup>
  }
}

PhotosSlidePopupComponent.propTypes = {
  paths: PropTypes.object.isRequired,
  slideId: PropTypes.string,
  type: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  rounds: PropTypes.array.isRequired,
  setRound: PropTypes.func,
  tournamentsByRoundId: PropTypes.object,
  setTournament: PropTypes.func,
  divisionIds: PropTypes.array,
  setDivisionIds: PropTypes.func,
  flightIdsAndValuesByDivisionId: PropTypes.object,
  setFlights: PropTypes.func,
  divisionsBySpecId: PropTypes.object,
  flightsByDivisionId: PropTypes.object,
  slidesCount: PropTypes.number.isRequired,
  isVisible: PropTypes.bool,
  scrollRate: PropTypes.number,
  backgroundImage: PropTypes.string,
  setVisible: PropTypes.func,
  changeScrollRate: PropTypes.func,
  addSlide: PropTypes.func.isRequired,
  updateSlide: PropTypes.func.isRequired,
  setTournamentsData: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  backgroundOpacity: PropTypes.number,
  setBackgroundOpacity: PropTypes.func.isRequired,
  roll: PropTypes.oneOfType([ PropTypes.object, PropTypes.array ]),
  animation: PropTypes.number,
}

PhotosSlidePopupComponent.defaultProps = {
  divisionIds: [],
}

export default PhotosSlidePopupComponent
