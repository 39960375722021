import { createSelector } from 'reselect'

const getFutureEventsById = state => state.participationHistory.futureEvents
const getFutureRoundsById = state => state.participationHistory.futureRounds
const getFilter = state => state.participationHistory.seasonId

export const getFutureEvents = createSelector(
  [ getFutureEventsById, getFilter ],
  (events, seasonId) => {
    if (seasonId === 'all') {
      return events
    }
    const visibleEvents = events.filter((t) => t.seasonId === seasonId)
    return visibleEvents
  }
)
export const getFutureRounds = createSelector(
  [ getFutureRoundsById, getFilter ],
  (rounds, seasonId) => {
    if (seasonId === 'all') {
      return rounds
    }
    const visibleRounds = rounds.filter((t) => t.seasonId === seasonId)
    return visibleRounds
  }
)

const getPastEventsById = state => state.participationHistory.pastEvents
const getPastRoundsById = state => state.participationHistory.pastRounds

export const getPastEvents = createSelector(
  [ getPastEventsById, getFilter ],
  (events, seasonId) => {
    if (seasonId === 'all') {
      return events
    }
    const visibleEvents = events.filter((t) => t.seasonId === seasonId)
    return visibleEvents
  }
)

export const getPastRounds = createSelector(
  [ getPastRoundsById, getFilter ],
  (rounds, seasonId) => {
    if (seasonId === 'all') {
      return rounds
    }
    const visibleRounds = rounds.filter((t) => t.seasonId === seasonId)
    return visibleRounds
  }
)

const getTotalMoneyEarnedById = state => state.participationHistory.moneyEarnedTotal

export const getTotalMoneyEarned = createSelector(
  [ getTotalMoneyEarnedById, getFilter ],
  (totalMoneyEarned, seasonId) => {
    return totalMoneyEarned[seasonId]
  }
)
