import { createSelector } from 'reselect'

const getSortOption = state => state.filters.sortByOption
const getOrder = state => state.filters.sortOrder
const foursomes = state => state.misc.foursomes

const getSortedPairings = createSelector(
  [ getSortOption, getOrder, foursomes ],
  (sortOption, order, foursomes) => {
    if (sortOption === 'pairing') {
      return foursomes.slice(0).sort( (f1, f2) => {
        if (order === 'asc') {
          return f1.position - f2.position
        } else {
          return f2.position - f1.position
        }
      })
    } else if (sortOption === 'tee_time') {
      return foursomes.slice(0).sort( (f1, f2) => {
        if (order === 'asc') {
          return f1.tee_time < f2.tee_time ? -1 : 1
        } else {
          return f2.tee_time < f1.tee_time ? -1 : 1
        }        
      })
    }
  }
)

export default getSortedPairings
