// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Polyfills
import '@babel/polyfill'
import 'isomorphic-fetch'
import 'formdata-polyfill'

// Root Components
import WebpackerReact from 'webpacker-react'

// import { initPerformanceDebugging } from './dev_tools'
if (process.env.NODE_ENV === 'development') {
  // initPerformanceDebugging()
}

// Set up root components (the ones that will be used by the react_component helper)
import DiscoverCenter from '../src/download_center/discover_center'
import DownloadCenter from '../src/download_center/download_center'
import ScorecardsCenter from '../src/scorecards_center/scorecards_center'
import CustomerCenterRoot from '../src/customer_center/containers/customer_center_root'
import AdminNavigatorOptionsRoot from '../src/admin/navigator_options/containers/admin_navigator_options_root'
import NavigatorRoot from '../src/navigator/containers/navigator_root'
import MultiWidgetsRoot from '../src/multi_widgets/components/containers/root'
import MarketingCampaignsIndexRoot from '../src/marketing_campaigns/containers/marketing_campaigns_index_root'
import MarketingCampaignsSetupRoot from '../src/marketing_campaigns/containers/marketing_campaigns_setup_root'
import MarketingCampaignsDeliveryReports from '../src/marketing_campaigns/components/delivery_reports/delivery_reports_root'
import PlayerActivityDashboardRoot from '../src/player_activity/containers/player_activity_dashboard_root'
import DirectoriesRoot from '../src/directories/containers/directories_root'
import MembersPageRoot from '../src/members_page/containers/members_page_root'
import TvShowsIndexRoot from '../src/tv_shows/containers/tv_shows_index_root'
import TvShowSetupRoot from '../src/tv_shows/containers/tv_show_setup_root'
import TvShowSlideshowRoot from '../src/tv_shows/containers/tv_show_slideshow_root'
import DashboardRoot from '../src/dashboard/containers/dashboard_root'
import DirectorySetupRoot from '../src/directory_setup/components/DirectorySetupRoot'
import GolfHubRoot from '../src/registration_form/GolfHubRoot'
import GroupViewRoot from '../src/group_view/containers/group_view_root'
import ScoringControlInterfaceRoot from '../src/scoring_control_interface/scoring_control_interface_root'
import ShotByShotViewRoot from '../src/shot_by_shot_view/containers/shot_by_shot_view_root'
import DoubleScoreVerificationRoot from '../src/double_score_verification/containers/double_score_verification_root'
import CourseLeaderboardRoot from '../src/course_leaderboard/containers/course_leaderboard_root'
import CourseBoardRoot from '../src/course_board/containers/course_board_root'
import PgaRankingsRoot from '../src/pga_rankings/containers/pga_rankings_root'
import MonsterBoardRoot from '../src/monster_board/containers/monster_board_root'
import MonsterBoardDeviceRoot from '../src/monster_board_device/containers/monster_board_device_root'
import DrivingDistancesRoot from '../src/driving_distances/containers/driving_distances_root'
import ThruBoardRoot from '../src/thru_board/thru_board_root'
import MemberCards from '../src/member_cards/containers/member_card_root'
import TourEventResultsRoot from '../src/tour_event_results/containers/tour_event_results_root'
import TourGamesSeasonStandingsRoot from '../src/tour_games_season_standings/tour_games_season_standings_root'
import GolferDetailedResults from '../src/tour_games_season_standings/golfer_detailed_results'
import KeySettingsRoot from '../src/key_settings/components/key_settings_root'
import OttPageRoot from '../src/ott/components/ott_page_root.jsx'
import Flyers from '../src/flyer/Flyers'
import FlyerImages from '../src/flyers_images/FlyerImages'
import DraftRoot from '../src/draft/containers/setup/draft_root'
import DraftManagerSideRoot from '../src/draft/containers/manager_side/manager_side_root'
import FlyersList from '../src/flyer/FlyersList'

WebpackerReact.setup({
  DiscoverCenter,
  DownloadCenter,
  ScorecardsCenter,
  CustomerCenterRoot,
  AdminNavigatorOptionsRoot,
  NavigatorRoot,
  MultiWidgetsRoot,
  MarketingCampaignsIndexRoot,
  MarketingCampaignsSetupRoot,
  MarketingCampaignsDeliveryReports,
  PlayerActivityDashboardRoot,
  DirectoriesRoot,
  MembersPageRoot,
  TvShowsIndexRoot,
  TvShowSetupRoot,
  TvShowSlideshowRoot,
  DashboardRoot,
  DirectorySetupRoot,
  GolfHubRoot,
  GroupViewRoot,
  ScoringControlInterfaceRoot,
  ShotByShotViewRoot,
  DoubleScoreVerificationRoot,
  CourseLeaderboardRoot,
  CourseBoardRoot,
  PgaRankingsRoot,
  MonsterBoardRoot,
  MonsterBoardDeviceRoot,
  DrivingDistancesRoot,
  ThruBoardRoot,
  MemberCards,
  TourEventResultsRoot,
  TourGamesSeasonStandingsRoot,
  GolferDetailedResults,
  KeySettingsRoot,
  OttPageRoot,
  Flyers,
  FlyerImages,
  DraftRoot,
  DraftManagerSideRoot,
  FlyersList,
})
