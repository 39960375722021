import { createSelector } from 'reselect'
import _ from 'lodash'

import { filterLeagueIds } from './index'

const getCategories = state => state.categories
const getFilters = state => state.filters
const getLeaguesById = state => state.leagues

const getVisibleCategoryIds = createSelector(
  [ getCategories, getFilters, getLeaguesById ],
  (categoriesById, filters, leaguesById) => {
    let categoryIds = _.keys(categoriesById)
    const categoryFilter = filters.category
    if (categoryFilter !== 'all') {
      categoryIds = categoryIds.filter(categoryId => (
        categoryId === categoryFilter
      ))
    }

    // While search is active, only show categories with at least one league
    if (filters.leagueName.length > 0) {
      categoryIds = categoryIds.filter(categoryId => {
        const leagueIds = categoriesById[categoryId].leagues
        return filterLeagueIds(leagueIds, leaguesById, categoriesById, filters).length > 0
      })
    }

    return categoryIds
  }
)

export default getVisibleCategoryIds
