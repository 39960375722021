import { createSelector } from 'reselect'
import _ from 'lodash'

const getCustomersById = state => state.customers

const getCustomersList = createSelector(
  [ getCustomersById ],
  customersById => {
    let customers = _.values(customersById)
    customers = _.sortBy(customers, customer => customer.name.toLowerCase())
    return customers
  }
)

export default getCustomersList
