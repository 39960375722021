import { ActionTypes } from '../actions'

const {
  REMOVE_LINE,
  ADD_LINE,
  REORDER_LINES,
  HANDLE_SHOW_MESSAGE_CHANGED,
  HANDLE_MESSAGE_CHANGED,
  HANDLE_SCORE_CHANGED,
  REFRESH_DATA,
  SUBMIT_COURSE_LEADERBOARD,
} = ActionTypes

const initialState = {
  unSavedChanges: false,
}

const misc = (state = initialState, action) => {
  switch (action.type) {
    case ADD_LINE:
      return {
        ...state,
        unSavedChanges: true,
        players: state.players.map( player => 
          player.memberIds === action.memberIds ?
            { 
              ...player,
              position: action.position,
              onBoard: true, 
            }
            : player.memberIds === action.removeMemberIds ?
              {
                ...player,
                position: null,
                onBoard: false,
              }
              : player
        ),
      }
    case REMOVE_LINE:
      return {
        ...state,
        unSavedChanges: true,
        players: state.players.map( player => 
          player.position === action.position ? 
            { 
              ...player, 
              position: null,
              onBoard: false,
            }
            : player
        ),
      }
    case REORDER_LINES:
      return {
        ...state,
        unSavedChanges: true,
        players: state.players.map( player => {
          return player.position !== null && player.position === action.fromPosition ?
            {
              ...player,
              position: action.toPosition,
            }
            : player.position !== null && player.position >= action.from && player.position <= action.to ?
              {
                ...player,
                position: player.position + action.offset,
              }
              : player
        }),
      }
    case HANDLE_SHOW_MESSAGE_CHANGED:
      return {
        ...state,
        unSavedChanges: true,
        showMessage: action.value,
      }
    case HANDLE_MESSAGE_CHANGED:
      return {
        ...state,
        unSavedChanges: true,
        message: action.value,
      }
    case HANDLE_SCORE_CHANGED:
      return {
        ...state,
        unSavedChanges: true,
        score: action.value,
      }
    case REFRESH_DATA:
      return {
        ...state,
        roundGgid: action.data.roundGgid,
        isMultiRound: action.data.isMultiRound,
        withTeams: action.data.withTeams,
        showCourse: action.data.showCourse,
        players: action.data.players.map( player => {
          const currentPlayer = state.players.find( p => p.memberIds === player.memberIds)
          return currentPlayer !== undefined && currentPlayer.position !== player.position ? {
            ...player,
            onBoard: currentPlayer.onBoard,
            position: currentPlayer.position,
          }
            : player
        }),
        lastDeviceUpdate: action.data.lastDeviceUpdate,
        noRowsOnCourseLeaderboard: action.data.noRowsOnCourseLeaderboard,
      }
    case SUBMIT_COURSE_LEADERBOARD:
      return {
        ...state,
        unSavedChanges: false,
        lastDeviceUpdate: action.data.lastDeviceUpdate,
      }
    default:
      return state
  }
}

export default misc
