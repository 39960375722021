import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-bootstrap'

import TeeData from './tee_data_component'
import FoursomeData from './foursome_data_component'

class ShotByShotTableComponent extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const tees = { ...this.props.tees }
    const foursomes = [ ...this.props.foursomes ]

    return (
      <Col xs={12} sm={12} md={12} lg={12} className='no-padding'>
        <div className='shot_trackings_table btsp_ps_container btsp_fheader_table_container'>
          <table className='shot_trackings btsp_fheader_table'>
            <thead> 
              <TeeData 
                tees={tees}
                isMultiRound={this.props.isMultiRound}
              />
            </thead>
            <tbody>
              {
                foursomes.map( foursome => 
                  <FoursomeData 
                    key={foursome.id}
                    foursome={foursome}
                    isMultiRound={this.props.isMultiRound}/>
                )
              }
            </tbody>
          </table>
        </div>
      </Col>
    )
  }
}

ShotByShotTableComponent.propTypes = {
  tees: PropTypes.object,
  foursomes: PropTypes.array,
  isMultiRound: PropTypes.bool,
  togglePopup: PropTypes.func,
}

export default ShotByShotTableComponent
