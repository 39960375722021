import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-flexbox-grid'
import { Row } from 'react-bootstrap'

class BlockForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: '',
      titles: {},
      layout: '1',
      usesTranslations: this.props.usesTranslations,
      availableLanguages: this.props.availableLanguages,
      shouldHide: true,
      shouldHideButton: false,
    }
    this.setLayout = this.setLayout.bind(this)
    this.setTitle = this.setTitle.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  setLayout(_layout) {
    this.setState({
      layout: _layout,
    })
  }

  setTitle(_title) {
    this.setState({
      title: _title,
    })
  }
  
  setTitles(_title, key) {
    this.setState({
      titles: {
        ...this.state.titles,
        [key]: _title,
      },
    })
  }

  handleSubmit() {
    this.props.createBlock(
      this.props.activeTab,
      this.state.title,
      this.state.titles,
      this.state.layout,
    )
  }

  render() {
    return (
      <Col xs={12} className="manage-blocks">
        <Col xs={10} xsOffset={1} className="title">
          <h1>{window.I18n.t('multi_widgets.block_form.title')}</h1>
        </Col>
        <Col xs={10} xsOffset={1} className="inner-content">
          <Col xs={10} xsOffset={1} className="form-row top-row">
            {!this.state.shouldHideButton &&
              <Row xs={4}>
                <Col xs={4} className="labels">{window.I18n.t('multi_widgets.block_form.block_title_label')}</Col>
                <Col xs={6} className="form-group no-margin-bottom">
                  <input type="text"
                        placeholder={window.I18n.t('multi_widgets.block_form.block_title_label')}
                        className="form-control"
                        value={ this.state.titles[this.props.currentLanguage] }
                        onChange={ e => this.setTitles(e.target.value, this.props.currentLanguage) }
                        autoFocus />
                </Col>
                <Col xs={2} className="info">{window.I18n.t('multi_widgets.block_form.optional')}</Col>
              </Row>
            }
            {this.props.usesTranslations && !this.state.shouldHideButton && 
              <Row xs={4}>
                <a xs={10}className="translation_check" href="javascript:void(0)"
                        onClick={ e => { e.preventDefault(); this.setState({ shouldHide: false, shouldHideButton: true }) } } >
                </a>
                <label xs={2} className="translation_text"
                        onClick={ e => { e.preventDefault(); this.setState({ shouldHide: false, shouldHideButton: true }) } } >
                        {window.I18n.t('pages.edit.portal_page_options.add_translation')}
                </label>
              </Row>
            }
            {this.state.availableLanguages.map(key => (
              <Row xs={4} key={key} className={this.state.shouldHide ? 'hidden' : undefined}>
                <Col xs={4} className="labels margin-bottom-20">{window.I18n.t('pages.edit.portal_page_options.title_' + key)}</Col>
                <Col xs={6} className="form-group no-margin-bottom">
                  <input type="text"
                        placeholder={window.I18n.t('multi_widgets.tabs.title_placeholder')}
                        className="form-control"
                        value={ this.state.titles[key] }
                        onChange={ e => this.setTitles(e.target.value, key) }
                        autoFocus />
                </Col>
              </Row>
            ))}
          </Col>
          <Col xs={10} xsOffset={1} className="form-row">
            <Col xs={4} className="labels">{window.I18n.t('multi_widgets.block_form.no_columns')}</Col>
            <Col xs={6} className="form-group no-margin-bottom">
              <select name="columnsCount" className="form-control" onChange={ e => this.setLayout(e.target.value) }>
                <option value="1">{window.I18n.t('multi_widgets.block_form.1_column')}</option>
                <option value="2">{window.I18n.t('multi_widgets.block_form.2_column')}</option>
              </select>
            </Col>
          </Col>
          <Col xs={10} xsOffset={1} className={ `form-row ${this.state.layout !== '1' ? '' : 'hidden'}` }>
            <Col xs={4} className="labels">{window.I18n.t('multi_widgets.block_form.column_split')}</Col>
            <Col xs={6}>
              <Col xs={4}>
                <label onChange={ e => this.setLayout(e.target.value) } >
                  <input type="radio" name="layout" value="2" hidden defaultChecked />
                  <div className={ `layout-symbols equal ${this.state.layout !== '2' ? 'passive' : 'active'}` }></div>
                  <div className={ `layout-symbols equal ${this.state.layout !== '2' ? 'passive' : 'active'}` }></div>
                </label>
              </Col>

              <Col xs={4}>
                <label onChange={ e => this.setLayout(e.target.value) }>
                  <input type="radio" name="layout" value="3" hidden />
                  <div className={ `layout-symbols one-third ${this.state.layout !== '3' ? 'passive' : 'active'}` }></div>
                  <div className={ `layout-symbols two-thirds ${this.state.layout !== '3' ? 'passive' : 'active'}` }></div>
                </label>
              </Col>

              <Col xs={4}>
                <label onChange={ e => this.setLayout(e.target.value) }>
                  <input type="radio" name="layout" value="4" hidden />
                  <div className={ `layout-symbols two-thirds ${this.state.layout !== '4' ? 'passive' : 'active'}` }></div>
                  <div className={ `layout-symbols one-third ${this.state.layout !== '4' ? 'passive' : 'active'}` }></div>
                </label>
              </Col>
            </Col>
          </Col>
        </Col>
        <Col xs={10} xsOffset={1} className="button-row">
          <button className="button submit save-page save-with-external-link btn btn-primary pull-right"
                  onClick={ e => { e.preventDefault(); this.handleSubmit() } } >
                  {window.I18n.t('multi_widgets.save')}
          </button>
          <a className="button cancel grey btn btn-default pull-right margin-right-10"
             onClick={ e => { e.preventDefault(); this.props.cancel() } } >
            {window.I18n.t('multi_widgets.cancel')}
          </a>
        </Col>
      </Col>
    )
  }
}

BlockForm.propTypes = {
  activeTab: PropTypes.number,
  colCount: PropTypes.string,
  setColumnCount: PropTypes.func,
  createBlock: PropTypes.func,
  cancel: PropTypes.func,
  usesTranslations: PropTypes.bool,
  availableLanguages: PropTypes.any,
  currentLanguage: PropTypes.any,
}

export default BlockForm
