import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'
import _ from 'lodash'
import { Responsive, WidthProvider } from 'react-grid-layout'
import { GlgSwitch, GlgButton, GlgPopup } from 'SharedComponents'
import LeaderboardSlidePopupComponent from './slide_popups/leaderboard_slide_popup_component'
import AdvancedSlidePopupComponent from './slide_popups/advanced_slide_popup_component'
import PhotosSlidePopupComponent from './slide_popups/photos_slide_popup_component'
import VideoSlidePopupComponent from './slide_popups/video_slide_popup_component'
import TextSlidePopupComponent from './slide_popups/text_slide_popup_component'
import { LEADERBOARD_TYPE, ADVANCED_TYPE, PHOTOS_TYPE, VIDEO_TYPE, TEXT_TYPE, LG_SLIDES_COL_COUNT, MD_SLIDES_COL_COUNT, SM_SLIDES_COL_COUNT, XS_SLIDES_COL_COUNT, XXS_SLIDES_COL_COUNT } from '../constants'
import { gridItemCompare } from '../helpers'

const ResponsiveGridLayout = WidthProvider(Responsive)
class SlidesSectionComponent extends Component {

  constructor(props) {
    super(props)

    this.state = {
      editOpenFor: undefined,
      deleteOpenFor: undefined,
      deletingIds: [],
    }

    props.computeSlidesLayout(LG_SLIDES_COL_COUNT)

    this.removeSlide = this.removeSlide.bind(this)
    this.handleDropdown = this.handleDropdown.bind(this)
    this.fetchSlideSettings = this.fetchSlideSettings.bind(this)
    this.buildSlidesToRender = this.buildSlidesToRender.bind(this)
    this.toggleVisibilityFor = this.toggleVisibilityFor.bind(this)
    this.hasSlidesOrderChanged = this.hasSlidesOrderChanged.bind(this)
    this.buildEditDropdownItems = this.buildEditDropdownItems.bind(this)
    this.buildDeleteDropdownItems = this.buildDeleteDropdownItems.bind(this)
  }

  componentDidUpdate(prevProps) {
    this.hasSlidesOrderChanged(prevProps.tvShowSlides, this.props.tvShowSlides)
  }

  hasSlidesOrderChanged(prevSlides, currentSlides) {
    if (prevSlides.length !== currentSlides.length) {
      // adding/deleting slides is already dealt with separately
      return
    }

    for ( let i = 0; i < prevSlides.length; i++ ) {
      if ( prevSlides[i].position !== currentSlides.find( slide => slide.id === prevSlides[i].id ).position ) {
        this.props.markAsDirty()
        break
      }
    }
  }

  fetchSlideSettings(slideId) {
    $.ajax({
      url: this.props.paths.getSlideSettings,
      type: 'POST',
      data: {
        slideId,
      },
      success: (response) => {
        this.props.editExistingSlide(response)
      },
      error: () => {
      },
    })
  }

  removeSlide(slideId) {
    this.setState({ deletingIds: [ slideId, ...this.state.deletingIds ] })
    $.ajax({
      url: this.props.paths.deleteSlide,
      type: 'DELETE',
      data: {
        slideId,
      },
      success: (response) => {
        this.props.genericSlideDispatchers.removeSlide(response.id)
      },
      error: () => {
      },
    })
  }

  toggleVisibilityFor(slide) {
    this.props.genericSlideDispatchers.updateSlide({
      id: slide.id,
      visible: !slide.visible,
    })

    $.ajax({
      url: this.props.paths.toggleSlideVisibility,
      type: 'POST',
      data: {
        slideId: slide.id,
      },
      error: () => {
        // revert toggle in UI
        this.props.genericSlideDispatchers.updateSlide({
          id: slide.id,
          visible: slide.visible,
        })
      },
    })
  }

  handleDropdown(slideId, editOrDelete) {
    if (slideId !== this.state[editOrDelete]) {
      this.setState({ [editOrDelete]: slideId })
    }
  }

  buildEditDropdownItems(advancedSlide) {
    const containedSlidesIds = Object.values(advancedSlide.settings.containedSlidesById)
                                    .filter( gridItem => gridItem.slideId )
                                    .sort(gridItemCompare)
                                    .map( gridItem => gridItem.slideId )

    const dropdownItems = this.props.tvShowSlides.filter( slide => containedSlidesIds.includes(slide.id) )
                                                  .map( slide => {
                                                    return {
                                                      id: slide.id,
                                                      text: slide.description,
                                                      onClick: () => { this.fetchSlideSettings(slide.id) },
                                                    }
                                                  })

    dropdownItems.push({
      id: advancedSlide.id,
      text: !window.I18n ? '' : window.I18n.t('tv_shows.components.slides_section_component.this_split_screen_slide'),
      onClick: () => { this.fetchSlideSettings(advancedSlide.id) },
    })

    return dropdownItems
  }

  buildDeleteDropdownItems(advancedSlide) {
    return [
      {
        id: 0,
        text: !window.I18n ? '' : window.I18n.t('tv_shows.components.slides_section_component.delete_slide'),
        onClick: () => { this.removeSlide(advancedSlide.id) },
      },
      {
        id: 1,
        text: !window.I18n ? '' : window.I18n.t('tv_shows.components.slides_section_component.delete_slide_and_content'),
        onClick: () => {
          [ ...Object.keys(advancedSlide.settings.containedSlidesById), advancedSlide.id ].forEach( slideId => {
            this.removeSlide(slideId)
          })
        },
      },
    ]
  }

  buildSlidesToRender() {
    const { tvShowSlides } = this.props

    const slidesIdsInAdvanced = tvShowSlides.filter( slide => slide.type === 'advanced' )
                                            .map( slide => Object.keys(slide.settings.containedSlidesById) )
                                            .flat()
                                            // uniq
                                            .filter( (slide, index, self) => self.indexOf(slide) === index )
    return tvShowSlides.filter( slide => !slidesIdsInAdvanced.includes(slide.id) )
  }

  render(){
    const {
      slidePopup,
      tvShowSlides,
      currentColsCount,
      slidesLayout,
      setCurrentColsCount,
      computeSlidesLayout,
      setSlidesPositions,
    } = this.props

    const slidesToRender = this.buildSlidesToRender()

    return <Fragment><Row>{ slidesToRender.length > 0 ? <ResponsiveGridLayout layouts={{ lg: slidesLayout }} breakpoints={{ lg: 972, md: 768, sm: 540, xs: 252, xxs: 0}} cols={{
                lg: LG_SLIDES_COL_COUNT,
                md: MD_SLIDES_COL_COUNT,
                sm: SM_SLIDES_COL_COUNT,
                xs: XS_SLIDES_COL_COUNT,
                xxs: XXS_SLIDES_COL_COUNT,
              }} rowHeight={ 130 } compactType={ 'horizontal' } isBounded={ true } margin={ [ 0, 30 ] } containerPadding={[ 0, 0 ]} isResizable={ false } draggableHandle={ '.drag-handle' } onLayoutChange={ (currentLayout) => {
                setSlidesPositions( currentLayout )
                computeSlidesLayout( currentColsCount )
              } } onBreakpointChange={ (newBrP, newCols) => {
                setCurrentColsCount(newCols)
                computeSlidesLayout(newCols)
              } }>{ slidesToRender.map((slide) => {
                return <Col xs={ 6 } sm={ 4 } lg={ 2 } key={ slide.id } style={{ zIndex: slidesToRender.length - slide.position }} className="slide-wrapper no-padding-left"><div className="slide-type">{ slide.type === 'advanced' ? 'split screen' : slide.type }</div><div className="slide-card"><i className="fa fa-arrows-alt drag-handle"></i><div className="slide-description">{ slide.description }</div><div className="slide-edit">{ slide.type === 'advanced' ? <Fragment><a onClick={ () => { this.handleDropdown(slide.id, 'editOpenFor') } } className="cursor-pointer">{ !window.I18n ? '' : window.I18n.t('buttons.edit') }<span className={`edit-caret ${this.state.editOpenFor === slide.id ? 'up' : ''}`}>&#9662;</span></a><DropdownComponent openFor={ this.state.editOpenFor } slide={ slide } handleDropdown={ (slideId) => { this.handleDropdown( slideId, 'editOpenFor' ) } } dropdownItems={ this.buildEditDropdownItems(slide) }></DropdownComponent></Fragment> : <a onClick={ () => { this.fetchSlideSettings(slide.id) } } className="cursor-pointer">{ !window.I18n ? '' : window.I18n.t('buttons.edit') }</a>}</div><div className="slide-actions"><div className="slide-delete"><i className="fa fa-trash visibility_hidden_important">{ slide.type === 'advanced' && <span className="delete-caret">&#9662;</span> }</i></div><div className="slide-switch"><GlgSwitch id={`slide_${slide.id}`} text={[ (!window.I18n ? '' : window.I18n.t('tv_shows.components.slides_section_component.visible')),
                                              (!window.I18n ? '' : window.I18n.t('tv_shows.components.slides_section_component.hidden')) ]} widthInPixels={ 67 } heightInPixels={ 20 } fontSize={ '12px' } checked={ slide.visible } onChange={ () => { this.toggleVisibilityFor(slide) } }></GlgSwitch></div>{ slide.type === 'advanced' ? <div className="slide-delete"><button onClick={ () => { this.handleDropdown(slide.id, 'deleteOpenFor') } } className="slide-delete"><i className="fa fa-trash"></i><span className={ 'delete-caret' + this.state.deleteOpenFor === slide.id ? 'up' : ''}>  &#9662;</span></button><DropdownComponent openFor={ this.state.deleteOpenFor } slide={ slide } handleDropdown={ (slideId) => { this.handleDropdown( slideId, 'deleteOpenFor' ) } } dropdownItems={ this.buildDeleteDropdownItems(slide) }></DropdownComponent></div> : (this.state.deletingIds.includes(slide.id) ? <button className="slide-delete"><i className="fa fa-spinner fa-spin"></i></button> : <button onClick={ () => { this.removeSlide(slide.id) } } className="slide-delete"><i className="fa fa-trash"></i></button>)
                        }</div></div></Col>
            })}</ResponsiveGridLayout> : <Col xs={ 12 } className="no-slides no-padding-left">{ !window.I18n ? '' : window.I18n.t('tv_shows.components.slides_section_component.no_slides_defined') }</Col>
          }</Row><Row className="add-margin-top-40"><GlgButton text={ !window.I18n ? '' : window.I18n.t('tv_shows.components.slides_section_component.add_new_leaderboard') } color={ 'white' } icon={ 'plus-circle' } onClick={ () => this.props.selectNewSlide(LEADERBOARD_TYPE) }></GlgButton><GlgButton text={ 'Add Photos Slide' } color={ 'white' } icon={ 'plus-circle' } onClick={ () => this.props.selectNewSlide(PHOTOS_TYPE) } className="add-margin-left-20"></GlgButton><GlgButton text={ 'Add Video Slide' } color={ 'white' } icon={ 'plus-circle' } onClick={ () => this.props.selectNewSlide(VIDEO_TYPE) } className="add-margin-left-20"></GlgButton><GlgButton text={ 'Add Text Slide' } color={ 'white' } icon={ 'plus-circle' } onClick={ () => this.props.selectNewSlide(TEXT_TYPE) } className="add-margin-left-20"></GlgButton></Row><Row className="add-margin-top-20"><GlgButton text={ !window.I18n ? '' : window.I18n.t('tv_shows.components.slides_section_component.configure_split_screen') } color={ 'white' } icon={ 'columns' } onClick={ () => this.props.selectNewSlide(ADVANCED_TYPE) }></GlgButton></Row><AddNewSlidePopupComponent show={ slidePopup.showSlidePopup } onClose={ () => this.props.closePopup('showSlidePopup') } selected={ slidePopup.configuration.type } selectNewSlide={ this.props.selectNewSlide }></AddNewSlidePopupComponent>{<LeaderboardSlidePopupComponent
          paths={ this.props.paths }
          show={ this.props.slidePopup.configuration.type === LEADERBOARD_TYPE }
          currentRoundId={ this.props.currentRoundId }
          slidesCount={ tvShowSlides.length }
          setTournamentsData={ this.props.setNewSlideDataSources }
          onClose={ () => this.props.selectNewSlide('none') }
          { ...this.props.slidePopup.dataSources }
          { ...this.props.slidePopup.configuration }
          { ...this.props.sourceData }
          { ...this.props.genericSlideDispatchers }
          { ...this.props.leaderboardSlideDispatchers }
        />}{<AdvancedSlidePopupComponent
          paths={ this.props.paths }
          show={ this.props.slidePopup.configuration.type === ADVANCED_TYPE }
          slides={ this.props.tvShowSlides.filter( slide => slide.type !== ADVANCED_TYPE ) }
          onClose={ () => { this.props.selectNewSlide('none') } }
          addNewSlide={ this.props.selectNewSlide }
          setSlidePopupConfiguration={ this.props.setSlidePopupConfiguration }
          toggleVisibilityFor={ this.toggleVisibilityFor }
          { ...this.props.slidePopup.configuration }
          { ...this.props.genericSlideDispatchers }
        />}{<PhotosSlidePopupComponent
          paths={ this.props.paths }
          show={ this.props.slidePopup.configuration.type === PHOTOS_TYPE }
          currentRoundId={ this.props.currentRoundId }
          slidesCount={ tvShowSlides.length }
          setTournamentsData={ this.props.setNewSlideDataSources }
          backgroundOpacity={ 100 }
          onClose={ () => this.props.selectNewSlide('none') }
          { ...this.props.slidePopup.dataSources }
          { ...this.props.slidePopup.configuration }
          { ...this.props.sourceData }
          { ...this.props.genericSlideDispatchers }
          { ...this.props.leaderboardSlideDispatchers }
        />}{<VideoSlidePopupComponent
          paths={ this.props.paths }
          show={ this.props.slidePopup.configuration.type === VIDEO_TYPE }
          backgroundOpacity={ 100 }
          onClose={ () => this.props.selectNewSlide('none') }
          { ...this.props.slidePopup.dataSources }
          { ...this.props.slidePopup.configuration }
          { ...this.props.sourceData }
          { ...this.props.genericSlideDispatchers }
        />}{<TextSlidePopupComponent
          paths={ this.props.paths }
          show={ this.props.slidePopup.configuration.type === TEXT_TYPE }
          backgroundOpacity={ 100 }
          onClose={ () => this.props.selectNewSlide('none') }
          { ...this.props.extra }
          { ...this.props.slidePopup.dataSources }
          { ...this.props.slidePopup.configuration }
          { ...this.props.sourceData }
          { ...this.props.genericSlideDispatchers }
          { ...this.props.leaderboardSlideDispatchers }
        />}</Fragment>
  }
}

class DropdownComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      waitForAnimation: false,
    }

    this.selfRef = React.createRef()

    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
    this._isMounted = true
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
    this._isMounted = false
  }

  handleClickOutside(event) {
    if ( this._isMounted && !_.get(this, `selfRef.current.contains(${event.target})`, false) && this.props.openFor ) {
      this.props.handleDropdown(undefined)
    }
  }

  componentDidUpdate() {
    if (this.props.openFor !== this.props.slide.id && this.state.waitForAnimation) {
      setTimeout(() => { this._isMounted && this.setState({ waitForAnimation: false }) }, 250)
    } else if (this.props.openFor === this.props.slide.id && !this.state.waitForAnimation) {
      this._isMounted && this.setState({ waitForAnimation: true })
    }
  }

  render() {
    const {
      openFor,
      slide,
      dropdownItems,
    } = this.props

    const {
      waitForAnimation,
    } = this.state

    const closeAnimationClass = (waitForAnimation && (openFor !== slide.id)) ? 'up' : ''

    return (openFor === slide.id || waitForAnimation) && <ul className={`${ closeAnimationClass }`} ref={ this.selfRef }>{ dropdownItems.map( item => <li key={ item.id } onMouseDown={ item.onClick }>{ item.text }</li>)}</ul>
  }
}

class AddNewSlidePopupComponent extends Component {
  constructor(props) {
    super(props)

    this.slideTypes = [ LEADERBOARD_TYPE, PHOTOS_TYPE, VIDEO_TYPE, TEXT_TYPE, ADVANCED_TYPE /*'tee sheet', 'collage', */ ]
  }

  render() {
    const {
      selected,
      selectNewSlide,
    } = this.props

    return <GlgPopup showSaveButton={false} showCloseButton={false} title={ !window.I18n ? '' : window.I18n.t('tv_shows.components.slides_section_component.add_new_slide') } show={ this.props.show } onClose={ this.props.onClose }><Row className="justify-center">{ this.slideTypes.map( (slideType, index) => <Col xs={ 6 } sm={ 3 } key={ index }><div className={ `slide-type-card ${ selected === slideType ? 'selected' : '' }` } onClick={ () => {
                  selectNewSlide(slideType)
                  this.props.onClose()
                }}>{ slideType }</div></Col>) }</Row></GlgPopup>
  }
}

SlidesSectionComponent.propTypes = {
  currentRoundId: PropTypes.string.isRequired,
  paths: PropTypes.object.isRequired,
  extra: PropTypes.object.isRequired,
  slidePopup: PropTypes.object.isRequired,
  sourceData: PropTypes.object.isRequired,
  genericSlideDispatchers: PropTypes.object.isRequired,
  leaderboardSlideDispatchers: PropTypes.object.isRequired,
  tvShowSlides: PropTypes.array.isRequired,
  currentColsCount: PropTypes.number.isRequired,
  slidesLayout: PropTypes.array.isRequired,
  markAsDirty: PropTypes.func.isRequired,
  openPopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
  selectNewSlide: PropTypes.func.isRequired,
  editExistingSlide: PropTypes.func.isRequired,
  setSlidePopupConfiguration: PropTypes.func.isRequired,
  setNewSlideDataSources: PropTypes.func.isRequired,
  setCurrentColsCount: PropTypes.func.isRequired,
  computeSlidesLayout: PropTypes.func.isRequired,
  setSlidesPositions: PropTypes.func.isRequired,
}

DropdownComponent.propTypes = {
  openFor: PropTypes.string,
  slide: PropTypes.object.isRequired,
  handleDropdown: PropTypes.func.isRequired,
  dropdownItems: PropTypes.array.isRequired,
}

AddNewSlidePopupComponent.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
  selectNewSlide: PropTypes.func.isRequired,
}

export default SlidesSectionComponent
