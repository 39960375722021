import { createSelector } from 'reselect'

const getCourse = state => state.filters.selectedCourse
const getFoursomes = state => state.groupViewData.foursomes

const getPairingsFilteredByCourse = createSelector(
  [ getCourse, getFoursomes ],
  ( course, foursomes) => {
    if (course === 'all') {
      return foursomes
    } else {
      return foursomes.filter( foursome => foursome.courseId === course)
    }
  }
)

export default getPairingsFilteredByCourse
