import { createSelector } from 'reselect'

const getPlayers = state => state.misc.players

const getPlayersOnFrontSide = createSelector(
  [ getPlayers ],
  ( players ) => {
    return [ ...Array(10) ].map( (_, pos) => {
      const player = players.find( p => p.front9 === true && p.position === pos)
      return player === undefined ? { id: `${pos}`, empty: true, position: pos, front9: true } : { ...player, empty: false }
    })
  }
)

export default getPlayersOnFrontSide
