import { callAPI, showErrorNotification, showNotification } from 'Shared/helpers'
import { NotificationManager } from 'react-notifications'

//* REDUX - Action Types
export const ActionTypes = {
  TOGGLE_MENU: 'TOGGLE_MENU',
  SELECT_MENU_ITEM: 'SELECT_MENU_ITEM',
  TOGGLE_NEW_ITEMS_MODAL: 'TOGGLE_NEW_ITEMS_MODAL',
  GET_NEW_ITEMS_LINKS: 'GET_NEW_ITEMS_LINKS',
  NEW_ITEMS_MODAL_SEARCH: 'NEW_ITEMS_MODAL_SEARCH',
  NEW_ITEMS_SELECT_ROUND: 'NEW_ITEMS_SELECT_ROUND',

  WIDGETS_DISPLAY: 'WIDGETS_DISPLAY',
  WIDGETS_LOAD_ON_SUCCESS: 'WIDGETS_LOAD_ON_SUCCESS',
  WIDGETS_LOAD_CONTENT: 'WIDGETS_LOAD_CONTENT',

  SELECT_TOURNAMENT_TOGGLE_MODAL: 'SELECT_TOURNAMENT_TOGGLE_MODAL',
  SET_SELECTED_DEFAULT_ROUND_TOURNAMENT: 'SET_SELECTED_DEFAULT_ROUND_TOURNAMENT',
  WALKING_SCORER_STATION_TOGGLE_MODAL: 'WALKING_SCORER_STATION_TOGGLE_MODAL',
  LOAD_ROUND_TOURNAMENTS: 'LOAD_ROUND_TOURNAMENTS',
  SELECT_EMAILS_OR_TEXTS: 'SELECT_EMAILS_OR_TEXTS',
  SELECT_ROUND_TOURNAMENT: 'SELECT_ROUND_TOURNAMENT',
  GET_ROUND_TOURNAMENT_DATA: 'GET_ROUND_TOURNAMENT_DATA',
  SET_ROUND_STATUS: 'SET_ROUND_STATUS',
  CHECK_HOST_VENUE_STATUS: 'CHECK_HOST_VENUE_STATUS',
  TOGGLE_ROUND_OPTIONS: 'TOGGLE_ROUND_OPTIONS',
  GET_AUDITS_STATUS: 'GET_AUDITS_STATUS',
  GET_AUDIT_ERROR_MESSAGES: 'GET_AUDIT_ERROR_MESSAGES',
  REPORT_PRINT_ID: 'REPORT_PRINT_ID',
  REPORT_GENERATE_DOC: 'REPORT_GENERATE_DOC',
  REPORT_GENERATE_TXT: 'REPORT_GENERATE_TXT',
  REPORT_SET_STATE: 'REPORT_SET_STATE',
  REPORT_SELECT_LIST: 'REPORT_SELECT_LIST',
  REPORT_SEARCH: 'REPORT_SEARCH',
  REPORT_GET_LIST_CONTENT: 'REPORT_GET_LIST_CONTENT',
  REPORT_IMPORT: 'REPORT_IMPORT',
  REPORT_POLL_JOB_STATUS: 'REPORT_POLL_JOB_STATUS',
  REPORT_PRINT_FINISHED: 'REPORT_PRINT_FINISHED',
  REPORT_DOC_CONVERT_FINISHED: 'REPORT_DOC_CONVERT_FINISHED',
  REPORT_TXT_CONVERT_FINISHED: 'REPORT_TXT_CONVERT_FINISHED',
  REPORT_TOGGLE_PREVIEW_MODAL: 'REPORT_TOGGLE_PREVIEW_MODAL',
  TOGGLE_SCORING_LINKS: 'TOGGLE_SCORING_LINKS',

  HANDICAP_ANALYSIS_SHOW_HIDDEN_DETAILS: 'HANDICAP_ANALYSIS_SHOW_HIDDEN_DETAILS',
  HANDICAP_ANALYSIS_SEARCH: 'HANDICAP_ANALYSIS_SEARCH',
  TOGGLE_ADJUST_HANDICAP_MODAL: 'TOGGLE_ADJUST_HANDICAP_MODAL',
  SET_ADJUST_HANDICAP_FORM_DATA: 'SET_ADJUST_HANDICAP_FORM_DATA',

  FLASH_MESSAGE_CHANGE_DATA: 'FLASH_MESSAGE_CHANGE_DATA',
  FLASH_MESSAGE_SET_MODAL_VISIBILITY: 'FLASH_MESSAGE_SET_MODAL_VISIBILITY',

  GGID_CHANGE: 'GGID_CHANGE',
  GGID_IS_VALID: 'GGID_IS_VALID',
  GGID_TOGGLE_EDIT: 'GGID_TOGGLE_EDIT',
  GGID_UPDATE: 'GGID_UPDATE',

  TOGGLE_DIVISION_ACTIONS: 'TOGGLE_DIVISION_ACTIONS',

  SET_GENERIC_DATA: 'SET_GENERIC_DATA',
}

// Menu
// ----

//* REDUX - Action Methods
export const toggleMenu = () => (dispatch) => {
  dispatch({
    type: ActionTypes.TOGGLE_MENU,
  })
}

export const selectMenuItem = (id, url) => (dispatch) => {
  callAPI(url, 'GET', {}, new Headers({ 'Accept': 'application/json' }))
    .then(() => {
      dispatch({
        type: ActionTypes.SELECT_MENU_ITEM,
        id: id,
      })
    })
}

export const toggleNewItemsModal = (visible) => (dispatch) => {
  dispatch({
    type: ActionTypes.TOGGLE_NEW_ITEMS_MODAL,
    visible: visible,
  })
}

export const newItemsSearch = (searchTerm) => (dispatch) => {
  dispatch({
    type: ActionTypes.NEW_ITEMS_MODAL_SEARCH,
    searchTerm: searchTerm,
  })
}

export const getNewItemsLinks = (path, forceUpdate = false) => (dispatch, getState) => {
  const state = getState()

  if (state.menu.menuItems.newItems.leagueLinks.length === 0 || forceUpdate) {
    callAPI(path) //* REACT - callAPi example
      .then(data => {
        dispatch({
          type: ActionTypes.GET_NEW_ITEMS_LINKS,
          data: data,
        })
      })
      .catch(() => {
        console.error('Error while fetching new items links!')
      })
  }
}

export const newItemsSelectRound = (selectedRound) => (dispatch) => {
  dispatch({
    type: ActionTypes.NEW_ITEMS_SELECT_ROUND,
    selectedRound: selectedRound,
  })
}

// Miscellanious
// -------------
export const copyText = (textToCopy) => {
  const textField = document.createElement('textarea')
  textField.innerText = textToCopy
  document.body.appendChild(textField)
  textField.select()
  document.execCommand('copy')
  textField.remove()
  showNotification(!window.I18n ? '' : window.I18n.t('dashboard_menu.actions.index.copied'))
}

// Widgets
// -------
export const displayWidgets = (roundId, leagueId) => (dispatch) => {
  dispatch({
    type: ActionTypes.WIDGETS_DISPLAY,
    roundId: roundId,
    leagueId: leagueId,
  })
}

export const loadWidgets = (path, id) => (dispatch, getState) => {
  const state = getState()

  if (state.widgets[id].length === 0) {
    fetch(path, { method: 'GET', credentials: 'same-origin' })
      .then(response => response.json())
      .then(data => {
        if (getState().widgets[id].length === 0) {
          dispatch({
            type: ActionTypes.WIDGETS_LOAD_ON_SUCCESS,
            data: data,
          })
        }
      })
      .catch(() => {
        console.error('Error while fetching widgets!')
      })
  }
}

export const loadWidgetContent = (path, id) => (dispatch) => {
  if (path !== '#') {
    fetch(path, { method: 'GET', credentials: 'same-origin' })
    .then(response => response.json())
    .then(data => {
      dispatch({
        type: ActionTypes.WIDGETS_LOAD_CONTENT,
        id: id,
        data: data,
      })
    })
    .catch(() => {
      console.error('Error while fetching widgets content!')
    })
  }
}

export const refreshCurrentWidgets = (widgets, developmentEnvironment) => (dispatch) => {
  if (!developmentEnvironment) {
    const exemptWidgets = [
      'reports',
      'leaderboard',
      'event_setup_divisions',
      'event_setup_flights',
      'event_setup_tee_sheet',
      'event_setup_import_golfers',
      'event_setup_registration',
    ]

    widgets.map(widget => exemptWidgets.includes(widget.content_type) ? null : dispatch(loadWidgetContent(widget.data_path, widget.id)))
  }
}

export const selectEmailsOrTexts = (id, selected) => (dispatch) => {
  dispatch({
    type: ActionTypes.SELECT_EMAILS_OR_TEXTS,
    id: id,
    selected: selected,
  })
}

export const selectRoundTournament = (id, selected) => (dispatch) => {
  dispatch({
    type: ActionTypes.SELECT_ROUND_TOURNAMENT,
    id: id,
    selected: selected,
  })
}

export const loadRoundTournaments = (path, id) => (dispatch) => {
  callAPI(path)
    .then(data => {
      if (data.tournaments.length > 1) {
        dispatch({
          type: ActionTypes.LOAD_ROUND_TOURNAMENTS,
          id: id,
          tournaments: data.tournaments,
        })

        dispatch(selectTournamentToggleModal(id, true))
      }
    })
    .catch(() => {
      console.error('Error while fetching round tournaments content!')
    })
}

// Check if th torunament data is loaded if not get it from the server
export const getRoundTournamentData = (id, path, selected, hasContent) => (dispatch) => {
  if (!hasContent) {
    callAPI(path, 'GET', { tournament_id: selected }, new Headers({ 'Accept': 'application/json' }))
      .then((response) => {
        if (response !== undefined && response !== null) {
          const value = Object.entries(response)[0][1]

          dispatch({
            type: ActionTypes.GET_ROUND_TOURNAMENT_DATA,
            id: id,
            selected: selected,
            tableData: value.table_data,
            tableContent: value.table_content,
          })
        }
      })
      .catch(() => {
        showErrorNotification(!window.I18n ? '' : window.I18n.t('dashboard_menu.actions.index.error_retrieving_tournament_data'))
      })
  }
}

export const selectTournamentToggleModal = (id, visible) => (dispatch) => {
  dispatch({
    type: ActionTypes.SELECT_TOURNAMENT_TOGGLE_MODAL,
    id: id,
    visible: visible,
  })
}

export const setSelectedDefaultRoundTournament = (id, selected) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_SELECTED_DEFAULT_ROUND_TOURNAMENT,
    id: id,
    selected: selected,
  })
}

export const setDefaultRoundTournament = (id, path, tournamentId) => (dispatch) => {
  callAPI(path, 'POST', { default_tournament_id: tournamentId }, new Headers({ 'Accept': 'application/json' }))
    .then(() => {
      dispatch(selectTournamentToggleModal(id, false))
    })
    .catch(() => {
      showErrorNotification(!window.I18n ? '' : window.I18n.t('dashboard_menu.actions.index.error_setting_default_tournament'))
    })
}

export const walkingScorerStationToggleModal = (id, visible) => (dispatch) => {
  dispatch({
    type: ActionTypes.WALKING_SCORER_STATION_TOGGLE_MODAL,
    id: id,
    visible: visible,
  })
}

export const saveWalkingScorerStationSettings = (id, path, newSettings) => (dispatch) => {
  const newSettingsRubyHash = { extra_holes: newSettings['extraHoles'] }
  if (newSettings['editAfterVerification'] === true) {
    newSettingsRubyHash['edit_after_verification'] = 'checked'
  }
  if (newSettings['fillMissingScores'] === true) {
    newSettingsRubyHash['fill_missing_scores'] = 'checked'
  }
  callAPI(path, 'POST', newSettingsRubyHash, new Headers({ 'Accept': 'application/json' }))
    .then(() => {
      dispatch(walkingScorerStationToggleModal(id, false))
    })
    .catch(() => {
      showErrorNotification('Error saving the walking scorer station settings!')
    })
}

export const setRoundStatus = (id, url, status, roundId) => (dispatch) => {
  callAPI(url, 'GET', {}, new Headers({ 'Accept': 'application/json' }))
    .then((response) => {
      dispatch({
        type: ActionTypes.SET_ROUND_STATUS,
        id: id,
        status: status,
      })

      // Make the changes reflect in the menu
      // ------------------------------------
      // eslint-disable-next-line newline-per-chained-call
      if (roundId === $('#round_panel_selector').find('option:selected').val()) { // Current round in menu
        $('a.tournament-state').removeClass('active')
        status === 'not started' ? $('a.not-started').addClass('active') : null
        status === 'in progress' ? $('a.in-progress').addClass('active') : null
        status === 'completed' ? $('a.completed').addClass('active') : null
      }

      if (status === 'completed' && response.hasOwnProperty('redirect_path')) {
        window.location.replace(response.redirect_path)
      }
    })
    .catch(() => {
      showErrorNotification(!window.I18n ? '' : window.I18n.t('dashboard_menu.actions.index.error_changing_round_status'))
    })
}

export const checkHostVenueStatus = (id, url) => (dispatch) => {
  callAPI(url, 'GET', {}, new Headers({ 'Accept': 'application/json' }))
    .then((response) => {
      dispatch({
        type: ActionTypes.CHECK_HOST_VENUE_STATUS,
        id: id,
        data: response,
      })
    })
    .catch(() => {
      showErrorNotification(!window.I18n ? '' : window.I18n.t('dashboard_menu.actions.index.error_checking_host_venue_status'))
    })
}

export const toggleRoundOptions = (id, url, key, roundId) => (dispatch, getState) => {

  // Enable digital scorecards
  if (key === 'enable_digital_scorecards' && $('#toggle_dashboard_digital_scorecards').hasClass('btn-default')) {
    if ($('#premium_customer_and_active_popup').val() === 'true') {
      $('#digital_scorecards_stations').modal('show')
    } else {
      $('#go_digital_scorecards_page_link')[0].click()
    }
  } else {
    callAPI(url, 'GET', {}, new Headers({ 'Accept': 'application/json' }))
      .then(() => {
        dispatch({
          type: ActionTypes.TOGGLE_ROUND_OPTIONS,
          id: id,
          key: key,
        })

        // Enable mobile score entry
        if (key === 'enable_mobile_score_entry') {
          const state = getState()
          const content = state.widgets[roundId].find(widget => widget.id === id).content
          if (content.roundOptions[key].status === true && content.selectTournamentModal.enabled) {
            dispatch(loadRoundTournaments(content.selectTournamentModal.getTournamentsPath, id))
          }
        }

        // Enable walking scorer
        if (key === 'enable_walking_scorer') {
          const state = getState()
          const content = state.widgets[roundId].find(widget => widget.id === id).content
          if (content.roundOptions[key].status === true && content.walkingScorerStationModal.enabled) {
            dispatch(walkingScorerStationToggleModal(id, true))
          }
        }

        if (key === 'enable_shot_trackings') {
          dispatch({
            type: ActionTypes.TOGGLE_SCORING_LINKS,
            id: id,
            key: 'shot_by_shot_view',
          })
        }

        // Make the changes reflect in the menu
        // ------------------------------------
        // eslint-disable-next-line newline-per-chained-call
        if (roundId === $('#round_panel_selector').find('option:selected').val()) { // Current round in menu
          // Show Pairing sin event portal
          if (key === 'show_parings_in_event_portal') {
            const checkbox = $('#show_teesheet_member_portal')
            checkbox.bootstrapToggle('destroy')
            checkbox.prop('checked', !checkbox.prop('checked'))
            checkbox.bootstrapToggle({
              on: !window.I18n ? '' : window.I18n.t('dashboard_menu.actions.index.affirmative'),
              off: !window.I18n ? '' : window.I18n.t('dashboard_menu.actions.index.negative'),
            })
          }
          // Enable digital scorecards
          if (key === 'enable_digital_scorecards') {
            const checkbox = $('#enable_digital_scorecards')
            checkbox.bootstrapToggle('destroy')
            checkbox.prop('checked', !checkbox.prop('checked'))
            if ($('#premium_customer_and_active_popup').val() === 'true' && $('#toggle_dashboard_digital_scorecards').hasClass('btn-default')) {
              $('#show_digital_scorecards_popup_link').removeClass('hide')
              $('#go_digital_scorecards_page_link').addClass('hide')
            }
            checkbox.bootstrapToggle({
              on: !window.I18n ? '' : window.I18n.t('dashboard_menu.actions.index.affirmative'),
              off: !window.I18n ? '' : window.I18n.t('dashboard_menu.actions.index.negative'),
            })
          }
          // Enable mobile score entry
          if (key === 'enable_mobile_score_entry') {
            const checkbox = $('#real_time_mode')
            checkbox.bootstrapToggle('destroy')
            checkbox.prop('checked', !checkbox.prop('checked'))
            checkbox.bootstrapToggle({
              on: !window.I18n ? '' : window.I18n.t('dashboard_menu.actions.index.affirmative'),
              off: !window.I18n ? '' : window.I18n.t('dashboard_menu.actions.index.negative'),
            })
          }
          // Enable walking scorer
          if (key === 'enable_walking_scorer') {
            const checkbox = $('#enable_walking_scorer')
            checkbox.bootstrapToggle('destroy')
            checkbox.prop('checked', !checkbox.prop('checked'))
            checkbox.bootstrapToggle({
              on: 'YES',
              off: 'NO',
            })
          }
          // Show result in event portal
          if (key === 'show_events_in_results_portal') { 
            const checkbox = $('#show_results_member_portal')
            checkbox.bootstrapToggle('destroy')
            checkbox.prop('checked', !checkbox.prop('checked'))
            checkbox.bootstrapToggle({
              on: !window.I18n ? '' : window.I18n.t('dashboard_menu.actions.index.affirmative'),
              off: !window.I18n ? '' : window.I18n.t('dashboard_menu.actions.index.negative'),
            })
          }
          //Enable shot trackings
          if (key === 'enable_shot_trackings') {
            const checkbox = $('#enable_shot_trackings')
            const link = $('.shot_trackings_view')
            link.prop('hidden', checkbox.prop('checked'))
            checkbox.bootstrapToggle('destroy')
            checkbox.prop('checked', !checkbox.prop('checked'))
            checkbox.bootstrapToggle({
              on: !window.I18n ? '' : window.I18n.t('dashboard_menu.actions.index.affirmative'),
              off: !window.I18n ? '' : window.I18n.t('dashboard_menu.actions.index.negative'),
            })
          }
          
          if (key === 'enable_double_score_verification') {
            const checkbox = $('#enable_double_score_verifications')
            const link = $('.double_score_verification_link')
            link.prop('hidden', checkbox.prop('checked'))
            checkbox.bootstrapToggle('destroy')
            checkbox.prop('checked', !checkbox.prop('checked'))
            checkbox.bootstrapToggle({
              on: 'YES',
              off: 'NO',
            })
          }
        }
      })
    .catch(() => {
      showErrorNotification(!window.I18n ? '' : window.I18n.t('dashboard_menu.actions.index.error_toggling_round_options'))
    })
  }
}

export const getAuditsStatus = (id, url) => (dispatch) => {
  callAPI(url)
    .then(response => {
      dispatch({
        type: ActionTypes.GET_AUDITS_STATUS,
        id: id,
        status: response,
      })
    })
    .catch((error) => {
      console.error(error)
      showErrorNotification(!window.I18n ? '' : window.I18n.t('dashboard_menu.actions.index.error_retrieving_audits_status'))
    })
}

export const getAuditErrorMessages = (id, url) => (dispatch) => {
  callAPI(url)
    .then(response => {
      dispatch({
        type: ActionTypes.GET_AUDIT_ERROR_MESSAGES,
        id: id,
        auditId: response.id,
        messages: response.message,
      })
    })
    .catch((error) => {
      console.error(error)
      showErrorNotification(!window.I18n ? '' : window.I18n.t('dashboard_menu.actions.index.error_retrieving_audit_error_messages'))
    })
}

export const reportSetState = (id, reportId, status) => (dispatch) => {
  dispatch({
    type: ActionTypes.REPORT_SET_STATE,
    id: id,
    reportId: reportId,
    status: status,
  })
}

export const reportsSelectList = (id, selected) => (dispatch) => {
  dispatch({
    type: ActionTypes.REPORT_SELECT_LIST,
    id: id,
    selected: selected,
  })
}

export const reportsSearch = (id, searchTerm) => (dispatch) => {
  dispatch({
    type: ActionTypes.REPORT_SEARCH,
    id: id,
    searchTerm: searchTerm,
  })
}

// Check if th reports are loaded for the selected report list if not get it from the server
export const reportGetListContent = (id, path, selected, noContent) => (dispatch) => {
  if (noContent) {
    callAPI(path, 'GET', { list_id: selected }, new Headers({ 'Accept': 'application/json' }))
      .then((response) => {
        if (response.reports.length > 0) {
          dispatch({
            type: ActionTypes.REPORT_GET_LIST_CONTENT,
            id: id,
            selected: selected,
            reports: response.reports,
          })
        }
      })
      .catch(() => {
        showErrorNotification(!window.I18n ? '' : window.I18n.t('dashboard_menu.actions.index.error_retrieving_report_center_items'))
      })
  }
}

export const reportAction = (id, url, reportId, type = 'url') => (dispatch) => {
  if (type === window.I18n.t('models.report_center_item.url').toLowerCase()) {
    window.open(url, '_target')
  }
  if (type === window.I18n.t('models.report_center_item.print').toLowerCase() || type === window.I18n.t('models.report_center_item.download').toLowerCase()) {
    dispatch(reportPrint(id, url, reportId))
  }
  if (type === window.I18n.t('models.report_center_item.email').toLowerCase()) {
    window.open(url, '_target')
  }
  // if (type === 'import') {
  //   console.error('Import!')
  // }
}

export const reportPrint = (id, url, reportId, method = 'GET', data = {}) => (dispatch) => {
  const urlWithParams = url.indexOf('?') < 0 ? `${url}?report_center_item_id=${reportId}` : `${url}&report_center_item_id=${reportId}`

  const callbacks = () => {
    const printModal = $('#print_report')
    // Override Cancel Button
    const onClick = (event) => {
      event.preventDefault()
      printModal.modal('hide')
    }
    printModal.find('a.btsp_gray_button, button.btsp_white_button, a.btsp_white_button')
              .on('click', onClick)

    // Bind custom handler to submit form through the corresponding printReport routine
    const submitCallback = (event) => {
      event.preventDefault()
      const form = $(event.target)
      const type = form.attr('method')
      const data = form.serialize()
      const url = form.attr('action')
      printModal.modal('hide')
      dispatch(reportPrint(id, url, reportId, type, data))
      console.error('Submit')
    }
    printModal.find('form')
              .submit(submitCallback)

    printModal.modal('show')
  }

  $.ajax(urlWithParams, {
    method: method,
    dataType: 'json',
    data: data,
    error: (error) => {
      // READ IMPORTANT!
      // Due to how report center was made this is how conflicts will be handled when printing
      // The response goes trough the rails asset pipeline and will trigger some js code
      // In normal ajax calls this triggers an error response so we need to
      if (error.status === 200 && error.responseText !== null) {
        openPrintModal(error.responseText, callbacks)
      } else {
        showErrorNotification(!window.I18n ? '' : window.I18n.t('dashboard_menu.actions.index.error_printing_report'))
      }
    },
    success: (response) => {
      console.error(response)
      if (response.job_id) {
        dispatch({
          type: ActionTypes.REPORT_PRINT_ID,
          id: id,
          reportId: reportId,
          jobId: response.job_id,
        })
      } else if (response.edit_conflicts_path) { //conflicts or another form rul response
        //tell modal to load form from edit_conflicts_path and submit throught printReport or cancel throught setJobId of this very rci
        // this.props.openPrintModal({ edit_conflicts_path: data.edit_conflicts_path, rci_base_printReport: this.printReport, rci_base_setJobId: this.setJobId})
        console.error('Open print options modal')
        openPrintModal(null, callbacks, response.edit_conflicts_path)
        // dispatch(openPrintModal({ edit_conflicts_path: response.edit_conflicts_path, rci_base_printReport: reportPrint(id, url, reportId), rci_base_setJobId: response.job_id}))
      } else if (response.window_redirect){
        //when printing a spreadsheet report a redirect to the edit report might be received as a response
        window.open(response.window_redirect, '_blank')
        console.error('redirect')
      }
    }})
}

export const reportGenerateDoc = (id, url, leagueId, reportId, reportName, pdfUrl, service) => (dispatch) => {
  $.ajax(url, {
    method: 'POST',
    dataType: 'json',
    data: {
      url: pdfUrl,
      name: reportName,
      primary_docx_service: service,
      league_id: leagueId,
    },
    error: () => {
      showErrorNotification(!window.I18n ? '' : window.I18n.t('dashboard_menu.actions.index.error_generating_doc'))
    },
    success: (response) => {
      console.error(response)
      dispatch({
        type: ActionTypes.REPORT_GENERATE_DOC,
        id: id,
        reportId: reportId,
        jobId: response.job_id,
      })
      // if (data.job_id){ //no conflicts
      //   this.setJobId({ id: data.job_id, error: false })
      // } else if (data.edit_conflicts_path) { //conflicts
      //   this.props.openPrintModal({ edit_conflicts_path: data.edit_conflicts_path, rci_base_setJobId: this.setJobId})
      // }
    },
  })
}

export const reportGenerateTxt = (id, url, leagueId, reportId, reportName, pdfUrl, generateTxt) => (dispatch) => {
  $.ajax(url, {
    method: 'POST',
    dataType: 'json',
    data: {
      url: pdfUrl,
      name: reportName,
      generateTxt: generateTxt,
      league_id: leagueId,
    },
    error: () => {
      showErrorNotification(!window.I18n ? '' : window.I18n.t('dashboard_menu.actions.index.error_generating_txt'))
    },
    success: (response) => {
      console.error(response)
      dispatch({
        type: ActionTypes.REPORT_GENERATE_TXT,
        id: id,
        reportId: reportId,
        jobId: response.job_id,
      })
    },
  })
}

export const reportImport = (id, url, reportId, action) => (dispatch) => {
  callAPI(url, 'PATCH')
    .then(response => {
      dispatch({
        type: ActionTypes.REPORT_IMPORT,
        id: id,
        reportId: reportId,
        rci: response.rci,
      })

      // Trigger initial action
      // ----------------------
      if (response.rci.edit_path !== null) {
        if (response.rci.edit_path.flat().length > 2) {
          response.rci.edit_path.map(editPath =>
            editPath[0].toLowerCase() === action.toLowerCase()
              ? dispatch(reportAction(id, editPath[1], response.rci.id, 'url'))
              : null)
        } else {
          response.rci.edit_path[0].toLowerCase() === action.toLowerCase()
            ? dispatch(reportAction(id, response.rci.edit_path[1], response.rci.id, 'url'))
            : null
        }
      }

      // Clear the content of the list where the report is imported to trigger another ajax call when selected
      // -----------------------------------------------------------------------------------------------------
      dispatch({
        type: ActionTypes.REPORT_GET_LIST_CONTENT,
        id: id,
        selected: response.rcl_id,
        reports: [],
      })
    })
    .catch(() => {
      showErrorNotification(!window.I18n ? '' : window.I18n.t('dashboard_menu.actions.index.error_importing_report'))
    })
}

export const reportPollJobStatus = (id, url, reportId, jobId, jobType) => (dispatch) => {
  const urlFormatted = url.replace(':id', jobId)
  callAPI(urlFormatted)
    .then(response => {
      // get status from response
      const resStatus = response.map(res => res.type === 'job-update' ? res.value : null)
                                .filter(res => res !== null)
      const status = resStatus.slice(-1)[0] !== undefined ? resStatus.slice(-1)[0].status : 'running'
      dispatch({
        type: ActionTypes.REPORT_POLL_JOB_STATUS,
        id: id,
        reportId: reportId,
        jobId: jobId,
        status: status,
      })

      if (status !== 'running' && jobType === 'pdf') {
        dispatch({
          type: ActionTypes.REPORT_PRINT_FINISHED,
          id: id,
          reportId: reportId,
          pdfPath: resStatus.slice(-1)[0].pdf_path,
          pdfButtonLabel: resStatus.slice(-1)[0].button_label,
          primaryDocxService: resStatus.slice(-1)[0].primary_docx_service,
          printCount: resStatus.slice(-1)[0].print_count,
          generateTxt: resStatus.slice(-1)[0].generate_txt,
        })
      }

      if (status !== 'running' && jobType === 'doc') {
        dispatch({
          type: ActionTypes.REPORT_DOC_CONVERT_FINISHED,
          id: id,
          reportId: reportId,
          docxPath: resStatus.slice(-1)[0].docx_path,
        })
      }

      if (status !== 'running' && jobType === 'txt') {
        dispatch({
          type: ActionTypes.REPORT_TXT_CONVERT_FINISHED,
          id: id,
          reportId: reportId,
          txtPath: resStatus.slice(-1)[0].txt_path,
        })
      }
    })
    .catch(() => {
      showErrorNotification(!window.I18n ? '' : window.I18n.t('dashboard_menu.actions.index.error_polling_for_job_status'))
    })
}

export const reportTogglePreviewModal = (id, visible, imgSrc = '', reportName = '') => (dispatch) => {
  dispatch({
    type: ActionTypes.REPORT_TOGGLE_PREVIEW_MODAL,
    id: id,
    visible: visible,
    imgSrc: imgSrc,
    reportName: reportName,
  })
}

export const openPrintModal = (content, callbacks, editConflictsPath = null) => {
  // Show the print modal and load html data from response
  const modalBody = $('#print_report > .modal-dialog > .modal-content > .modal-body')
  if (content !== null) {
    modalBody.html(content)
  }
  if (content === null && editConflictsPath !== null) {
    modalBody.load(editConflictsPath, callbacks)
  }

  callbacks()
}

export const scrollReports = (direction) => {
  const scrollAmount = direction === 'right' ? '+=350' : '-=350'
  $('.reports-panel').animate( { scrollLeft: scrollAmount }, 200)
}

export const toggleSetFlashMessageModal = (id, show) => (dispatch) => {
  dispatch({
    type: ActionTypes.FLASH_MESSAGE_SET_MODAL_VISIBILITY,
    id: id,
    show: show,
  })
}

export const changeFlashMessageData = (id, key, value) => (dispatch) => {
  dispatch({
    type: ActionTypes.FLASH_MESSAGE_CHANGE_DATA,
    id: id,
    key: key,
    value: value,
  })
}

export const updateFlashMessage = (url, formData) => {
  const params = {}
  Object.keys(formData).map(key => params[`website[${key}]`] = formData[key])
  params['from'] = 'event_dashboard'

  callAPI(url, 'POST', params, new Headers({ 'Accept': 'application/json' }))
    .then(() => { })
    .catch(() => {
      showErrorNotification(!window.I18n ? '' : window.I18n.t('dashboard_menu.actions.index.error_updating_website_flash_message'))
    })
}

export const toggleEditGgid = (id) => (dispatch) => {
  dispatch({
    type: ActionTypes.GGID_TOGGLE_EDIT,
    id: id,
  })
}

export const changeGgid = (id, newGgid) => (dispatch) => {
  dispatch({
    type: ActionTypes.GGID_CHANGE,
    id: id,
    newGgid: newGgid,
  })
}

export const validateGgidUniqueness = (id, url, newGgid) => (dispatch) => {
  callAPI(url, 'GET', { ggid: newGgid }, new Headers({ 'Accept': 'application/json' }))
    .then(json => {
      dispatch({
        type: ActionTypes.GGID_IS_VALID,
        id: id,
        validity: json.valid,
      })
    })
    .catch(() => {
      showErrorNotification(!window.I18n ? '' : window.I18n.t('dashboard_menu.actions.index.error_updating_website_flash_message'))
    })
}

export const updateGgid = (id, url, newGgid) => (dispatch) => {
  callAPI(url, 'POST', { 'league[pin]': newGgid }, new Headers({ 'Accept': 'application/json' }))
    .then(json => {
      if (json.success === true) {
        dispatch({
          type: ActionTypes.GGID_UPDATE,
          id: id,
        })
      } else {
        showErrorNotification(!window.I18n ? '' : window.I18n.t('dashboard_menu.actions.index.error_updating_ggid'))
      }
    })
    .catch(() => {
      showErrorNotification(!window.I18n ? '' : window.I18n.t('dashboard_menu.actions.index.error_updating_ggid'))
    })
}

export const handicapAnalysisShowHiddenDetails = (id, showKey) => (dispatch) => {
  dispatch({
    type: ActionTypes.HANDICAP_ANALYSIS_SHOW_HIDDEN_DETAILS,
    id: id,
    showKey: showKey,
  })
}

export const toggleDivisionActions = (id, divisionId) => (dispatch) => {
  dispatch({
    type: ActionTypes.TOGGLE_DIVISION_ACTIONS,
    id: id,
    divisionId: divisionId,
  })
}

export const handicapAnalysisSearch = (id, searchTerm) => (dispatch) => {
  dispatch({
    type: ActionTypes.HANDICAP_ANALYSIS_SEARCH,
    id: id,
    searchTerm: searchTerm,
  })
}

export const toggleAdjustHandicapModal = (id, show) => (dispatch) => {
  dispatch({
    type: ActionTypes.TOGGLE_ADJUST_HANDICAP_MODAL,
    id: id,
    show: show,
  })
}

export const clearPlayerAdjustment = (path) => () => {
  callAPI(path, 'GET', {}, new Headers({ 'Accept': 'application/json' }))
    .then(json => {
      if (json.success !== true) {
        showErrorNotification(!window.I18n ? '' : window.I18n.t('dashboard_menu.actions.index.error_clearing_players_adjusted_handicap'))
      }
    })
    .catch(() => {
      showErrorNotification(!window.I18n ? '' : window.I18n.t('dashboard_menu.actions.index.error_clearing_players_adjusted_handicap'))
    })
}

export const clearAllPlayerAdjustment = (path, eventId, confirmation) => () => {
  // eslint-disable-next-line no-alert
  if (window.confirm(confirmation)) {
    callAPI(path, 'GET', { event_id: eventId }, new Headers({ 'Accept': 'application/json' }))
      .then(json => {
        if (json.success !== true) {
          showErrorNotification(!window.I18n ? '' : window.I18n.t('dashboard_menu.actions.index.error_clearing_all_players_adjusted_handicap'))
        }
      })
      .catch(() => {
        showErrorNotification(!window.I18n ? '' : window.I18n.t('dashboard_menu.actions.index.error_clearing_all_players_adjusted_handicap'))
      })
  }
}

export const setAdjustHandicapFormData = (id, golfer, handicap, playerIds, ajustmentPath, clearAdjustmentPath) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_ADJUST_HANDICAP_FORM_DATA,
    id: id,
    golfer: golfer,
    handicap: handicap,
    playerIds: playerIds,
    ajustmentPath: ajustmentPath,
    clearAdjustmentPath: clearAdjustmentPath,
  })
}


export const submitAdjustHandicap = (id, path, handicap, playerIds) => (dispatch) => {
  callAPI(path, 'POST', { 'ch': handicap, 'player_ids': playerIds }, new Headers({ 'Accept': 'application/json' }))
    .then(json => {
      if (json.success === true) {
        dispatch({
          type: ActionTypes.TOGGLE_ADJUST_HANDICAP_MODAL,
          id: id,
          show: false,
        })
      } else {
        showErrorNotification(!window.I18n ? '' : window.I18n.t('dashboard_menu.actions.index.error_adjusting_handicap'))
      }
    })
    .catch(() => {
      showErrorNotification(!window.I18n ? '' : window.I18n.t('dashboard_menu.actions.index.error_adjusting_handicap'))
    })
}

export const setGenericData = (widgetId, keys, value) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_GENERIC_DATA,
    id: widgetId,
    keys: keys,
    value: value,
  })
}

export const saveEventSettingsWidget = (widgetId, url, data, { dataType = 'json', withNotifications = true} = {}) => (dispatch, getState) => {
  const setSaveState = (state) =>
    dispatch({
      type: ActionTypes.SET_GENERIC_DATA,
      id: widgetId,
      keys: [ 'saveInProgress' ],
      value: state,
    })

  setSaveState(true)
  return new Promise((resolve, reject) => {
    $.ajax(url, {
      method: 'POST',
      dataType: dataType,
      data: data,
      error: (xhr, status, error) => {
        reject({ state: getState(), xhr, status, error })
        withNotifications && showErrorNotification(!window.I18n ? '' : window.I18n.t('dashboard_plus.widgets.save_settings_error'))
        setSaveState(false)
      },
      success: (response) => {
        resolve({ state: getState(), response })
        setSaveState(false)

        if (dataType !== 'json' || response.success === true) {
          withNotifications && showNotification(response.message || (!window.I18n ? '' : window.I18n.t('dashboard_plus.widgets.save_settings_success')))
          dispatch({
            type: ActionTypes.SET_GENERIC_DATA,
            id: widgetId,
            keys: [ 'dataChanged' ],
            value: false,
          })
        } else {
          withNotifications && showErrorNotification(response.error)
        }
      },
    })
  })
}


export const deleteDivisions = (widgetId, url, data, { dataType = 'json', withNotifications = true} = {}) => (dispatch, getState) => {
  const setSaveState = (state) =>
    dispatch({
      type: ActionTypes.SET_GENERIC_DATA,
      id: widgetId,
      keys: [ 'saveInProgress' ],
      value: state,
    })

  setSaveState(true)
  return new Promise((resolve, reject) => {
    $.ajax(url, {
      method: 'DELETE',
      dataType: dataType,
      data: data,
      error: (xhr, status, error) => {
        reject({ state: getState(), xhr, status, error })
        withNotifications && showErrorNotification(xhr.responseJSON.errors || (!window.I18n ? '' : window.I18n.t('dashboard_plus.widgets.save_settings_error')))
        setSaveState(false)
      },
      success: (response) => {
        resolve({ state: getState(), response })
        setSaveState(false)
        withNotifications && showNotification(response.message || (!window.I18n ? '' : window.I18n.t('dashboard_plus.widgets.save_settings_success')))
        dispatch({
          type: ActionTypes.SET_GENERIC_DATA,
          id: widgetId,
          keys: [ 'dataChanged' ],
          value: false,
        })
      },
    })
  })
}

export const updateRounds = (widgetId, url, data, getRoundsUrl) => (dispatch, getState) => {
  const setRounds = (rounds) => dispatch({
    type: ActionTypes.SET_GENERIC_DATA,
    id: widgetId,
    keys: [ 'rounds' ],
    value: rounds,
  })


  return new Promise((resolve, reject) => {
    $.ajax(url, {
      method: 'POST',
      dataType: 'json',
      data: data,
      error: (xhr, status, error) => {
        reject({ state: getState(), xhr, status, error })
        showErrorNotification(!window.I18n ? '' : window.I18n.t('dashboard_plus.widgets.operation_errors'))
      },
      success: (json) => {
        if (json.success) {
          if (! json.job_id) {
            showNotification(json.message)
            resolve({ state: getState(), response: json })
            setRounds(json.rounds)
          } else {
            pollJob(json.job_id, pollUpdateNotifyMessages)
              .then(update => {
                showNotification(update.value.friendly_status)

                $.ajax(getRoundsUrl, {
                  method: 'POST',
                  dataType: 'json',
                  success: (response) => {
                    resolve(response)
                    setRounds(response.rounds)
                  },
                  error: (xhr, status, error) => {
                    reject({ state: getState(), xhr, status, error })
                  },
                })
              })
              .catch(update => {
                showErrorNotification(!window.I18n ? '' : window.I18n.t('dashboard_plus.widgets.operation_errors'))
                reject(update)
              })
          }
        } else {
          showErrorNotification(json.error)
          reject({ response: json })
        }
      },
    })
  })
}

export const editRound = (widgetId, url, data, valueKeys, value) => (dispatch) => {
  $.ajax(url, {
    method: 'PUT',
    dataType: 'json',
    data: data,
    error: () => {
      showErrorNotification(!window.I18n ? '' : window.I18n.t('dashboard_plus.widgets.operation_errors'))
    },
    success: () => {
      dispatch({
        type: ActionTypes.SET_GENERIC_DATA,
        id: widgetId,
        keys: [ 'rounds' ].concat(valueKeys),
        value: value,
      })
    },
  })
}

export const toggleRoundSignup = (widgetId, url, roundId, valueKeys) => (dispatch) => {
  $.ajax(url, {
    method: 'POST',
    dataType: 'json',
    error: () => {
      showErrorNotification(!window.I18n ? '' : window.I18n.t('dashboard_plus.widgets.round_signup_toggle_error'))
      dispatch({
        type: ActionTypes.SET_GENERIC_DATA,
        id: widgetId,
        keys: [ 'updatingData', roundId ],
        value: false,
      })
    },
    success: (round) => {
      dispatch({
        type: ActionTypes.SET_GENERIC_DATA,
        id: widgetId,
        keys: [ 'rounds' ].concat(valueKeys),
        value: round.reg_open ? 'yes' : 'no',
      })
      dispatch({
        type: ActionTypes.SET_GENERIC_DATA,
        id: widgetId,
        keys: [ 'updatingData', roundId ],
        value: false,
      })
    },
  })
}

export const getPossibleCustomFieldAnswers = (widgetId, url) => (dispatch) => {
  $.ajax(url, {
    dataType: 'json',
    error: () => {
      showErrorNotification(!window.I18n ? '' : window.I18n.t('dashboard_plus.widgets.custom_field_answers_retrieval_error'))
    },
    success: (answers) => {
      answers = answers[0].unique().map(e => ({ label: e, value: e }))
      dispatch({
        type: ActionTypes.SET_GENERIC_DATA,
        id: widgetId,
        keys: [ 'registration', 'data', 'registration_setup', 'options', 'enable_pending_registration', 'custom_field_possible_answers' ],
        value: answers,
      })
    },
  })
}

export const scheduleSignupsOpenClose = (widgetId, url, data) => (dispatch) => {
  const setSaveState = (state) =>
    dispatch({
      type: ActionTypes.SET_GENERIC_DATA,
      id: widgetId,
      keys: [ 'saveInProgress' ],
      value: state,
    })

  setSaveState(true)
  $.ajax(url, {
    method: 'POST',
    dataType: 'json',
    data: data,
    complete: (xhr) => {
      setSaveState(false)
      if (xhr.status === 200) {
        showNotification(!window.I18n ? '' : window.I18n.t('dashboard_plus.widgets.save_settings_success'))
      } else {
        showErrorNotification(!window.I18n ? '' : window.I18n.t('dashboard_plus.widgets.save_settings_error'))
      }
    },
  })
}

export const eventSetupWidgetDoJob = (widgetId, url, data, method = 'POST') => (dispatch) => {
  const setSaveState = (state) =>
    dispatch({
      type: ActionTypes.SET_GENERIC_DATA,
      id: widgetId,
      keys: [ 'saveInProgress' ],
      value: state,
    })

  setSaveState(true)
  $.ajax(url, {
    method,
    data,
    dataType: 'json',
    error: () => {
      setSaveState(false)
      showErrorNotification(!window.I18n ? '' : window.I18n.t('dashboard_plus.widgets.save_settings_error'))
    },
    success: (json) => {
      if (json.success) {
        NotificationManager.info(!window.I18n ? '' : window.I18n.t('dashboard_plus.widgets.job_started'))

        pollJob(json.job_id, pollUpdateNotifyMessages)
          .then(update => {
            showNotification(update.value.friendly_status)
          })
          .catch(() => {
            showErrorNotification(!window.I18n ? '' : window.I18n.t('dashboard_plus.widgets.save_settings_error'))
          })
          .finally(() => {
            setSaveState(false)
          })

      } else {
        setSaveState(false)
        showErrorNotification(json.error || !window.I18n ? '' : window.I18n.t('dashboard_plus.widgets.save_settings_error'))
      }
    },
  })
}


export const pollJob = (jobId, onUpdate = () => {}, delay = 500) => {
  const pollJobPath = `/jobs/${jobId}/poll`
  let updatesCount = 0

  return new Promise((resolve, reject) => {
    const doPoll = () => setTimeout(() => {
      $.ajax(pollJobPath, {
        method: 'GET',
        dataType: 'json',
        error: (xhr, status, error) => {
          reject({ xhr, status, error})
        },
        success: (jobUpdates) => {
          if (jobUpdates.length > updatesCount) {
            for (let i = updatesCount; i < jobUpdates.length; ++i) {
              const update = jobUpdates[i]
              onUpdate(update)

              if (update.type === 'job-update') {
                switch (update.value.status) {
                  case 'completed':
                    resolve(update)
                    return

                  case 'errors':
                    reject(update)
                    return

                  default:
                    break
                }
              }
            }

            updatesCount = jobUpdates.length
          }

          //in progress - keep polling
          doPoll()
        },
      })
    }, delay)

    doPoll()
  })
}

export const pollUpdateNotifyMessages = (update, transform = x => x) => {
  switch (update.type) {
    case 'job-update': {
      if (update.value.status === 'running') {
        NotificationManager.info(transform(update.value.friendly_status))
      }
      break
    }

    case 'message':
      NotificationManager.info(transform(update.value))
      break
  }
}

export const ajaxRequest = (url, method = 'get', data = {}, dataType = null) => () => {
  return new Promise((resolve, reject) => {
    $.ajax(url, {
      method,
      data,
      dataType,
      success: (response, status, xhr) => {
        resolve({response, status, xhr})
      },
      error: (xhr, status, error) => {
        reject({xhr, status, error})
      },
    })
  })
}

export const clearWidgetContent = (...widgetContentTypes) => (dispatch, getState) => {
  const state = getState()

  Object.values(state.widgets)
    .flat()
    .filter(widget => widgetContentTypes.includes(widget.content_type))
    .map(widget => { dispatch(setGenericData(widget.id, [], undefined)) })
}

export const reloadWidgetContent = (...widgetContentTypes) => (dispatch, getState) => {
  const state = getState()
  const dependentWidgets = state.widgets[state.menu.selectedMenuItem].filter(w => widgetContentTypes.includes(w.content_type))

  dependentWidgets.forEach(widget => {
    dispatch(loadWidgetContent(widget.data_path, widget.id))
  })
}
