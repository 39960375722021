import _ from 'lodash'
import createDeepSelector from './create_deep_selector'

import { getSortedCategoryIds } from './index'

const _getSortedCategoryIds = (state, { useManagedCustomer = false } = {}) => {
  return getSortedCategoryIds(state, useManagedCustomer)
}
const getCategories = (state, { useManagedCustomer = false } = {}) => {
  if (useManagedCustomer) {
    return state.managedCustomerData.categories || state.categories
  } else {
    return state.categories
  }
}
const getAddAll = (state, { addAll = false } = {}) => addAll

const getCategoriesOptions = createDeepSelector(
  [ getCategories, _getSortedCategoryIds, getAddAll ],
  (categoriesById, sortedCategoryIds, addAll) => {
    const categoriesOptions = sortedCategoryIds.map(
      categoryId => _.pick(categoriesById[categoryId], [ 'id', 'name' ])
    )
    if (addAll) {
      categoriesOptions.unshift({
        id: 'all',
        name: 'All',
      })
    }
    return categoriesOptions
  }
)

export default getCategoriesOptions
