import _ from 'lodash'
import createDeepSelector from './create_deep_selector'

import { getSortedDefaultCourseIds } from './index'

const _getSortedDefaultCourseIds = (state, { useManagedCustomer = false } = {}) => {
  return getSortedDefaultCourseIds(state, useManagedCustomer)
}
const getDefaultCourse = (state, { useManagedCustomer = false } = {}) => {
  if (useManagedCustomer) {
    return state.managedCustomerData.default_courses || state.default_courses
  } else {
    return state.default_courses
  }
}
const getAddAll = (state, { addAll = false } = {}) => addAll

const getDefaultCourseOptions = createDeepSelector(
  [ getDefaultCourse, _getSortedDefaultCourseIds, getAddAll ],
  (defaultCourseById, sortedDefaultCourseIds, addAll) => {
    const defaultCourseOptions = sortedDefaultCourseIds.map(
      categoryId => _.pick(defaultCourseById[categoryId], [ 'id', 'name' ])
    )
    if (addAll) {
      defaultCourseOptions.unshift({
        id: 'all',
        name: 'All',
      })
    }
    return defaultCourseOptions
  }
)

export default getDefaultCourseOptions
