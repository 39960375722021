import React, {useState, useEffect, Fragment} from 'react'
import PropTypes from 'prop-types'
import { callAPI } from 'Shared/helpers'
import { Col, Row } from 'react-flexbox-grid'
import { getAuthenticityToken } from '../../../shared/helpers'
import { GlgLoadingSpinner, GlgRadio, GlgSelect } from 'SharedComponents'
import IframeContainer from './IframeContainer'
import CKEditor from 'ckeditor4-react'
import { BannerItems, AddPhotoPopup } from '../banners/BannersNew'
import { SponsorItems, AddSponsorsPopup } from '../sponsors/SponsorsNew'
import { HubFiltersPopup } from './HubFiltersPopup'
import { ClubSelectPopup } from './ClubSelectPopup'
import { GlgCheckbox, GlgTooltipIcon } from '../../../shared/components'

const HubPage = (props) => {
  const selectedClubsData = props.selectedClubs?.map(selectedClub => { return {id: selectedClub.id, customer_id: selectedClub.customer_id, name: selectedClub.name } })
  const selectedManagers = props.selectedManagers?.map(selectedManager => { return {id: selectedManager.id, user_id: selectedManager.user_id, name: selectedManager.name} })

  const [ data, setData ] = useState({aggregate_from: '', encrypted_id: null, hub_name: '', name: '', description: '', external_website: '', location: null, customer_link: '', communication_officer_set: false, managers_link: '', front_end_url: '', courses: [], selected_course: null, sponsors: [], sponsor: 'none', course_albums: [], course_album: null, daily_digest: false, communication_officer_assigned: false, selected_clubs: selectedClubsData, selected_managers: selectedManagers, view_settings: {}})
  const [ formData, setFormData ] = useState({aggregate_from: '', hub_name: '', description: '', external_website: '', selected_course: null, sponsor: 'none', course_album: null, daily_digest: false, communication_officer_assigned: false, view_settings: {}})
  const [ errors, setErrors ] = useState({})
  const [ showSponsorLoading, setShowSponsorLoading ] = useState(false)
  const [ showBannerLoading, setShowBannerLoading ] = useState(false)
  
  const [ filterModalOpen, setFilterModalOpen ] = useState(false)
  const [ clubSelectModalOpen, setClubSelectModalOpen ] = useState(false)

  const [ dataIsLoading, setDataIsLoading ] = useState(true)

  const [ newCourseAlbums, setNewCourseAlbums ] = useState([])
  const [ courseAlbumModalOpen, setCourseAlbumModalOpen ] = useState(false)
  const [ courseAlbumDeleted, setCourseAlbumDeleted ] = useState([])

  const [ newSponsors, setNewSponsors ] = useState([])
  const [ sponsorModalOpen, setSponsorModalOpen ] = useState(false)
  const [ sponsorDeleted, setSponsorDeleted ] = useState([])

  const [ newBannersUpdated, setNewBannersUpdated ] = useState({})
  const [ newBannersBlobUrl, setNewBannersBlobUrl ] = useState({})

  const [ newSponsorsUpdated, setNewSponsorsUpdated ] = useState({})
  const [ newSponsorsBlobUrl, setNewSponsorsBlobUrl ] = useState({})

  const [ clubLeagues, setClubLeagues ] = useState([])
  const [ clubLeaguesHaveBeenFetched, setClubLeaguesHaveBeenFetched ] = useState(false)
  const [ selectedClubLeagues, setSelectedClubLeagues ] = useState(null)

  const [ selectedClubsNumber, setSelectedClubsNumber ] = useState(selectedClubsData?.length || 0)
  const [ excludedOptions, setExcludedOptions ] = useState([])
  const [ noOptions, setNoOptions ] = useState(false)

  const [ eventsCount, setEventsCount ] = useState(0)
  const [ canSave, setCanSave ] = useState(true)

  const fetchData = () => {
    callAPI(`/api/customers/${props.customerId}/get_club_data?hub_id=${props.hubId}`, 'GET')
      .then((json) => {
        setData({...data, ...json})
        setFormData(json)
      })
  }

  const checkPresence = (id, eventsRegistrationTypes) => {
    return eventsRegistrationTypes.filter((regType) => regType.id === id).length > 0
  }

  const deserializeEvent = (events, included) => {
    if (events === undefined) {
      return events
    }

    return events.map((event) => {

      const mainAddress = included.find((inc) => inc.type === 'address' && inc.id === event.relationships.main_address.data.id)
      const courseAlbum = included.find((inc) => inc.type === 'course_album' && inc.id === event.relationships.course_album.data.id)

      return {
        ...event,
        attributes: {
          ...event.attributes,
          registration_types: included.filter((item) => item.type === 'slim_registration_type' && checkPresence(item.id, event.relationships.registration_types.data)).map((item) => ({...item.attributes})),
          main_address: mainAddress ? mainAddress.attributes : null,
          course_album: courseAlbum ? courseAlbum.attributes : null,
        },
      }
    })
  }

  const fetchClubLeagueData = (roleSetupFilters = data?.role_setup_filters, selectedClubs = data?.selected_clubs) => {
    roleSetupFilters = roleSetupFilters || []
    selectedClubs = selectedClubs || []

    const nonDeletedFilters = roleSetupFilters.filter(filter => filter['_destroy'] === null || filter['_destroy'] !== true)
    const updatedSelectedClubs = selectedClubs.filter(selectedClub => selectedClub['_destroy'] === null || selectedClub['_destroy'] !== true).map((record) => record.customer_id)
    setSelectedClubsNumber(updatedSelectedClubs.length)
    callAPI(`/api/customers/${props.customerId}/get_club_leagues?hub_id=${props.hubId}`, 'GET', { aggregation_type: formData.aggregate_from, role_type: props.roleType, filters: JSON.stringify(nonDeletedFilters), aggregated_clubs: updatedSelectedClubs})
      .then((json) => {
        const deserializeData = deserializeEvent(json.data, json.included)
        setClubLeagues(deserializeData || [])
        setEventsCount(json.events_count)
        if (formData.aggregate_from === 'none' && nonDeletedFilters.length === 0) {
          setClubLeaguesHaveBeenFetched(false)
        } else {
          setClubLeaguesHaveBeenFetched(true)
        }
        setSelectedClubLeagues(deserializeData)
      })
  }

  const isHub = props.roleType === 'hub'

  // formData and selectedClubLeagues should always be set before setDataIsLoading
  useEffect(() => {
    if (data.aggregate_from !== '' && formData.aggregate_from !== '') {
      fetchClubLeagueData()
    }
  }, [ formData.aggregate_from, data?.role_setup_filters, data?.selected_clubs ])
  useEffect(() => {
    if (selectedClubLeagues !== null) {
      setDataIsLoading(false)
    }
  }, [ selectedClubLeagues ])
  useEffect(fetchData, [])

  let displayName = props.roleType.charAt(0).toUpperCase() + props.roleType.slice(1)

  if (isHub) {
    displayName = formData.hub_name
  } else if (props.roleType !== 'club') {
    displayName = displayName.slice(0, -1)
  }

  const handleFormData = (e, key) => {
    const value = key === 'description' ? e.editor.getData() : e.target.value
    const errors = {}

    if (value !== '' && key === 'external_website' && !value.match('[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$')){
      errors['external_website'] = window.I18n.t('views.golfhub.errors.external_website')
    }
    if (value === '' && key === 'hub_name') {
      errors['hub_name'] = window.I18n.t('views.golfhub.errors.hub_name')
    }

    if (key === 'selected_course' && value === -1) {
      setFormData({...formData, [key]: null})
    } else {
      setFormData({...formData, [key]: value})
    }
    setErrors(errors)
  }

  const saveFormData = () => {
    if (isHub && formData.hub_name === '') {
      setErrors({'hub_name': window.I18n.t('views.golfhub.errors.hub_name')})
      return
    }
    checkDirtyForm(true)

    const fd = new FormData()

    fd.append('aggregate_from', formData.aggregate_from)
    fd.append('hub_name', formData.hub_name)
    fd.append('description', formData.description)
    fd.append('external_website', formData.external_website)
    fd.append('selected_course', formData.selected_course || data.courses[0]?.id || null)
    fd.append('sponsor', formData.sponsor?.id || formData.sponsor)
    fd.append('course_album', formData.course_album?.id || formData.course_album)
    fd.append('role_type', props.roleType)
    fd.append('daily_digest', (formData.aggregate_from === 'custom' || formData.aggregate_from === 'all') ? formData.daily_digest : false)
    fd.append('communication_officer_assigned', data?.selected_managers && data?.selected_managers.some(selectedManager => selectedManager.user_id === 0))
    fd.append('selected_managers', JSON.stringify(updatedSelectedManagers()))
    fd.append('role_setup_filters', JSON.stringify(data?.role_setup_filters))
    fd.append('selected_clubs', JSON.stringify(data?.selected_clubs))
    fd.append('view_settings', JSON.stringify(formData.view_settings))

    if (newSponsors.length > 0) {
      newSponsors.forEach(s => {
        fd.append('sponsor_urls[]', s.url)
        fd.append('sponsor_photos[]', s.imageFile)
      })
    }

    if (newCourseAlbums.length > 0) {
      newCourseAlbums.forEach(ca => {
        fd.append('course_album_photos_crop[]', JSON.stringify(ca.crop))
        fd.append('course_album_photos[]', ca.imageFile)
      })
    }

    fetch(`/api/customers/${props.customerId}/update_club_data?hub_id=${props.hubId}`, {
      method: 'PATCH',
      body: fd,
      headers: {'X-CSRF-Token': getAuthenticityToken()}})
      .then((response) => {
        const message = response.ok ? window.I18n.t('views.golfhub.errors.good_save').replace('#{hub_name}', displayName) : window.I18n.t('views.golfhub.errors.bad_save')
        window.show_flash(message)
        window.hide_flash()
        window.location.pathname = `/customers/${props.customerId}/hubs`
      })
  }

  const updatedSelectedManagers = () => {
    if (!data?.selected_managers){
      return []
    }
    // remove old managers and try not to make duplicates (if a manager was previously here and through actions he was removed and added back he will no longer have the id, give him that id back so you don't make 2 more queries)
    const newSelectedManagers = data?.selected_managers.filter(selectedManager => selectedManager.user_id !== 0)

    if (props.selectedManagers === undefined || props.selectedManagers.length === 0){
      return newSelectedManagers
    } else {
      let auxSelectedManagers = []
      newSelectedManagers.map(newSelectedManager => {
        const selectedIndex = props.selectedManagers.findIndex(selectedManager => selectedManager.user_id === newSelectedManager.user_id)
        if (selectedIndex === -1) {
          auxSelectedManagers = [ ...auxSelectedManagers, {user_id: newSelectedManager.user_id, name: newSelectedManager.name} ]
        } else {
          auxSelectedManagers = [ ...auxSelectedManagers, {id: props.selectedManagers[selectedIndex].id, user_id: newSelectedManager.user_id, name: newSelectedManager.name} ]
        }
      })

      props.selectedManagers.map(oldSelectedManager => {
        if (oldSelectedManager.user_id !== 0) {
          const selectedIndex = newSelectedManagers.findIndex(selectedManager => selectedManager.user_id === oldSelectedManager.user_id)
          if (selectedIndex === -1) {
            auxSelectedManagers = [ ...auxSelectedManagers, {id: oldSelectedManager.id, _destroy: true, user_id: oldSelectedManager.user_id, name: oldSelectedManager.name} ]
          }
        }
      })

      return auxSelectedManagers
    }
  }

  const setCourseAlbum = (id) => {
    setFormData({...formData, course_album: data.course_albums.find(ca => ca.id === id)})
  }

  const setNewCourseAlbum = (records) => {
    setNewCourseAlbums(records)
    setFormData({...formData, course_album: 'new'})

    const albumFormData = new FormData
    records.forEach(ca => {
      albumFormData.append('course_album_photos_crop[]', JSON.stringify(ca.crop))
      albumFormData.append('course_album_photos[]', ca.imageFile)
    })
    setShowBannerLoading(true)
    fetch(`/api/customers/${props.customerId}/create_temp_course_albums`, { method: 'POST', body: albumFormData, headers: { 'X-CSRF-Token': getAuthenticityToken() } } )
    .then((response) => {
      return response.json()
    })
    .then((data) => {
      const newBannersBlob = {}
      data.course_album.photos.map((banner, index) => { newBannersBlob[records[index]?.image] = banner })
      setNewBannersBlobUrl(newBannersBlob)
      setNewBannersUpdated(data.course_album)
      setShowBannerLoading(false)
    })
  }

  const setSponsor = (id) => {
    setFormData({...formData, sponsor: data.sponsors.find(s => s.id === id)})   
  }

  const setNewSponsor = (records) => {
    setNewSponsors(records)
    setFormData({...formData, sponsor: 'new'})
    if (records.length > 0) {
      const albumFormData = new FormData
      records.forEach(sponsor => {
        albumFormData.append('sponsor_urls[]', sponsor.url)
        albumFormData.append('sponsor_photos[]', sponsor.imageFile)
      })
      
      setShowSponsorLoading(true)
      fetch(`/api/customers/${props.customerId}/create_temp_sponsors`, { method: 'POST', body: albumFormData, headers: { 'X-CSRF-Token': getAuthenticityToken() } } )
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        const newSponsorsBlob = {}
        data.sponsor.photos.map((sponsor, index) => { newSponsorsBlob[records[index]?.image] = sponsor })
        setNewSponsorsBlobUrl(newSponsorsBlob)
        setNewSponsorsUpdated(data.sponsor)
        setShowSponsorLoading(false)
      })
    }
  }

  const setNoSponsors = () => {
    setFormData({...formData, sponsor: 'none'})
  }

  const checkDirtyForm = (isOnSubmit) => {
    const isDirtyForm = !(data.hub_name === formData.hub_name && 
      data.aggregate_from === formData.aggregate_from &&
      data.description === formData.description &&
      data.external_website === formData.external_website &&
      data.selected_course === formData.selected_course &&
      data.sponsor === formData.sponsor &&
      data.course_album === formData.course_album)
    
    if (isDirtyForm && !isOnSubmit) {
      window.onbeforeunload = () => window.I18n.t('views.golfhub.errors.unsaved_changes')
    } else {
      window.onbeforeunload = null
    }
  }

  let iframeSrc = data.front_end_url
  if (props.roleType !== 'club') {
    if (props.roleType === 'events') {
      iframeSrc += '?section=open_event'
    } else if (props.roleType === 'leagues') {
      iframeSrc += '?section=league'
    } else if (props.roleType === 'clinics') {
      iframeSrc += '?section=clinic'
    }
  }

  const removeSeasonFilters = (aggregateFromValue) => {
    if (aggregateFromValue !== 'none') {
      const filters = data.role_setup_filters.filter((filter) => {
        return filter.category !== 'Season'
      })

      const excludedOptionsWithoutSeasons = excludedOptions.filter((option) => {
        return option.category !== 'Season'
      })

      setData({ ...data, role_setup_filters: filters })
      setExcludedOptions(excludedOptionsWithoutSeasons)
    }
  }

  const setAggregateFrom = (aggregateFromValue) => {
    if (aggregateFromValue === 'all') {
      setSelectedClubLeagues(clubLeagues)
    } else if (aggregateFromValue === 'none') {
      setSelectedClubLeagues([])
    }

    if (formData.aggregate_from !== '') {
      setFormData({...formData, aggregate_from: aggregateFromValue})
    }

    removeSeasonFilters(aggregateFromValue)
  }

  const aggregateFromItems = [
    {
      label: window.I18n.t('views.golfhub.aggregate_from.none'),
      value: 'none',
    },
    {
      label: window.I18n.t('views.golfhub.aggregate_from.all'),
      value: 'all',
    },
    {
      label: window.I18n.t('views.golfhub.aggregate_from.custom'),
      value: 'custom',
    },
  ]

  const setDataAndReloadEvents = (value) => {
    setData(value)
  }

  return (
    <div className='hub-page'>
      <div className='btsp_form_content col-xs-12'>
        { dataIsLoading && (<div> <br/> <br/> <GlgLoadingSpinner className='text-center' show={dataIsLoading}/> <br/> </div>)}

        {( !dataIsLoading &&
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 no-padding add-padding-top-10">
          <div className="col-xs-6 add-padding-left-20 add-padding-right-30">
            <div className={`row add-padding-top-20 ${!isHub && 'hidden'}`}>
              <div className='col-xs-12 col-sm-3 col-md-3 col-lg-3 add-padding-top-5 inline-container'>
                <label>{window.I18n.t('views.golfhub.columns.hub_name')}</label>
              </div>
              <div className="col-xs-12 col-sm-9 col-md-9 col-lg-9 inline-container">
                <input defaultValue={data?.hub_name} onChange={(e) => handleFormData(e, 'hub_name')} type='text' className={`form-control form-text ${errors['hub_name'] && 'is_field_error'}`}/>
                <span style={{color: 'red', paddingTop: '5px'}}>{errors['hub_name']}</span>
              </div>
            </div>
            <div className={`row add-padding-top-20 ${(props.isSuperCustomer === 'true' && isHub) ? '' : 'hidden'}`}>
              <Col xs={12} sm={3} md={3} lg={3} className="inline-container add-margin-top-10">
                <label>{window.I18n.t('views.golfhub.aggregate_from.scope')}</label>
              </Col>
              <Col xs={12} sm={9} md={9} lg={9} className="inline-container">
                <GlgSelect
                  name='aggregate_from'
                  defaultValue={ data.aggregate_from }
                  items={ aggregateFromItems }
                  onChange={ setAggregateFrom }
                />
              </Col>
            </div>

            {( props.isSuperCustomer === 'true' && isHub && formData.aggregate_from === 'custom' &&
              <div className='row add-padding-top-20'>
                <Col xs={12} sm={3} md={3} lg={3} className="inline-container add-margin-top-5">
                  <label>{window.I18n.t('views.golfhub.aggregate_from.clubs')}</label>
                </Col>
                <Col xs={12} sm={9} md={9} lg={9} className="inline-container add-margin-top-5">
                { selectedClubsNumber > 0 && (<b className="selected-clubs">{selectedClubsNumber} { selectedClubsNumber === 1 ? window.I18n.t('views.golfhub.aggregate_from.club') : window.I18n.t('views.golfhub.aggregate_from.clubs')} {window.I18n.t('views.golfhub.aggregate_from.selected')}</b>)}

                  <a className="copy-button">
                    <span style={{ cursor: 'pointer' }} onClick={() => setClubSelectModalOpen(true)}>{window.I18n.t('views.golfhub.aggregate_from.select_clubs')}</span>
                  </a>
                </Col>
              </div>
            )}
            <div className={`row add-padding-top-20 ${!isHub && 'hidden'}`}>
              <div className='filter-title col-xs-12 col-sm-3 col-md-3 col-lg-3 inline-container'>
                <label>{window.I18n.t('views.golfhub.filters')}</label>
              </div>
              <div className='filters-container col-xs-12 col-sm-9 col-md-9 col-lg-9 inline-container'>
                <FilterRowContainer data={data} setData={setDataAndReloadEvents} strongFilters={null} excludedOptions={excludedOptions} setExcludedOptions={setExcludedOptions} noOptions={noOptions} setNoOptions={setNoOptions}/>
                {!noOptions && 
                  <span className='add-filter'>
                    <a className="add-filter-button add-margin-top-5" style={{ cursor: 'pointer' }} onClick={() => setFilterModalOpen(true)}>
                      <i className='fa fa-plus fa-border filter-icon'></i>
                      <span>Add</span>
                    </a>
                  </span>
                }
              </div>
            </div>
            <div className='row add-padding-top-20'>
              <div className='col-xs-12 col-sm-3 col-md-3 col-lg-3 inline-container'>
                <label>{window.I18n.t('views.golfhub.website')}</label>
              </div>
              <div className='col-xs-12 col-sm-9 col-md-9 col-lg-9 inline-container'>
                <div className="bold">{window.I18n.t('views.golfhub.add_external_webiste')}</div>
                <div className="clear">{window.I18n.t('views.golfhub.accessible_link')}</div>
                <div className="add-padding-top-20">
                  <input defaultValue={data?.external_website} onChange={(e) => handleFormData(e, 'external_website')} type='url' className={`form-control form-text ${errors['external_website'] && 'is_field_error'}`}/>
                  <span style={{color: 'red', paddingTop: '5px'}}>{errors['external_website']}</span>
                </div>
              </div>
            </div>
            <div className={`row add-padding-top-20 ${isHub && 'hidden'}`}>
              <div className='filter-title col-xs-12 col-sm-3 col-md-3 col-lg-3 inline-container'>
                <label>{window.I18n.t('views.golfhub.filters')}</label>
              </div>
              <div className='filters-container col-xs-12 col-sm-9 col-md-9 col-lg-9 inline-container'>
                <FilterRowContainer data={data} setData={setDataAndReloadEvents} strongFilters={props.strongFilters}/>
              </div>
            </div>
            <div className='row add-padding-top-30'>
              <div className='col-sm-3 col-md-3 col-lg-3 inline-container'>
                <label>{window.I18n.t('views.golfhub.description')}</label>
              </div>
              <div className='col-xs-12 col-sm-9 col-md-9 col-lg-9 inline-container'>
                <div className="bold">{window.I18n.t('views.golfhub.fill_description')}</div>
                <div className="clear">{window.I18n.t('views.golfhub.displayed_description')}</div>
              </div>
            </div>
            <div className='row add-padding-top-30'>
              <div className='col-xs-12'>
                <CKEditor
                  className='form-control form-text name-input'
                  data={data?.description}
                  onChange={ (e) => handleFormData(e, 'description') }
                  config={{
                    toolbar: [
                      [ 'Undo', 'Redo' ],
                      [ 'PasteFromWord' ],
                      [ 'Font', 'FontSize', 'lineheight' ],
                      [ 'Bold', 'Italic', 'Underline', 'Strike', 'TextColor', 'BGColor' ],
                      [ 'Link', 'Unlink' ],
                      [ 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock' ],
                      [ 'NumberedList', 'BulletedList', 'Indent', 'Outdent' ],
                      [ 'HorizontalRule', 'Table', 'Image' ],
                      [ 'Source' ],
                    ],
                  }}
                />
              </div>
            </div>
            <div className='row add-padding-top-30'>
              <div className='col-sm-3 col-md-3 col-lg-3 inline-container add-padding-top-5'>
                <label>{window.I18n.t('views.golfhub.view_settings')}</label>
              </div>
              <Col xs={12} sm={9} className="no-padding no-margin-bottom">
                <Col xs={12} className="inline_container add-padding-top-5">
                  <GlgCheckbox
                    name="enable_map_view"
                    label={ window.I18n.t('views.golfhub.enable_map_view') }
                    defaultChecked={ data.view_settings.enable_map_view }
                    inline={true}
                    onChange={ (val) =>
                      setFormData({
                        ...formData,
                        view_settings: {
                          ...formData.view_settings,
                          enable_map_view: val,
                          enable_clustering: val,
                          include_only_city: val,
                          default_view: val ? 'map'
                                      : !val && formData.view_settings.default_view === 'map' ? 'tile'
                                      : formData.view_settings.default_view,
                        },
                      })
                    }
                  />
                </Col>
                {( formData.view_settings.enable_map_view &&
                  <Col xs={12} className="inline_container add-padding-top-10">
                    <GlgCheckbox
                      name="enable_clustering"
                      label={ window.I18n.t('views.golfhub.enable_clustering') }
                      checked={ formData.view_settings.enable_clustering }
                      inline={true}
                      onChange={ (val) => setFormData({...formData, view_settings: {...formData.view_settings, enable_clustering: val}}) }
                    />
                    <span className="info_circle">
                      <GlgTooltipIcon tooltip={window.I18n.t('views.golfhub.enable_clustering_info_circle')} placement="bottom" iconClass="add-margin-left-5 add-top-3"/>
                    </span>
                  </Col>
                )}
                <Col xs={12} className="inline_container add-padding-top-10">
                  <GlgCheckbox
                    name="include_next_round"
                    label={ window.I18n.t('views.golfhub.include_next_round') }
                    defaultChecked={ data.view_settings.include_next_round }
                    inline={true}
                    onChange={ (val) => setFormData({...formData, view_settings: {...formData.view_settings, include_next_round: val}}) }
                  />
                </Col>
                <Col xs={12} className="inline_container add-padding-top-10">
                  <GlgCheckbox
                    name="group_by_month"
                    label={ window.I18n.t('views.golfhub.group_by_month') }
                    defaultChecked={ data.view_settings.group_by_month }
                    inline={true}
                    onChange={ (val) => setFormData({...formData, view_settings: {...formData.view_settings, group_by_month: val}}) }
                  />
                  <span className="info_circle">
                    <GlgTooltipIcon tooltip={window.I18n.t('views.golfhub.group_by_month_info_circle')} placement="bottom" iconClass="add-margin-left-5 add-top-3"/>
                  </span>
                </Col>
                <Col xs={12} className="inline_container add-padding-top-10">
                  <GlgCheckbox
                    name="include_only_city"
                    label={ window.I18n.t('views.golfhub.include_only_city') }
                    checked={ formData.view_settings.include_only_city || formData.view_settings.enable_map_view }
                    inline={true}
                    disabled={ formData.view_settings.enable_map_view }
                    onChange={ (val) => setFormData({...formData, view_settings: {...formData.view_settings, include_only_city: val}}) }
                  />
                  <span className='info_circle'>
                    <GlgTooltipIcon tooltip={window.I18n.t('views.golfhub.state_not_available')} placement="bottom" iconClass="add-margin-left-5 add-top-3"/>
                  </span>
                </Col>
              </Col>
            </div>
            <div className='row add-padding-top-30'>
              <div className='col-sm-3 col-md-3 col-lg-3 inline-container add-padding-top-5'>
                <label>{window.I18n.t('views.golfhub.default_view')}</label>
              </div>
              <Col xs={12} sm={9} className="no-padding no-margin-bottom">
                <Col xs={12} sm={3} md={3} lg={3} className="inline_container add-padding-top-5">
                  <GlgRadio
                    name="default_view"
                    value="tile"
                    label={ window.I18n.t('views.golfhub.tile_view') }
                    checked={ (formData.view_settings.default_view === 'tile') }
                    onChange={ (val) => setFormData({...formData, view_settings: {...formData.view_settings, default_view: val}}) }
                  />
                </Col>
                <Col xs={12} className="inline_container add-padding-top-10">
                  <GlgRadio
                    name="default_view"
                    value="list"
                    label={ window.I18n.t('views.golfhub.list_view') }
                    checked={ (formData.view_settings.default_view === 'list') }
                    onChange={ (val) => setFormData({...formData, view_settings: {...formData.view_settings, default_view: val}}) }
                  />
                </Col>
                {( formData.view_settings.enable_map_view &&
                  <Col xs={12} className="inline_container add-padding-top-10">
                    <GlgRadio
                      name="default_view"
                      value="map"
                      label={ window.I18n.t('views.golfhub.map_view') }
                      checked={ (formData.view_settings.default_view === 'map') }
                      onChange={ (val) => setFormData({...formData, view_settings: {...formData.view_settings, default_view: val}}) }
                    />
                  </Col>
                )}
              </Col>
            </div>
            <div className='row add-padding-top-30'>
              <div className='col-sm-3 col-md-3 col-lg-3 inline-container add-padding-top-5'>
                <label>Course</label>
              </div>
              <div className='col-xs-12 col-sm-9 col-md-9 col-lg-9 inline-container'>
                {(data?.courses.length > 0 &&
                  <select className='form-control' onChange={ (e) => handleFormData(e, 'selected_course') } defaultValue={data?.selected_course}>
                  {
                    data?.courses.map( course => <option key={ course.id } value={ course.id }>{ course.name }</option> )
                  }
                  </select>
                )}
                {(data?.courses.length === 0 &&
                  <label className='add-padding-top-5'>{window.I18n.t('views.golfhub.no_courses')}</label>
                )}
              </div>
            </div>

            <TemplateFields
              label='Sponsor'
              items={data.sponsors}
              defaultValue={data.sponsor?.id}
              inputName='sponsor'
              setValueOnChange={setSponsor}
              setNewRecord={setNewSponsor}
              newRecords={newSponsors}
              setNewRecords={setNewSponsors}
              setNoRecord={setNoSponsors}
              deleted={sponsorDeleted}
              setDeleted={setSponsorDeleted}
              setModalOpen={setSponsorModalOpen}
              newRecordsUpdated={newSponsorsUpdated}
              newRecordsBlobUrl={newSponsorsBlobUrl}
              showLoading={showSponsorLoading}
            />
            <TemplateFields
              label='Banner'
              items={data.course_albums}
              defaultValue={data.course_album?.id}
              inputName='course_album'
              setValueOnChange={setCourseAlbum}
              setNewRecord={setCourseAlbum}
              newRecords={newCourseAlbums}
              setNewRecords={setNewCourseAlbums}
              deleted={courseAlbumDeleted}
              setDeleted={setCourseAlbumDeleted}
              setModalOpen={setCourseAlbumModalOpen}
              newRecordsUpdated={newBannersUpdated}
              newRecordsBlobUrl={newBannersBlobUrl}
              showLoading={showBannerLoading}
            />
            <AddSponsorsPopup
              modalOpen={sponsorModalOpen}
              setModalOpen={setSponsorModalOpen}
              images={newSponsors}
              setImages={setNewSponsor}
            />
            <AddPhotoPopup
              modalOpen={courseAlbumModalOpen}
              setModalOpen={setCourseAlbumModalOpen}
              images={newCourseAlbums}
              setImages={setNewCourseAlbum}
            />

            <HubFiltersPopup
              filterModalOpen={filterModalOpen}
              setFilterModalOpen={setFilterModalOpen}
              filtersSelected={data?.role_setup_filters || []}
              data={data}
              setData={setDataAndReloadEvents}
              excludedOptions={excludedOptions}
              setExcludedOptions={setExcludedOptions}
              noOptions={noOptions}
              setNoOptions={setNoOptions}
              availableSeasons={props?.availableSeasons}
              formData={formData}
            />

            <ClubSelectPopup
              clubSelectModalOpen={clubSelectModalOpen}
              setClubSelectModalOpen={setClubSelectModalOpen}
              clubsSelected={data?.selected_clubs || []}
              clubs={props?.allClubs || []}
              data={data}
              setData={setDataAndReloadEvents}
              customerId={props.customerId}
            />

            {(isHub && formData.aggregate_from !== 'none' &&
              <DailyDigestContainer
                data={data}
                setData={setData}
                formData={formData}
                setFormData={setFormData}
                selectedManagers={data?.selected_managers || []}
                allCustomerManagers={props.allCustomerManagers}
              />
            )}
          </div>

          <div className="col-xs-6 add-padding-left-20 add-padding-right-20">
            <br/>
            <div className='iframeElement'>
              <IframeContainer
                src={iframeSrc}
                data={data}
                formData={formData}
                newBannersUpdated={newBannersUpdated}
                newSponsorsUpdated={newSponsorsUpdated}
                clubLeagues={selectedClubLeagues}
                customClubLeagues={clubLeaguesHaveBeenFetched}
                eventsCount={eventsCount}
                filteredByEventType={data?.role_setup_filters?.filter(filter => filter.category === 'Event Type' && (filter['_destroy'] === null || filter['_destroy'] !== true)).map(filter => filter.option) || []}
                aggregated={formData.aggregate_from !== 'none'}
                filters={formData.view_settings}
              />
            </div>
          </div>
        </div>)}
      </div>
      <div className='save_bar'>
        <a className='button btsp_gray_button' onClick={() => {
          checkDirtyForm(false)
          window.location.pathname = `/customers/${props.customerId}/hubs`
        }}>Cancel</a>
        <a className='button btsp_orange_button' disabled={errors['external_website'] || errors['hub_name']} onClick={() => {
          if (Object.keys(errors).length === 0 && canSave) {
            saveFormData()
          }
          setCanSave(false)
        }}>Save</a>
      </div>
    </div>
  )
}

const TemplateFields = (props) => {
  let settingValue = props.defaultValue ? 'template' : 'none'
  if (props.inputName === 'course_album' && settingValue === 'none') {
    settingValue = 'new'
  }

  const [ setting, setSetting ] = useState(settingValue)

  const onRadioSelected = (e) => {
    if (e === 'new') {
      props.setNewRecord(props.newRecords)
    } else if (e === 'none') {
      props.setNoRecord()
    } else {
      props.setValueOnChange(props.defaultValue || props.items[0].id)
    }
  }

  const setNewRecords = (records) => {
    props.setNewRecords(records)

    if (props.inputName === 'course_album' || props.inputName === 'sponsor') {
      const photos = records.map((record) => { return props.newRecordsBlobUrl[record.image] })
      props.newRecordsUpdated.photos = photos
    }
  }

  return (
    <Fragment>
      <div className="row">
        <Col xs={12} className="description no-margin-bottom no-padding add-margin-top-30">
          <Col xs={12} sm={3} md={3} lg={3} className="no-margin-bottom no-padding add-padding-top-5 add-padding-left-15 add-margin-top-10">
            <label>{props.label}</label>
          </Col>
          <Col xs={12} sm={9} className="no-padding description no-margin-bottom">
            <Col xs={12} sm={3} md={3} lg={3} className="inline_container add-padding-top-5">
              <GlgRadio
                name={props.inputName}
                value="template"
                label={'Template'}
                defaultChecked={ props.defaultValue !== undefined }
                onChange={(v) => { setSetting(v); onRadioSelected(v) }}
              />
            </Col>
            {setting === 'template' && (
              <Col xs={12} sm={9} md={9} lg={9} className="inline_container add-margin-top-5">
                <GlgSelect
                  name={'selected_' + props.inputName}
                  defaultValue={ props.defaultValue }
                  items={ props.items.map((i) => ({label: i.name, value: i.id}))}
                  onChange={ props.setValueOnChange }
                />
              </Col>
            )}
            <Col xs={12} className="description no-margin-bottom no-padding add-padding-left-15 add-margin-top-10">
              <Col xs={12} sm={3} md={3} lg={3} className="no-margin-bottom no-padding inline_container margin-top-15 add-padding-top-5">
                <GlgRadio
                  name={props.inputName}
                  value="new"
                  label={'New ' + props.label}
                  defaultChecked={ (props.inputName === 'course_album' && setting !== 'template') }
                  onChange={(v) => { setSetting(v); onRadioSelected(v) }}
                />
              </Col>
              {setting === 'new' && (
                <Col xs={12} sm={9} md={9} lg={9} className="inline_container add-padding-top-10">
                  {props.inputName === 'sponsor' && (
                    <SponsorItems
                      images={props.newRecords}
                      setImages={setNewRecords}
                      deleted={props.deleted}
                      setDeleted={props.setDeleted}
                      setModalOpen={props.setModalOpen}
                      showLoading={props.showLoading}
                    />
                  )}
                  {props.inputName === 'course_album' && (
                    <BannerItems
                      images={props.newRecords}
                      setImages={setNewRecords}
                      deleted={props.deleted}
                      setDeleted={props.setDeleted}
                      setModalOpen={props.setModalOpen}
                      showLoading={props.showLoading}
                    />
                  )}
                </Col>
              )}
            </Col>

            <Col xs={12} className="inline_container margin-top-15 add-padding-top-10">
              {props.inputName !== 'course_album' && (
                <GlgRadio
                  name={props.inputName}
                  value="none"
                  label={'No ' + props.label}
                  defaultChecked={ !props.defaultValue }
                  onChange={(v) => { setSetting(v); onRadioSelected(v) }}
                />
              )}
            </Col>
          </Col>
        </Col>
      </div>
    </Fragment>
  )
}

export const FilterRowContainer = ({ data, setData, strongFilters, excludedOptions, setExcludedOptions, noOptions, setNoOptions }) => {
  const onFilterDelete = (id, option, excludedOptions, setExcludedOptions) => {
    let newExcludedOptions = [ ...excludedOptions ]
    newExcludedOptions = newExcludedOptions.filter((element) => {
      return element.option !== option
    })
    if (noOptions) {
      setNoOptions(false)
    }
    setExcludedOptions(newExcludedOptions)
    const updatedFilters = [ ...data.role_setup_filters ]
    if (id !== null && id !== undefined) {
      updatedFilters.find((filter) => filter.id === id)['_destroy'] = true
      setData({ ...data, role_setup_filters: updatedFilters })
    } else {
      const removeNewFilter = data.role_setup_filters.filter(filter => filter.option !== option)
      setData({ ...data, role_setup_filters: removeNewFilter })
    }
  }

  const FilterRow = () => {
    const filters = data?.role_setup_filters
    const haveStrongFilters = strongFilters === undefined || strongFilters === null ? false : true

    if (!filters && !haveStrongFilters){
      return null
    }

    return (
      <div className='filter-row-container'>
        <div className='content-filter'>
          {!haveStrongFilters && filters.map(filter => {
            if ((filter._destroy === undefined || filter._destroy === null)){
              return (
                <div key={filter.option} className='filter-row'>
                  <div className='filter-text-container'>
                    <p className='text'>
                      { filter.option }
                    </p>
                  </div>

                  <button type='button' className='close' data-dismiss='modal' onClick={() => onFilterDelete(filter.id, filter.option, excludedOptions, setExcludedOptions)}>
                    <span aria-hidden='true' className='modal_button'>&times;</span>
                    <span className='sr-only'>Close</span>
                  </button>
                </div>
              )
            }
          })}
          {haveStrongFilters && strongFilters.map((filter, index) => {
            return (
              <div key={index} className='filter-row'>
                <div className={`filter-text-container ${index > 0 && 'strong-filters'}`}>
                  <p className='text'>{ filter }</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  return (
    <FilterRow />
  )
}

const DailyDigestContainer = ({data, setData, formData, setFormData, selectedManagers, allCustomerManagers}) => {
  const onDailyDigestReceiverDelete = (indexToDelete) => {
    let newSelectedManagers = [ ...selectedManagers ]
    newSelectedManagers = newSelectedManagers.filter((digestReceiver, index) => (index !== indexToDelete))
    setData({ ...data, selected_managers: newSelectedManagers })
  }

  const notSelectedManagers = (indexSelector = -1) => {
    return indexSelector === -1 ? allCustomerManagers.filter((customerManager) => !selectedManagers?.some(selectedManager => selectedManager.user_id === customerManager.user_id))
      : allCustomerManagers.filter((customerManager) => (customerManager.user_id === selectedManagers[indexSelector].user_id || !selectedManagers?.some(selectedManager => selectedManager.user_id === customerManager.user_id)))
  }

  const addDigestReceiverSelector = () => {
    const newManager = notSelectedManagers()[0]
    const newSelectedManagers = [ ...selectedManagers ]
    newSelectedManagers.push(newManager)
    setData({ ...data, selected_managers: newSelectedManagers })
  }

  const addDigestReceiver = (newDigestReceiver, index) => {
    const newSelectedManagers = [ ...selectedManagers ]
    newSelectedManagers[index] = newDigestReceiver
    setData({ ...data, selected_managers: newSelectedManagers })
  }

  const dailyDigestCheckboxSelected = (value) => {
    setFormData({...formData, daily_digest: value})
    if (value === true && selectedManagers.length === 0) {
      addDigestReceiverSelector()
    }
  }

  return (
    <div className='row add-padding-top-30'>
      <div className='col-sm-3 col-md-3 col-lg-3 inline-container add-padding-top-5'>
        <label>{window.I18n.t('views.golfhub.daily_digest')}</label>
      </div>
      <Col xs={12} sm={9} className="no-padding no-margin-bottom add-padding-left-5">
        <Col xs={12} sm={9} md={9} lg={9} className="inline_container add-padding-top-5 daily-digest-container">
          <Row className="add-padding-left-10">
            <Col xs={11} sm={9} md={9} lg={9} className="inline-container">
              <GlgCheckbox
                name="daily_digest"
                label={ window.I18n.t('views.golfhub.daily_digest_checkbox') }
                defaultChecked={ data.daily_digest || formData.daily_digest }
                inline={true}
                onChange={ (val) => { dailyDigestCheckboxSelected(val) } }
              />
            </Col>
            <Col xs={1} sm={1} md={1} lg={1} className="inline-container">
              {formData.daily_digest && !data['communication_officer_set'] && selectedManagers.map(selectedManager => selectedManager.user_id).includes(0) && (
                <a href={data.managers_link} className="info_circle">
                  <i className="gga-info-circle" title= {window.I18n.t('views.golfhub.communication_officer_missing')}></i>
                </a>
              )}
            </Col>
          </Row>
          { formData.daily_digest && (
            <Row className="add-padding-top-10 add-padding-left-20">
              {selectedManagers.map(selectedManager => ({user_id: selectedManager.user_id, name: selectedManager.name})).map((item, index) => (
                <div key={item.user_id} className='digest-receiver-row'>
                  <Col xs={12} sm={9} md={9} lg={9} className="inline_container">
                    <GlgSelect
                      defaultValue={item}
                      items={ notSelectedManagers(index).map((i) => ({label: i.name, value: i}) )}
                      onChange={ (val) => { addDigestReceiver(val, index) }}
                    />
                  </Col>
                  {selectedManagers.length > 1 && (
                    <Col xs={12} sm={3} md={3} lg={3} className="inline_container">
                      <a onClick={() => onDailyDigestReceiverDelete(index)}>
                        <i className='gga-table-delete-member remove-digest-receiver'/>
                      </a>
                    </Col>
                  )}
                </div>
              ))}
              <Col xs={12} sm={9} md={9} lg={9} className="inline_container">
                <button onClick={() => addDigestReceiverSelector()} className={selectedManagers.length === allCustomerManagers.length ? 'hidden' : 'btsp_orange_button pull-right'}>
                  Add Another Recipient
                </button>
              </Col>
            </Row>
          ) }
        </Col>
      </Col>
    </div>
  )
}

TemplateFields.propTypes = {
  label: PropTypes.string,
  items: PropTypes.array,
  defaultValue: PropTypes.string,
  inputName: PropTypes.string,
  setValueOnChange: PropTypes.func,
  setNewRecord: PropTypes.func,
  newRecords: PropTypes.array,
  setNewRecords: PropTypes.func,
  setNoRecord: PropTypes.func,
  deleted: PropTypes.array,
  setDeleted: PropTypes.func,
  setModalOpen: PropTypes.func,
  newRecordsUpdated: PropTypes.object,
  newRecordsBlobUrl: PropTypes.object,
  showLoading: PropTypes.bool,
}

HubPage.propTypes = {
  customerId: PropTypes.string,
  roleType: PropTypes.string,
  hubId: PropTypes.string,
  isSuperCustomer: PropTypes.string,
  allClubs: PropTypes.array,
  selectedClubs: PropTypes.array,
  strongFilters: PropTypes.array,
  availableSeasons: PropTypes.array,
  allCustomerManagers: PropTypes.array,
  selectedManagers: PropTypes.array,
}

FilterRowContainer.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func,
  strongFilters: PropTypes.array,
  excludedOptions: PropTypes.array,
  setExcludedOptions: PropTypes.func,
  noOptions: PropTypes.bool, 
  setNoOptions: PropTypes.func,
}

DailyDigestContainer.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  selectedManagers: PropTypes.array,
  allCustomerManagers: PropTypes.array,
}
export default HubPage
